import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { formatPrice, formatPriceTwoDecimals, formatPriceCL } from '../helpers';
import hideFromRoutes from '../hoc/hideFromRoutes';


const Total = ({ total, subtotal, bookingFee, bookingFeeMessage, symbol, countryCode, formatMovie }) => (
  <div className="total">
    { bookingFee !== null && bookingFee > 0
      ? <Fragment>
          <p className="total__price"><span className="total__text">Cargo por servicio: </span> <span className="total__num">{symbol}{countryCode === 'PA' ? formatPriceTwoDecimals(bookingFee) : (countryCode === 'CL' ? formatPriceCL(bookingFee) : formatPrice(bookingFee))}</span></p>
          <p className="total__price"><span className="total__text">Subtotal: </span> <span className="total__num">{symbol}{countryCode === 'PA' ? formatPriceTwoDecimals(subtotal) : (countryCode === 'CL' ? formatPriceCL(subtotal) : formatPrice(subtotal))}</span></p>
        </Fragment>
        : <p className="text text--small">{ bookingFeeMessage !== '' ? `*${bookingFeeMessage}` : ''}</p>
    }
    <p className="total__price"><span className="total__text">Total (IVA incluido): </span> <span className="total__num">{symbol}{countryCode === 'PA' ? formatPriceTwoDecimals(total) : (countryCode === 'CL' ? formatPriceCL(total) : formatPrice(total))}</span></p>
    {
      countryCode === 'CL' &&
      formatMovie.toUpperCase().includes('FD') &&
      <p className="text text--fd" >Función distendida: volumen moderado, sala parcialmente iluminada, puertas abiertas durante toda la función, capacidad reducida, dispositivos de regulación sensorial permitidos, espacios de regulación disponible, sistema de señalética con pictogramas.</p>
    }
  </div>
);

const mapStateToProps = ({ tickets, seats, showtime, globals }) => {
  const { seatsSelectedResponse } = seats;

  let message = "";
  if (showtime.showtimeInfo && showtime.showtimeInfo.alerts) {
    const alertMessage = showtime.showtimeInfo.alerts.find(x => x.section == "booking-fee");
    if (alertMessage) {
      if (globals.countryCode === 'PA') {
        if (showtime.settings !== null && showtime.settings.others !== null) {
          message = showtime.settings.others.booking_fee_message;
        }
      }
      else {
        message = alertMessage.message;
      }
      //message = globals.countryCode === 'PA' ? showtime.settings.others?.booking_fee_message : alertMessage.message;  
    }
  }

  if (message === "") {
    if (showtime.settings !== null && showtime.settings.others !== null) {
      message = showtime.settings.others.booking_fee_message;  
    }    
  }

  return {
    subtotal: seatsSelectedResponse.subtotal_value && seatsSelectedResponse.subtotal_value ? seatsSelectedResponse.subtotal_value : null,
    bookingFee: seatsSelectedResponse.booking_fee_value && seatsSelectedResponse.booking_fee_value ? parseInt(seatsSelectedResponse.booking_fee_value, 10) : 0,
    bookingFeeMessage: message,
    total: seatsSelectedResponse.total_value && seatsSelectedResponse.total_value ? parseInt(seatsSelectedResponse.total_value,10) : tickets.total,
    countryCode: globals.countryCode,
    symbol: showtime.settings?.currency.symbol ? showtime.settings.currency.symbol : '$',
    formatMovie: showtime.movie?.format ? showtime.movie.format : '',
  };
};
export default hideFromRoutes(connect(
  mapStateToProps
)(Total), ['/error', '/']);

