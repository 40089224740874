import { getCities } from '../services';

/*
  Constants
*/
export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS';

/*
  Action creators
*/
export const getCitiesService = (cinemaVistaId, countryCode) => (dispatch) => {
  getCities(countryCode).then(response => {
    dispatch({ type: GET_CITIES_SUCCESS, cities: response.data, cinemaVistaId });
  });

};

/*
  Reducer
*/

const initialState = {
  cities: [],
  hasFoodSales: false,
  merchantId:''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CITIES_SUCCESS: {
      let { cities } = action;
      let hasFoodSales = false;
      let merchantId = '';
      cities.forEach(function (city) {
        city.cinemas.forEach(function (cinema) {
          if (cinema.vista_id === action.cinemaVistaId) {
            hasFoodSales = cinema.settings.type_food_sales === "traditional" ||
              cinema.settings.type_food_sales === "vip";
            merchantId = cinema.settings.cs_merchant_id;
            return;
          }
        });
      });
      return { ...state, cities: cities, hasFoodSales, merchantId };
    }
    default:
      return state;
  }
};
