import React, { memo } from 'react';
import { connect } from 'react-redux';

import { cardPaseAnualSelector, cardTarjetaClubCinepolisSelector } from '../selectors';

const PriceZero = ({ getCardInfo }) => {
  const { cardImage, cardName, cardNumber, isOtros } = getCardInfo()
  return (
    <div className="payment__method-box price-zero">
      <figure className="price-zero__img">
        <img 
          src={cardImage !== '' ? cardImage : require(`../static/images/bg-folio-big.png`)} 
          styles={{ backgroundSize: `${isOtros ? '90%' : '100%'}` }}
          alt="" 
        />
        <figcaption className={`price-zero__num`} style={{ color: cardImage || 'black'}}>
          {cardNumber.map((num, i) => <span key={`${num}-${i}`}>{num}</span>)}
        </figcaption>
      </figure>

      <p className="text text--inc">El pago de tus entradas está cubierto con tu</p>
      <p className="text text--inc">{cardName || 'OTRA PROMOCIÓN'}</p>
    </div>
  );
};

const mapStateToProps = (state) => ({
  getCardInfo: () => {
    const code = state.cards.cardList[0].code
    const cardNumber = state.cards.cardList[0] !== null ? state.cards.cardList[0].number.replace(/.(?=.{4,}$)/g, '*').match(/.{1,4}/g) : []
    const hash = {
      'club-cinepolis': cardTarjetaClubCinepolisSelector(state),
      'pase-anual': cardPaseAnualSelector(state)
    }

    const benefitInfo = hash[code] || { cardName: 'OTRA PROMOCIÓN', images: { reference: '' }} 
    const isOtros = code === 'otros'
    return { cardName: benefitInfo.name, cardImage: benefitInfo.images.reference, cardNumber, isOtros}
  }
});

export default memo(connect(mapStateToProps)(PriceZero));
