import React from 'react';


const SeatMessage = (props) => (
  <div className="seat-message-container">
    <div className="seat-message">
      <div className="seat-message__text">
        {props.children}
      </div>
      <div className="seat-message__btnc">
        <button type="button" className="seat-message__btn" onClick={props.onCloseMessage}>DE ACUERDO</button>
      </div>
    </div>
  </div>
);

export default SeatMessage;
