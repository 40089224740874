import React from 'react';
import PropTypes from 'prop-types';

const Step = ({ step, name, completed, active }) => {
  return (
    <li className={`step ${completed ? 'completed' : ''} ${active ? 'active' : ''}`}>
      <span className="step__content">
        <p className="text blue-light">Paso {step}</p>
        <p className="text blue-light">{name}</p>
      </span>
    </li>
  );
};

Step.propTypes = {
  step: PropTypes.number,
  name: PropTypes.string
};

export default Step;
