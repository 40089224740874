import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import isEqual from "lodash/isEqual";

import { clearLoader, clearCardResponse } from "../store/cards";
import { showModal, continueToggle, toggleCardsList, toggleCardsListClose } from "../store/ui";

import CardPin from "./CardPin";
import CardLoader from "./CardLoader";
import ReCAPTCHA from "react-google-recaptcha";

class Card extends Component {
  constructor(props) {
    super(props);

    this.recaptchaRef = React.createRef();
    this.numInputs = [];
    this.activeInputIdx = 1;
    for (let i = 1; i <= props.numInputs; i += 1) {
      this.numInputs.push(i);
    }
    this.initialForm = this.numInputs.reduce((acc, num) => {
      this[`input${num}`] = React.createRef();
      acc[`${props.name}${num}`] = "";
      return acc;
    }, {});

    this.state = {
      form: { ...this.initialForm },
      valid: false,
      loaded: false,
      response: null,
      showMessage: false,
      hide: false,
      showPinForm: false,
      folio: props.folio !== null ? props.folio.number : null,
      remove: null,
      tokenRecaptcha: ''
    };
  }

  componentDidUpdate(prevProps) {
    if (!this.props.active && prevProps.active !== this.props.active) {
      this.setState({ form: { ...this.initialForm } });
    }

    if (
      prevProps.active !== this.props.active &&
      this.props.active &&
      this.props.folio === null
    ) {
      this.input1.current.focus();
      this.props.clearLoader();
    }

    if (
      this.props.response !== null &&
      this.state.response === null &&
      !this.state.loaded &&
      !prevProps.hide &&
      this.props.active &&
      (this.props.folio === null ||
        !isEqual(this.props.folio, prevProps.folio) ||
        prevProps.response.status_code !== this.props.response.status_code)
    ) {
      this.setState({ loaded: true });

      window.setTimeout(() => {
        this.setState(() => ({
          response: this.props.response.status_code === 0 ? "success" : "error",
        }));
      }, 1600);

      window.setTimeout(() => {
        this.setState(() => ({ showMessage: true }));
      }, 2400);

      window.setTimeout(() => {
        const state = {
          loaded: false,
          valid: false,
        };

        if (this.props.response.status_code === 0) {
          this.props.hideForm();
          this.props.clearLoader();
          state.showPinForm = false;
          state.remove = false;
          state.isLoading = false;
          state.response = null;
          state.hide = false;
          this.props.clearCardResponse();
          this.props.toggleCardsList();
          this.props.toggleCardsListClose();
        } else {
          this.props.showModal({ type: "error card", position: "aside" });
        }

        this.setState(state);
      }, 4000);
    }

    if (
      this.props.active &&
      this.props.folio !== null &&
      prevProps.active !== this.props.active
    ) {
      let folioParts = "";
      let form = {};

      if (this.numInputs.length > 1) {
        folioParts = this.props.folio.number.match(/.{1,4}/g);
        form = this.numInputs.reduce((acc, num) => {
          acc[`${this.props.name}${num}`] =
            typeof folioParts[num - 1] !== "undefined"
              ? folioParts[num - 1]
              : "";
          return acc;
        }, {});
      } else {
        form = { [`${this.props.name}1`]: this.props.folio.number };
      }
      this.setState({
        form,
        folio: this.props.folio,
        showPinForm:
          this.props.folio.pin !== null && this.props.folio.pin !== "",
      });
    }

    if (
      prevProps.active !== this.props.active &&
      !this.props.active &&
      this.props.folio !== null
    ) {
      this.setState({ showPinForm: false });
    }
  }

  handleShowForm = () => {
    this.props.showForm(this.props.code);
  };

  handleHideForm = () => {
    this.setState({ showPinForm: false, folio: null });
    this.setState((prev) => { return { ...prev, valid:false }});
    this.props.hideForm();
    this.activeInputIdx = 1;
  };

  handleKeyDown = (e) => {
    this.key = e.which || e.keyCode || 0;
  };

  setCardState(value, name, cb) {
    this.setState((prevState) => {
      const state = {
        form: {
          ...prevState.form,
          [name]: value,
        },
      };
      if (prevState.showPinForm) {
        state["showPinForm"] = false;
      }
      if (prevState.valid) {
        state["valid"] = false;
      }

      return state;
    }, typeof cb === "function" && cb);
  }

  showPinForm() {
    // bins = null
    const number = Object.keys(this.state.form).reduce(
      (acc, key) => acc + this.state.form[key],
      ""
    );
    
    const type = this.props.hasLoyalty ? 'loyalty' : 'voucher';
    const state = {
      showPinForm: this.props.use_pin,
      folio: {
        type,
        number,
        pin: null,
        code: this.props.code,
        name: this.props.name,
      },
    };

    if (!this.props.use_pin) state["valid"] = true;
    this.setState(state);
  }

  handleDefaultCard(value, name) {
    
    let showPinFromValue = 11
    if(this.props.code === 'rut') {
      
      showPinFromValue = value.length === 9 ? 9 : 10;
      
      if(value.length > 10) return;
      this.setCardState(value, name, () => value.length >= showPinFromValue && this.showPinForm(value));

    } else {

      if(value.length > 16) return;
      this.setCardState(value, name, () => value.length >= showPinFromValue && this.showPinForm(value));
      
    }
    
  }

  handleMultipleInputCard(value, name) {
    if (value.length === 16) {
      var numList = value.replace(/(\d{4}(?!\s))/g, "$1 ");
      name = name.substring(0, name.length - 1);
      var arrList = numList.trim().split(" ");
      for (var i = 0; i <= arrList.length - 1; i++) {
        this.setCardState(arrList[i], name + (i + 1), () => {
          this.showPinForm();
        });
      }
    } 
    else if (value.length > 4) return;
    else {
      this.setCardState(value, name, () => {
        if (
          value.length === 4 &&
          this.activeInputIdx + 1 <= this.numInputs.length
        ) {
          this[`input${this.activeInputIdx + 1}`].current.focus();
        } else if (
          this.activeInputIdx === this.numInputs.length &&
          value.length === 4
        ) {
          this.showPinForm();
        }
      });
    }
  }

  handleInputChange = (e) => {
    const { value, name } = e.target;
    if(this.props.numInputs > 1) this.handleMultipleInputCard(value, name);
    else this.handleDefaultCard(value, name);

    if (this.state.valid && !this.state.loaded && this.props.isLoading) {
      this.props.clearLoader();
    }
  };

  handleFocus = (e) => {
    const { name } = e.target;

    if (this.numInputs.length > 1) {
      const idx = +name.replace(this.props.name, "");
      if (idx !== this.activeInputIdx) {
        this.activeInputIdx = idx;
      }
    }
  };

  handlePinChange = (name, value, valid) => {
    const state = { folio: { ...this.state.folio, pin: value } };

    if (valid) {
      state["valid"] = true;
    } else {
      state["valid"] = false;
    }

    this.setState(state);
  };

  handleSubmitCard = () => {
    const tokenR = this.recaptchaRef.current.getValue();

    if (this.state.valid) {
      this.props.continueToggle();
      this.props.onSubmit(this.state.folio, tokenR);

      this.setState({
        response: null,
        showMessage: false,
      });
    }
    this.recaptchaRef.current.reset();
  };

  toggleRemoveConfirmation = (e) => {
    this.setState((prevState) => ({ remove: !prevState.remove }));
  };

  showDisabledTCCModal = () => {
    const message = "Para usar tus beneficios Club Cinépolis, por favor, inicia sesión en la app y completa tu transacción ahí."
    this.props.showModal({ type: "error card", position: "aside", message});
  }

  handleRecaptcha = (e) => {
    e.preventDefault();
    if (this.state.valid)
      this.recaptchaRef.current.execute();
  }

  render() {
    const {
      hasLoyalty,
      active,
      cssTypeName,
      name,
      buttonImage,
      code,
      bgImage,
      addedImage,
      folio,
      shouldCardChange,
      hide,
      style,
      submitButtonText,
      isLoading,
      activeFolio,
      modalOpen,
      hasCardListBenefits,
    } = this.props;

    return (
      <Fragment>
        <div
          className={`card-container${active ? " active" : ""}${
            hide ? " hidden" : ""
          }`}
          style={style}
        >
          <button
            type="button"
            className={`icon-cross icon-cross--circle icon-back icon-back--circle`}
            onClick={this.handleHideForm}
            aria-label={`Cerrar ${name}`}
            style={{
              right: code === "otros" ? 'calc(50% - 115px)' : 'calc(50% - 137px)',
              top: submitButtonText === "folio" ? '-5.3rem' :'-5.7rem'
            }}
            >
            <svg width="12" height="12" viewBox="0 0 12 12">
              <g fill="#000000" fillRule="nonzero">
                <path
                  d="M18 11.25L8.873 11.25 13.065 7.058 12 6 6 12 12 18 13.057 16.942 8.873 12.75 18 12.75z"
                  transform="translate(-6 -6)"
                />
              </g>
            </svg>
          </button>

          <form onSubmit={this.handleRecaptcha}>
            <div className={`card ${cssTypeName}`}>
              {activeFolio && !this.state.loaded && !active ? (
                <div className="card__added">
                  {shouldCardChange && (
                    <div
                      className={`card__added-remove${
                        this.state.remove && activeFolio ? " active" : ""
                      }`}
                    >
                      <div
                        className="card__added-remove-icon"
                        onClick={this.toggleRemoveConfirmation}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="39"
                          height="39"
                          viewBox="0 0 39 39"
                        >
                          <g
                            fillRule="nonzero"
                            transform="translate(.648 .16)"
                            fill="#FFF"
                          >
                            <path d="M27.383 11.05H11.007a.482.482 0 0 0-.478.486c0 .268.214.485.478.485h1.134v16.68c.001.972.777 1.76 1.735 1.761h10.638c.958-.001 1.734-.789 1.735-1.76v-16.68h1.134a.482.482 0 0 0 .479-.486.482.482 0 0 0-.479-.485zm-2.09 17.652c0 .436-.349.789-.779.79H13.876a.785.785 0 0 1-.779-.79v-16.56h12.196v16.56zM23.2 10.128a.482.482 0 0 1-.478-.485.771.771 0 0 0-.765-.776h-5.524a.771.771 0 0 0-.765.776.482.482 0 0 1-.478.485.482.482 0 0 1-.479-.485c.002-.964.772-1.745 1.722-1.747h5.524c.95.002 1.72.783 1.722 1.747a.482.482 0 0 1-.479.485z" />
                            <path d="M19.195 27.55a.482.482 0 0 1-.478-.485v-12.86c0-.268.214-.485.478-.485s.478.217.478.485v12.86a.482.482 0 0 1-.478.485zM15.847 27.55a.482.482 0 0 1-.478-.485v-12.86c0-.268.214-.485.478-.485s.479.217.479.485v12.86a.482.482 0 0 1-.479.485zM22.543 27.55a.482.482 0 0 1-.478-.485v-12.86c0-.268.214-.485.478-.485s.478.217.478.485v12.86a.482.482 0 0 1-.478.485z" />
                          </g>
                        </svg>
                      </div>

                      <div className="card__added-remove-btns">
                        <button type="button" onClick={this.props.removeCard}>
                          Eliminar {name.replace(/_/g, " ")}
                        </button>
                        <button
                          type="button"
                          onClick={this.toggleRemoveConfirmation}
                        >
                          Regresar <span>→</span>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <button
                  type="button"
                  className={`btn`}
                  onClick={this.props.isTCC ? this.showDisabledTCCModal : this.handleShowForm}
                  aria-label={`Añadir ${name}`}
                >
                  {
                    buttonImage !== null ? (
                      <img src={buttonImage} alt="" />
                    ) : (
                      <img src={require(`../static/images/folio.png`)} alt="" />
                    )
                  }
                </button>
              )}

              <div
                className={`card__add${!active ? " hidden" : ""}`}
                style={{
                  backgroundImage: 
                    `url(${
                      bgImage !== null
                        ? bgImage
                        : require(`../static/images/bg-folio-big.png`)
                    })`,
                  backgroundSize: `${code === 'otros' ? 'contain' : 'cover'}`
                }}
              >
                <div className="card__add-input">
                  <div className="form-group">
                    {this.numInputs.map((value) => {
                      return (
                        <Fragment key={`${name}${value}`}>
                          <input
                            ref={this[`input${value}`]}
                            type="text"
                            name={`${name}${value}`}
                            id={`${name}${value}`}
                            autoComplete="off"
                            className={`form-control ${hasLoyalty ? (this.props.code === "rut" ? "form-control--rut" : "form-control--loyalty") : "form-control--voucher"}`}
                            onChange={this.handleInputChange}
                            onKeyDown={this.handleKeyDown}
                            value={this.state.form[`${name}${value}`]}
                            onFocus={this.handleFocus}
                            placeholder={submitButtonText}
                          />
                        </Fragment>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            {this.state.showPinForm && (
              <CardPin
                onChangePin={this.handlePinChange}
                text="Introduce tu PIN"
                name="pin"
                label="PIN"
                section="aside"
                cardNumber={this.state.folio.number}
                code={this.props.code}
              />
            )}

            {this.props.active && isLoading && !modalOpen && (
              <CardLoader
                response={this.state.response}
                loaded={this.state.loaded}
                showMessage={this.state.showMessage}
                hasLoyalty={hasLoyalty}
              />
            )}

            <button
              type="submit"
              className={`btn btn--secondary btn--secondary-purchase submit ${
                active ? "" : " hidden"
              }`}
              disabled={isLoading || !this.state.valid}
            >
              Agregar
            </button>
          </form>
        </div>
        <ReCAPTCHA
            className="recaptcha-cards"
            ref={this.recaptchaRef}
            size="invisible"
            sitekey="6LcYxXcUAAAAAPd2-4EIfCksacpQd6xVYtQsRP_D"
            onChange={this.handleSubmitCard}
          />
      </Fragment>
    );
  }
}

Card.defaultProps = {
  inputValue: "",
  cssTypeName: "",
  style: {},
};

Card.propTypes = {
  style: PropTypes.object,
  active: PropTypes.bool,
  hide: PropTypes.bool,
  cssTypeName: PropTypes.string,
  buttonImage: PropTypes.string,
  bgImage: PropTypes.string,
  addedImage: PropTypes.string,
  activeFolio: PropTypes.bool,
  code: PropTypes.string,
  use_pin: PropTypes.bool,
  btnText: PropTypes.string,
  btnTextPosition: PropTypes.string,
  submitButtonText: PropTypes.string,
  showForm: PropTypes.func,
  hideForm: PropTypes.func,
  onSubmit: PropTypes.func,
  removeCard: PropTypes.func,
  hasLoyalty: PropTypes.bool,
};

export default connect(null, {
  clearLoader,
  showModal,
  continueToggle,
  clearCardResponse,
  toggleCardsList,
  toggleCardsListClose,
})(Card);
