import { createSelector } from 'reselect';
import { formatPrice, formatPriceCL } from '../helpers';

const seatsSelectedSelector = (state) => state.seats.selected.reduce((acc, category) => acc.concat(category.selected_seats), []);
const seatsLayoutSelector = (state) => state.seats.layout;

export const selectedSeatsHumanSelector = createSelector(
  [seatsSelectedSelector, seatsLayoutSelector],
  (seatsSelected, layout) => {
    //console.time('seats selected');
    const seatsSelectedResult = seatsSelected.map(seat => {
      let result = '';

      for (let letter in layout) {
        const foundSeat = layout[letter].find(elem => {
          return (
          seat.row_index === elem.position.row_index && 
          seat.column_index === elem.position.column_index && 
          seat.area_number === elem.position.area_number
        )});
  
        if (foundSeat) {
          result = `${letter}${foundSeat.id}`;
          break;
        }
      }

      return result;
    });
    //console.timeEnd('seats selected');

    return seatsSelectedResult.join(', ');
  }
);


export const ticketsSelector = (state, countryCurrency, countryCode) => {
	const ticketTypes = state.tickets.types;
  //there are ticket names that have - (minus sign) in its name, so split would not work appropiately
	let tickets = Object.keys(ticketTypes).map(ticket => ` ${ticket.replace(/(\d+)-(.*)-(null|(\d+))/, '$2')} (${ticketTypes[ticket]})`).join(', ');
  if(tickets !== '')
  tickets +=  ` (${countryCurrency}${countryCode === 'CL' ? formatPriceCL(parseInt(state.tickets.total)) : formatPrice(parseInt(state.tickets.total))})`
  return tickets;
};

export const concessionsSelector = (state, countryCurrency) => {
  if (typeof state.seats.concessions_data.concessions === 'undefined' || state.seats.concessions_data.concessions === null ) return '';
  const concessions = state.seats.concessions_data;
  let concessionsText = Object.keys(concessions.concessions).map(concession => `${concessions.concessions[concession].name} (${concessions.concessions[concession].quantity})`).join(', \n');
  concessionsText += ` (${countryCurrency}${formatPrice(parseInt(concessions.concessions_total, 10))})`;
  return concessionsText;
};

const ticketsAreasSelector = (state) => state.tickets.areas;
export const maxQuantitySelector = createSelector(
  [ticketsAreasSelector],
  (areas) => {
    if (areas && areas.length) {
      var total = areas.map(area => {
        return area.tickets.reduce((acc, ticket) => acc > ticket.max_quantity ? acc : ticket.max_quantity, 0);
      });
      var maxTickets = total.sort(function (a, b) { return b - a })[0];
      return maxTickets;
    }
    return 0;
  }
);


const benefitsSelector = (state) => state.showtime.benefits;
export const cardPaseAnualSelector = createSelector(
  [benefitsSelector],
  (benefits) => benefits.find(benefit => benefit.code === 'pase-anual')
);

export const cardTarjetaClubCinepolisSelector = createSelector(
  [benefitsSelector],
  (benefits) => benefits.find(benefit => benefit.code === 'club-cinepolis')
);
