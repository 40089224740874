import React, { Component, Fragment } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import TagManager from "react-gtm-module";
import { connect } from "react-redux";
import BillingData from "../components/BillingData";
import Loader from "../components/Loader";
import LocalRedirect from "../components/LocalRedirect";
import PaymentMethods from "../components/PaymentMethods";
import PaymentSharedFields from "../components/PaymentSharedFields";
import PaymentSubmitButton from "../components/PaymentSubmitButton";
import Return from "../components/Return";
import { resetTime, routeChanged, setStartTime } from "../store/globals";
import { resetOrder } from "../store/order";
import {
  requestAnnualPassPayment,
  requestBankCard,
  requestCineCashPayment,
  requestClubCinepolisPayment,
  requestPaypalOrder,
  requestKueskiOrder,
  requestPriceZeroPayment,
  requestReservation,
  requestVisaCheckout,
  requestVisaCheckOutDecrypt,
  setPaymentType,
  setSessionId,
  setTokenRecaptcha,
  paymentRequest,
  requestSpreedly,
  clearPaymentResponse,
  timeResetCancelLoading,
  kueskiOrderComplete,
  kueskiCompleteOrderCheckout
} from "../store/payments";
import { hideModal, showModal } from "../store/ui";
import { formatPriceTwoDecimals } from "../helpers";
import { selectedSeatsHumanSelector } from '../selectors/index';


class Payments extends Component {
  constructor(props) {
    super(props);

    this.recaptchaRef = React.createRef();
    let initialBillTo = {
      email: "",
      first_name: "",
      last_name: "",
      loyalty: "",
      tarjeta_cc: ""
    };

    if (this.props.loginResponse !== null) {
      const { email, first_name, last_name, card_number } =
        this.props.loginResponse;

      initialBillTo = {
        email,
        first_name,
        last_name,
        loyalty: card_number,
      };
    }
    this.loadScript();
    this.state = {
      active: props.isPriceZero
        ? "price-zero"
        : props.isMobile && props.wSize < 680
          ? null
          : "spreedly_tickets",
      mobilePaymentMethodsOpen:
        props.isMobile && props.wSize < 680 ? false : null,
      bill_to: initialBillTo,
      valid: false,
      tokenRecaptcha: "",
      hideButtons: false,
      activeSonicBrand: false,
      finalConsumer: true,
      paymentProvider: "",
      invoicing_data: this.props.countryCode === 'CO' ?
      {
        tax_id: '2',
        tax_number: '222222222222',
        email: 'email@gmail.com',
        tax_name: 'Consumidor Final',
      } :
      {
        customer_tax_name: "",
        customer_tax_number: ""
      },
      valid_invoicing: true,
      valid_invoicing_data: this.props.countryCode === 'CO' ? 
      {
        tax_id: false,
        tax_number: false,
        tax_name: false,
      } :
      {
        customer_tax_name: false,
        customer_tax_number: false,
      },
      checkout_id: null,
      isLoading_kueski: false,
      enableBeginCheckoutEvent: true,
    };
  }

  verifyQueriesKueski = () => {

    try {

      const { search } = window.location;

      //! No hay queries -> no se hace nada
      if (!search) return;

      const queries = search.split('?')[1].split('&');

      const session_id = queries[0].split('=')[1];
      const method = queries[1].split('=')[1];
      const status = queries[2].split('=')[1];

      //! Si no estan todos los queries necesarios
      if (!session_id || !method || !status) return;

      //! Validamos que los queries sean de kueski para no crear error con paypal , etc
      if (queries[0].split('=')[0] !== 'sessionId'
        || queries[1].split('=')[0] !== 'method'
        || queries[2].split('=')[0] !== 'status') return;

      //! Validamos que el metodo sea kueski
      if (method !== 'kueski') return;

      //* Loader
      this.setState({
        isLoading_kueski: true
      });

      //* Obtenemos id de checkout almacenado en localstorage
      const checkout_id_kueski = localStorage.getItem('checkout_id_kueski');

      //* Simulación para loading de kueski de 3 segundos
      setTimeout(() => {

        //! Validamos que exista checkout_id de kueski
        if (!checkout_id_kueski) {

          this.props.hideModal();
          this.props.timeResetCancelLoading();
          this.setState({
            active: null,
            valid: false,
            checkout_id: null,
            isLoading_kueski: false

          });
          this.recaptchaRef.current.reset();
          this.props.showModal({ type: 'error iframe', message: 'Parámetros kueski pay no validos.' });

          localStorage.removeItem('checkout_id_kueski');

          return;
        }

        //* Si validacion de queries es corrrecta -> instancia de un token y validaciones de status
        const tokenR = this.recaptchaRef.current.getValue();
        this.recaptchaRef.current.reset();

        //! Status cancelado -> Retornamos alerta de error
        if (status === 'canceled' || status === 'reject' || status === 'failed') {

          this.props.hideModal();
          this.props.timeResetCancelLoading();
          this.setState({
            active: null,
            valid: false,
            checkout_id: null,
            isLoading_kueski: false
          });
          this.recaptchaRef.current.reset();
          this.props.showModal({ type: 'error iframe', message: 'La compra no se realizó correctamente.' });

          localStorage.removeItem('checkout_id_kueski');

          return;

        }

        //* Status success -> Validamos status de orden 
        if (status === 'success') {

          this.props.kueskiOrderComplete(this.props.sessionId, checkout_id_kueski, this.props.countryCode, tokenR, this.state.bill_to).then(res => {

            //! Ocurre un error en la consulta
            if (res?.status === 400) {

              this.props.hideModal();
              this.props.timeResetCancelLoading();
              this.setState({
                active: null,
                valid: false,
                checkout_id: null,
                isLoading_kueski: false
              });
              this.recaptchaRef.current.reset();
              this.props.showModal({ type: 'error iframe', message: res?.message || 'La compra no se realizó correctamente.' });

              localStorage.removeItem('checkout_id_kueski');

              return;
            }

            // ! Ocurre un error con el status de la orden
            if (res?.tickets_confirmation?.status === 1) {
              this.props.hideModal();
              this.props.timeResetCancelLoading();
              this.setState({
                active: null,
                valid: false,
                checkout_id: null,
                isLoading_kueski: false
              });
              this.recaptchaRef.current.reset();
              this.props.showModal({ type: 'error iframe', message: res?.message || 'La compra no se realizó correctamente.' });

              localStorage.removeItem('checkout_id_kueski');

              return;
            }

            //* Orden con status success
            if(res?.tickets_confirmation?.status === 0){
              this.props.kueskiCompleteOrderCheckout( res, this.state.bill_to, this.props.sessionId );
              this.setState({ 
                active: null, 
                valid: false, 
                checkout_id: null,
                isLoading_kueski: false
              });
              //* Borramos localstorage de checkout_id_kueski
              localStorage.removeItem('checkout_id_kueski');

              return;

          }

        }).catch(err  => {
          //! Ocurre un error 

          this.props.hideModal();
          this.props.timeResetCancelLoading();
          this.setState({ 
            active: null, 
            valid: false, 
            checkout_id: null,
            isLoading_kueski: false
          });
          this.recaptchaRef.current.reset();
          this.props.showModal({ type: 'error iframe', message: 'La compra no se realizó correctamente - ocurrió un error.' });

          localStorage.removeItem('checkout_id_kueski');
      })

      return;
    }
    
  }, 3000);

    } catch (error) {
      console.log(error);
    }

  }

  componentDidMount() {
    this.props.resetOrder(this.props.sessionId, false, this.props.countryCode);
    this.props.setSessionId(this.props.sessionId);
    this.props.setPaymentType(this.state.active);
    this.loadScript();

    //* Funcion para kueski
    this.verifyQueriesKueski();

    let paymentsTagManagerPageView;

    if(this.props.isMX) {
      paymentsTagManagerPageView = {
        dataLayer: {
          event: 'page_view',
          channel: {
            type: 'web',
            subtype: 'legacy',
            language: 'ES',
            country: 'MX'
          }
        }
      }
    } else {
      paymentsTagManagerPageView = {
        dataLayer: {
          item_name: this.props.movieName,
          event: "page_view",
          path: "/visPayment",
          times: new Date().toLocaleTimeString('es-MX', { hour12: false })
        },
      };
    }

    const tagManagerArgs = {
      dataLayer: {
        item_name: this.props.movieName,
        event: "pago",
        times: new Date().toLocaleTimeString('es-MX', { hour12: false }),
        items: [{
          step: 3,
          action: "checkout"
        }]
      },
    };

    if(this.props.countryCode === 'CL') {
      tagManagerArgs.dataLayer.items[0].item_name = this.props.movieName;
      delete tagManagerArgs.dataLayer.item_name;
    }

    const appDynamics = {
      cinema_type: this.props.movieLanguage,
      screen: this.props.movieScreen,
    };

    TagManager.dataLayer(tagManagerArgs);
    TagManager.dataLayer(paymentsTagManagerPageView);

    let total = this.props.total / 100;
    window.requestVisaCheckOutDecrypt = this.props.requestVisaCheckOutDecrypt;
    window.onVisaCheckoutReady = function onVisaCheckoutReady() {
      window.V.init({
        apikey: process.env.REACT_APP_CNPL_PURCHASE_API_KEY_VC,
        paymentRequest: {
          currencyCode: this.props.currencyCode,
          subtotal: total,
          total: total,
        },
      });
      window.V.on("payment.success", function (payment) {
        window.requestVisaCheckOutDecrypt(
          payment.encPaymentData,
          payment.encKey,
          payment.callid,
          appDynamics,
          this.props.countryCode
        );
      });
      window.V.on("payment.cancel", function (payment) { });
      window.V.on("payment.error", function (payment, error) { });
    };

    if (
      typeof this.props.payments.paymentResponse.url !== "undefined" ||
      (typeof this.props.payments.paymentResponse.tickets_confirmation !==
        "undefined" &&
        this.props.payments.paymentResponse.tickets_confirmation !== null &&
        this.props.payments.paymentResponse.tickets_confirmation.status > 0)
    ) {
      this.props.clearPaymentResponse();
      this.props.hideModal();
    }

    localStorage.removeItem('active-type')
  }

  shouldComponentUpdate(prevProps, prevState) {
    if (
      prevState.active === this.state.active &&
      JSON.stringify(prevState.bill_to) ===
      JSON.stringify(this.state.bill_to) &&
      prevState.valid === this.state.valid &&
      prevProps.payments.paymentResponse ===
      this.props.payments.paymentResponse &&
      prevProps.payments.isLoading === this.props.payments.isLoading &&
      prevProps.withFolio === this.props.withFolio &&
      prevProps.isMobile === this.props.isMobile &&
      prevProps.modal === this.props.modal &&
      prevState.mobilePaymentMethodsOpen ===
      this.state.mobilePaymentMethodsOpen &&
      prevState.loginResponse === this.props.loginResponse
    ) {
      return false;
    }

    return true;
  }

  componentDidUpdate(prevProps, prevState) {
    const appDynamics = {
      cinema_type: this.props.movieLanguage,
      screen: this.props.movieScreen,
    };
    if (prevProps.payments.type !== this.state.active)
      this.props.setPaymentType(this.state.active);
    if (this.state.active === "bank_card") {
      // credit card
      this.isCreditCardValid();
    } else if (this.state.active === "loyalty_tickets") {
      this.isClubCinepolisValid();
    } else if (
      [
        "unpaid_booking",
        "paypal_tickets",
        "visa_checkout",
        "cinecash",
        "price-zero",
        "spreedly_tickets",
        "c2p_tickets",
        "kueski_tickets",
      ].indexOf(this.state.active) > -1
    ) {
      // every other payment type
      this.isPaymentMethodValid();
    }

    if (
      prevProps.payments.isLoading !== this.props.payments.isLoading &&
      this.props.payments.isLoading &&
      this.state.valid
    ) {
      window.scrollTo(0, 0);
    }

    if (
      prevProps.loginResponse !== this.props.loginResponse &&
      this.props.loginResponse !== null &&
      this.props.loginResponse.access_token !== null
    ) {
      const { email, first_name, last_name, card_number } =
        this.props.loginResponse;
      this.setState({
        bill_to: { email, first_name, last_name, loyalty: card_number },
      });
    }
    if (
      this.state.active === "visa_checkout" &&
      this.props.payments.visaCheckoutDecryptResponse !== null &&
      !this.props.payments.isLoading &&
      typeof this.props.payments.paymentResponse.tickets_confirmation ===
      "undefined"
    ) {
      if (
        typeof this.props.payments.visaCheckoutDecryptResponse.card !==
        "undefined" &&
        this.props.payments.visaCheckoutDecryptResponse.card !== null
      ) {
        var cinema = {
          name: this.props.cinemaName,
          position: {
            latitude: this.props.cinemaLat,
            longitude: this.props.cinemaLng,
          },
        };
        const fingerprintId = this.props.cinema_id + "_" + this.props.sessionId;
        var movie = {
          name: this.props.movieName,
          duration: this.props.movieDuration,
          format: this.props.movieFormat,
        };
        this.props.requestVisaCheckout(
          this.props.payments.visaCheckOutData,
          this.props.payments.visaCheckOutWrappedKey,
          this.state.bill_to,
          this.props.payments.visaCheckOutCallId,
          this.props.payments.visaCheckoutDecryptResponse.card,
          cinema,
          fingerprintId,
          this.state.bill_to.loyalty,
          movie,
          this.props.sessionId,
          appDynamics,
          this.props.countryCode
        );
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.recieveMessage, true);
    var vco = document.getElementById("vcop-src-frame");
    if (vco !== null && typeof vco !== "undefined")
      vco.parentNode.removeChild(vco);
  }

  handleActiveForm = (active) => {
    if (this.state.active === active) return;
    this.setState((prevState, props) => {
      return {
        active,
        bill_to: {
          first_name: prevState.bill_to.first_name,
          last_name: prevState.bill_to.last_name,
          email: prevState.bill_to.email,
          loyalty: prevState.bill_to.loyalty,
          tarjeta_cc: prevState.bill_to.tarjeta_cc,
          billing_name: prevState.bill_to.billing_name,
          billing_nit: prevState.bill_to.billing_nit,
          accept_terms: prevState.bill_to.accept_terms
            ? prevState.bill_to.accept_terms
            : false,
        },
        valid: false,
        mobilePaymentMethodsOpen:
          props.isMobile && props.wSize < 680 ? true : null,
        tokenRecaptcha: "",
      };
    });

    if (this.props.modal !== null && this.props.modal.position === "main") {
      this.props.hideModal();
    }
  };

  handleOpenMobileMethods = () => {
    this.setState({ mobilePaymentMethodsOpen: false, valid: false, active: null });
  };
  handleFinalConsumer = () => {
    this.setState({ finalConsumer: !this.state.finalConsumer });
    this.setState({ valid: false });
    if(!this.state.finalConsumer && this.props.countryCode === 'CO') {
      this.state.valid_invoicing = true;
      this.state.invoicing_data.tax_id = '2';
      this.state.invoicing_data.tax_number = '222222222222';
      this.state.invoicing_data.email = this.state.bill_to.email;
      this.state.invoicing_data.tax_name = 'Consumidor Final';
    } else if(this.state.finalConsumer && this.props.countryCode === 'CO') {
      this.state.invoicing_data.tax_id = '';
      this.state.invoicing_data.tax_name = '';
      this.state.invoicing_data.email = this.state.bill_to.email;
      this.state.invoicing_data.tax_number = '';
      this.state.valid_invoicing = false;
      this.state.valid_invoicing_data.tax_id = false;
      this.state.valid_invoicing_data.tax_name = false;
      this.state.valid_invoicing_data.tax_number = false;
    }

    if(
      this.props.countryCode === 'GT' &&
      !this.state.finalConsumer
    ) {
      this.state.valid_invoicing = true;
      this.state.invoicing_data.customer_tax_name = '';
      this.state.invoicing_data.customer_tax_number = '';
    } else if(this.props.countryCode === 'GT' && this.state.finalConsumer) {
      this.state.valid_invoicing = false;
    }
  }

  handleInvoicingData = (name, value, valid) => {
    const invoicing_data = { ...this.state.invoicing_data };
    if (valid) {
      invoicing_data[name] = value;
      this.setState({ invoicing_data });
    } else if (value === "" || !value) {
      invoicing_data[name] = value;
      this.setState({ invoicing_data, valid: false });
    } else {
      this.setState({ valid: false });
    }

    if(this.props.countryCode === 'CO' || this.props.countryCode === 'GT') {
      const valid_invoicing_data = { ...this.state.valid_invoicing_data };
      valid_invoicing_data[name] = valid;
      this.setState({ valid_invoicing_data });

      const data_invoicing = Object.values(valid_invoicing_data);
      this.state.valid_invoicing = data_invoicing.every(function(value) {
        return value === true;
      });
    }

    if(this.props.countryCode === 'CO' && (name === 'tax_id' || name === 'tax_number') && valid) {
      const valid_invoicing_data = { ...this.state.valid_invoicing_data };
      
      if(name === 'tax_id') {
        valid_invoicing_data['tax_id'] = false;
        valid_invoicing_data['tax_number'] = false;
      } else {
        valid_invoicing_data['tax_id'] = true;
        valid_invoicing_data['tax_number'] = true;
      }

      this.setState({ valid_invoicing_data });

      const data_invoicing = Object.values(valid_invoicing_data);
      this.state.valid_invoicing = data_invoicing.every(function(value) {
        return value === true;
      });

    }
  };

  handleInputChange = (name, value, valid) => {
    const bill_to = { ...this.state.bill_to };

    if (valid) {
      bill_to[name] = value;
      this.setState({ bill_to });
      if((this.state.finalConsumer && name === "email") || (this.props.countryCode === 'CO' && name === "email")) {
        this.state.invoicing_data.email = value;
      }
    } else if (value === "" || !value) {
      bill_to[name] = value;
      this.setState({ bill_to, valid: false });
    } else {
      this.setState({ valid: false });
    }
  };

  isSharedFieldsValid() {
    const { first_name, last_name, email } = this.state.bill_to;
    return first_name !== "" && last_name !== "" && email !== "";
  }

  isBillingDataValid() {
    const { billing_name, billing_nit } = this.state.bill_to;
    const { finalConsumer } = this.state
    return finalConsumer || billing_name !== "" && billing_nit !== ""
  }

  isCheckboxValid() {
    return (
      this.state.bill_to.hasOwnProperty("accept_terms") &&
      this.state.bill_to["accept_terms"] &&
      !this.state.valid
    );
  }

  isDefaultFieldsValid() {
    if (this.isSharedFieldsValid() && this.isCheckboxValid() && this.isBillingDataValid()) {
      return true;
    }

    return false;
  }

  isPaymentMethodValid() {
    if (this.isDefaultFieldsValid() && this.isBillingDataValid) {
      this.setState({ valid: true });
    }
  }

  isCreditCardValid() {
    const { bill_to } = this.state;

    if (
      this.isDefaultFieldsValid() &&
      bill_to.hasOwnProperty("card_number") &&
      bill_to.card_number !== "" &&
      bill_to.hasOwnProperty("card_name") &&
      bill_to.card_name !== "" &&
      bill_to.hasOwnProperty("card_date") &&
      bill_to.card_date !== "" &&
      bill_to.hasOwnProperty("card_cvv") &&
      bill_to.card_cvv !== ""
    ) {
      this.setState({ valid: true });
    }
  }

  isClubCinepolisValid() {
    const { bill_to } = this.state;

    if (
      this.isDefaultFieldsValid() &&
      bill_to.hasOwnProperty("card_cc_number") &&
      bill_to.card_cc_number !== "" &&
      bill_to.hasOwnProperty("cc_pin") &&
      bill_to.cc_pin !== ""
    ) {
      this.setState({ valid: true });
    }
  }
  recieveMessage = (event) => {
    if (this.state.valid) this.recaptchaRef.current.execute();
    const tokenR = this.recaptchaRef.current.getValue();
    this.setState({ tokenRecaptcha: tokenR });
    try {
      var data = JSON.parse(event.data);
      const appDynamics = {
        cinema_type: this.props.movieLanguage,
        screen: this.props.movieScreen,
      };

      if (
        typeof data.token !== "undefined" &&
        typeof data.number !== "undefined"
      ) {
        var fingerprintId = this.props.cinema_id + "_" + this.props.sessionId;
        
        if (data.first_six_digits) {
          let { paymentMastercardSettings } = this.props;
          let masterCardBines = paymentMastercardSettings.find(
            (x) => x.country_code === "MX"
          );

          if (this.props.countryCode === 'CL' && data.card_type === 'master') {
            localStorage.setItem('isMastercard', true);
          } else if (typeof masterCardBines !== "undefined") {
            if (typeof masterCardBines.bines !== "undefined") {
              let isMastercard = masterCardBines.bines.includes(
                String(data.first_six_digits)
              );
              if (isMastercard) {
                this.setState((prev) => {
                  return {
                    ...prev,
                    activeSonicBrand: isMastercard,
                  };
                });
              }
            }
          }
        }

        const tax_id = this.state.invoicing_data.tax_id;
        if(this.props.countryCode === 'CO' && (tax_id === '5' || tax_id === '77' || tax_id === '12')) {
          this.setState({ 
            invoicing_data: {
              ...this.state.invoicing_data,
              tax_number: this.state.invoicing_data.tax_number.replaceAll('-', ''),
              tax_id: tax_id === '77' ? '7' : tax_id,
            }
          });
        }
        
        this.props.requestSpreedly(
          this.state.bill_to,
          this.props.sessionId,
          fingerprintId,
          data.token,
          this.state.tokenRecaptcha,
          data.first_six_digits,
          appDynamics,
          this.props.countryCode,
          this.props.cinema_id,
          this.state.paymentProvider,
          this.state.invoicing_data,
          data.card_type,
          data.last_four_digits
        );
        this.props.hideModal();
        this.setState({ active: null, valid: false });
        this.recaptchaRef.current.reset();
        this.props.paymentRequest();
      }
    } catch (e) { }
  };

  callC2PFrame = (event) => {
    try {
      if (event.data.iFrameOpened) {
        const frame = document.getElementById('iframe-c2p');
        frame.contentWindow.postMessage({
          email: this.state.bill_to.email,
          amount: this.props.total / 100,
          currency: "MXN",
          mc_frame_height: 720,
          mc_frame_witdh: 600,
          first_name: this.state.bill_to.first_name,
          last_name: this.state.bill_to.last_name,
          country: "MX"
        }, "*");
        window.addEventListener("message", this.recieveMessage, true);
      } else if (event.data.action === "reload") {
        this.props.hideModal();
        this.props.timeResetCancelLoading();
        this.setState({ active: null, valid: false });
        this.recaptchaRef.current.reset();
        //window.addEventListener("message", this.callC2PFrame, true);
      } else {
        var data = JSON.parse(event.data);
        if (data.error_code) {
          this.props.hideModal();
          this.props.timeResetCancelLoading();
          this.setState({ active: null, valid: false });
          this.recaptchaRef.current.reset();
          this.props.showModal({ type: 'error iframe', message: data.errors });
        }
      }
    } catch (e) {
      console.log("error: ", e);
    }
  }

  handleSubmit = (e) => {
    if(this.props.isMX) {
      TagManager.dataLayer({
        dataLayer: {
          event: "add_payment_info",
          channel: {
            module: "payment",
            component: "transaction_summary",
            element: "complete_payment",
            action: "add_payment"
          },
          transaction: {
            payment_type: this.state.active,
          },
          items: [{
            item_id: this.props.sessionId,
            item_category: "payment",
            item_category2: this.state.active,
            price: +formatPriceTwoDecimals(this.props.total),
            quantity: 1
          }]
        }
      })
    }


    const tokenR = this.recaptchaRef.current.getValue();
    const appDynamics = {
      cinema_type: this.props.movieLanguage,
      screen: this.props.movieScreen,
    };
    this.props.resetOrder(
      this.props.sessionId,
      false,
      this.props.countryCode,
      this.state.active === "spreedly_tickets" || this.state.active === "c2p_tickets" || this.state.active === 'kueski_tickets'
    );
    let metodo = "";
    if (this.state.active === "spreedly_tickets") {
      // credit card
      this.setState({ tokenRecaptcha: tokenR, paymentProvider: "spreedly" });
      metodo = "credit card";
      window.addEventListener("message", this.recieveMessage, true);
      //this.props.requestBankCard(this.state.bill_to, this.props.sessionId, fingerprintId, tokenR, countryCode);
      this.props.paymentRequest();
    } else if (this.state.active === "c2p_tickets") {
      // credit card
      this.setState({ tokenRecaptcha: tokenR, paymentProvider: "c2p" });
      metodo = "click to pay";
      window.addEventListener("message", this.callC2PFrame, true);
      this.props.paymentRequest();
    } else if (this.state.active === "unpaid_booking") {
      // reservation
      metodo = "reservation";
      this.props.requestReservation(
        this.state.bill_to,
        this.props.sessionId,
        tokenR,
        appDynamics,
        this.props.countryCode
      );
      this.recaptchaRef.current.reset();
    } else if (this.state.active === "cinecash") {
      metodo = "cinecash";
      const { access_token } = this.props.loginResponse;
      this.props.requestCineCashPayment(
        this.state.bill_to,
        this.props.sessionId,
        access_token,
        this.props.cinemaName,
        this.props.movieName,
        this.state.bill_to.loyalty,
        tokenR,
        appDynamics,
        this.props.countryCode
      );
      this.recaptchaRef.current.reset();
    } else if (this.state.active === "loyalty_tickets") {
      metodo = "club cinépolis";
      let card_number = this.state.bill_to.loyalty;
      if (typeof this.state.bill_to.card_cc_number !== "undefined")
        card_number = this.state.bill_to.card_cc_number;

      this.props.requestClubCinepolisPayment(
        this.state.bill_to,
        this.props.sessionId,
        this.state.bill_to.cc_pin,
        card_number,
        tokenR,
        appDynamics,
        this.props.countryCode
      );
      this.recaptchaRef.current.reset();
    } else if (this.state.active === "paypal_tickets") {
      metodo = "paypal";
      this.props.requestPaypalOrder(
        this.props.sessionId,
        this.props.selectedTickets,
        this.props.total,
        this.props.cinema_id,
        this.state.bill_to,
        tokenR,
        this.props.concessions,
        this.props.bookingFee,
        appDynamics,
        this.props.countryCode
      );
    } else if (this.state.active === "price-zero") {
      metodo = "price-zero";
      this.props.requestPriceZeroPayment(
        this.state.bill_to,
        this.props.sessionId,
        tokenR,
        appDynamics,
        this.props.countryCode
      );
      this.recaptchaRef.current.reset();
    }
    else if (this.state.active === "kueski_tickets") {

      metodo = "kueski";

      const settings_kueski_purchases = this.props.settings_purchases.find(st => st.payment_provider === 'kueski');

      if (!settings_kueski_purchases) {

        this.props.hideModal();
        this.props.timeResetCancelLoading();
        this.setState({
          active: null,
          valid: false,
          checkout_id: null
        });
        this.recaptchaRef.current.reset();
        this.props.showModal({ type: 'error iframe', message: 'Lo sentimos metódo de pago no esta disponible.' });

        localStorage.removeItem('checkout_id_kueski');

        return;

      }

      //! Si no carga settings de kueski para saber montos de compra minima 

      if (!Object.values(settings_kueski_purchases).length) {

        this.props.hideModal();
        this.props.timeResetCancelLoading();
        this.setState({
          active: null,
          valid: false,
          checkout_id: null
        });
        this.recaptchaRef.current.reset();
        this.props.showModal({ type: 'error iframe', message: 'Lo sentimos metódo de pago no esta disponible.' });
        localStorage.removeItem('checkout_id_kueski');

        return;
      }

      //! Si total es menor a la compra minima permitida -> mostrar alerta error por compra minima
      if (this.props.total < settings_kueski_purchases?.min_amount) {

        this.props.hideModal();
        this.props.timeResetCancelLoading();
        this.setState({
          active: null,
          valid: false,
          checkout_id: null
        });
        this.recaptchaRef.current.reset();
        this.props.showModal({ type: 'error iframe', position: 'aside', message: settings_kueski_purchases?.custom_alerts[0]?.message || 'Lo sentimos, ocurrió un error.' });

        localStorage.removeItem('checkout_id_kueski');
        let closeButton = document.querySelector('.modal__close');
        if (closeButton) closeButton.addEventListener('click', () => document.location.href = '/boletos');

        return;
      }

      //! Si total es mayor a la compra máxima permitida -> mostrar alerta error por compra máxima
      if (this.props.total > settings_kueski_purchases?.max_amount) {

        this.props.hideModal();
        this.props.timeResetCancelLoading();
        this.setState({
          active: null,
          valid: false,
          checkout_id: null
        });
        this.recaptchaRef.current.reset();
        this.props.showModal({ type: 'error iframe', position: 'aside', message: settings_kueski_purchases?.custom_alerts[1]?.message || 'Lo sentimos, ocurrió un error.' });

        localStorage.removeItem('checkout_id_kueski');
        let closeButton = document.querySelector('.modal__close');
        if (closeButton) closeButton.addEventListener('click', () => document.location.href = '/boletos');

        return;
      }

      //* Compra minima es valida -> Proceso para obtener checkout_id de kueski y la url para abrir en iframe o redirigir en al misma ventana
      this.setState({ tokenRecaptcha: tokenR, paymentProvider: "kueski_pay" });

      const res = this.props.requestKueskiOrder(this.props.sessionId,
        this.props.selectedTickets,
        this.props.total,
        this.props.cinema_id,
        this.state.bill_to,
        tokenR,
        this.props.concessions,
        this.props.bookingFee,
        appDynamics,
        this.props.countryCode,
        this.state.bill_to.tarjeta_cc
      ).then(res => {
        //! Errores dentro la res de servicio kueski checkout
        if (res.status && res.status === 400) {

          this.props.hideModal();
          this.props.timeResetCancelLoading();
          this.setState({
            active: null,
            valid: false,
            checkout_id: null
          });
          this.recaptchaRef.current.reset();
          this.props.showModal({ type: 'error iframe', message: res.message || 'Lo sentimos, ocurrió un error.' });
          localStorage.removeItem('checkout_id_kueski');
          let closeButton = document.querySelector('.modal__close');
          if (closeButton) closeButton.addEventListener('click', () => document.location.href = '/boletos');

          return res;
        }

        if (res.status_code === -1) {

          this.props.hideModal();
          this.props.timeResetCancelLoading();
          this.setState({
            active: null,
            valid: false,
            checkout_id: null
          });
          this.recaptchaRef.current.reset();
          this.props.showModal({ type: 'error iframe', message: res.message || 'Lo sentimos, ocurrió un error.' });
          localStorage.removeItem('checkout_id_kueski');
          let closeButton = document.querySelector('.modal__close');
          if (closeButton) closeButton.addEventListener('click', () => document.location.href = '/boletos');

          return res;
        }

        if (!res.checkout_id) {

          this.props.hideModal();
          this.props.timeResetCancelLoading();
          this.setState({
            active: null,
            valid: false,
            checkout_id: null
          });
          this.recaptchaRef.current.reset();
          this.props.showModal({ type: 'error iframe', message: res.message || 'Lo sentimos, ocurrió un error.' });
          localStorage.removeItem('checkout_id_kueski');
          let closeButton = document.querySelector('.modal__close');
          if (closeButton) closeButton.addEventListener('click', () => window.document.href = '/boletos');

          return;
        }

        //* Retorna un checkout_id valido y lo abrimos en la misma ventana de compra web cinépolis
        this.setState({
          checkout_id: res.checkout_id
        });
        this.recaptchaRef.current.reset();
        //*Guardar checkout_id_kueski en localstorage
        localStorage.setItem('checkout_id_kueski', res.checkout_id);
        //*Redireccion para web de kueski
        window.location = res.url;

      }).catch(err => {
        //! Ocurre un error durante el request hacia servicio de kueski
        this.props.hideModal();
        this.props.timeResetCancelLoading();
        this.setState({
          active: null,
          valid: false,
          checkout_id: null
        });
        this.recaptchaRef.current.reset();
        this.props.showModal({ type: 'error iframe', message: 'Lo sentimos, ocurrió un error.' });
        localStorage.removeItem('checkout_id_kueski');
        let closeButton = document.querySelector('.modal__close');
        if (closeButton) closeButton.addEventListener('click', () => window.document.href = '/boletos');
      })



    }

    let hasRunTagManager = false;

    if(this.props.countryCode === 'CL' || this.props.countryCode === 'CO' || this.props.countryCode === 'PA' || this.props.countryCode === 'CR' || this.props.countryCode === 'GT' || this.props.countryCode === 'SV'  || this.props.countryCode === 'PE') {
      this.setState({
        enableBeginCheckoutEvent: !(localStorage.getItem('active-type') === this.state.active),
      });
    }

    if (!hasRunTagManager && this.state.enableBeginCheckoutEvent) {
      let tagManagerArgs = {
        dataLayer: {
          event: "begin_checkout",
          times: new Date().toLocaleTimeString('es-MX', { hour12: false }),
          items: [{
            option: metodo
          }]
        },
      };

      if(this.props.countryCode === 'CR' || this.props.countryCode === 'CO'  || this.props.countryCode === 'PA' || this.props.countryCode === 'CL'|| this.props.countryCode === 'GT' || this.props.countryCode === 'SV'  || this.props.countryCode === 'PE') {
        const paymentTypeMethod = (
          this.state.active ? 
          (
            this.state.active.includes('spreedly') ? 
            'Tarjeta de crédito / débito' : 
            (
              this.state.active.includes('c2p') ?
              'Click to pay' : 
              this.state.active
            )
          ) : 
          'NA'
        );
        
        localStorage.setItem('active-type', this.state.active);
        localStorage.setItem('payment-type', paymentTypeMethod);

        const dataProductsStorage = JSON.parse( localStorage.getItem('tickets') ) ?? [];
        const selectedSeats = (
                                this.props.textselectedSeats !== null || 
                                this.props.textselectedSeats !== undefined || 
                                this.props.textselectedSeats !== ''
                              ) ? 
                              (this.props.textselectedSeats).split(',') : 
                              [];
        
        for (let item of dataProductsStorage) {

            for (let index = 0; index < item.quantity; index++) {
              item.item_category3 = index === 0 ?
                                    `${(selectedSeats[index]).trim()}` :
                                    `${item.item_category3 }${(selectedSeats[index]).trim()}${(index+1) === item.quantity ? '' : ', '}`;
            }

            selectedSeats.splice(0, item.quantity);

        }
        
        localStorage.setItem('tickets', JSON.stringify(dataProductsStorage));
        localStorage.setItem('metodoPago', JSON.stringify(metodo));
        console.log(dataProductsStorage);
        console.log(this.props.showtime.movie.original_name);
        tagManagerArgs = {
          dataLayer: {
            event: "begin_checkout",
            ecommerce: {
              city_name: this.props.cityName ?? 'NA', 
              movie_date: this.props.showtime.showtimes.datetime.split('T')[0].replaceAll("-", " ") ?? 'NA',
              movie_time: this.props.showtime.showtimes.datetime.split('T')[1] ?? 'NA',
              times: new Date().toLocaleTimeString('es-MX', { hour12: false }) ?? 'NA',
              Language: this.props.movieFormat ?? 'NA', 
              payment_type: this.props.countryCode === "CL" ? paymentTypeMethod : metodo,
              currency: this.props.currencyCode ?? 'NA', 
              coupon: this.props.folio?.name || 'NA',
              value: !isNaN(this.props.total) ? +formatPriceTwoDecimals(this.props.total) : 0,
              items: [
                ...dataProductsStorage
              ]
            },
          },
        }
      }

      if(!this.props.isMX) TagManager.dataLayer(tagManagerArgs);
      hasRunTagManager = true;
    }
    this.setState({ hideButtons: true });
  };

  handleRecaptcha = (e) => {
    e.preventDefault();
    this.recaptchaRef.current.reset()
    if (this.state.valid) this.recaptchaRef.current.execute()
  };

  getReturnRoute = () => {
    const { hasSeats } = this.props;
    if (hasSeats) {
      return "/asientos";
    } else {
      return "/boletos";
    }
  };

  loadScript = () => {
    const fingerprintId = this.props.cinema_id + "_" + this.props.sessionId;
    const fingerMerchantId = (this.props.countryCode === 'CL' ? 'cinepolisch' : this.props.merchantId);
    let tag = document.createElement("script");
    tag.async = false;
    tag.src =
      "https://h.online-metrix.net/fp/tags.js?org_id=9ozphlqx&session_id=" +
      fingerMerchantId +
      fingerprintId;
    let iframe = document.createElement("iframe");
    document.head.appendChild(tag);
    document.body.appendChild(iframe);
    iframe.setAttribute(
      "src",
      "https://h.online-metrix.net/fp/tags?org_id=9ozphlqx&session_id=" +
      fingerMerchantId +
      fingerprintId
    );
    iframe.setAttribute(
      "style",
      "width: 100px; height: 100px; border: 0; position: absolute; top: -5000px;"
    );
    let visaCheckOutJs = document.createElement("script");
    visaCheckOutJs.src = process.env.REACT_APP_CNPL_PURCHASE_URL_VC;
    document.body.appendChild(visaCheckOutJs);
  };

  positionBtnPayment = () => {
    const aside = document.querySelector(".main-aside__body");
    if (aside < 150) return 0;
    return aside.offsetHeight
  };

  render() {
    const { withFolio, modal, isMobile, wSize, loginResponse } = this.props;
    const { isLoading, paymentResponse } = this.props.payments;
    //if (globalError !== null && globalError.status_code > 0) return <LocalRedirect to={{ pathname: '/error', state: { message: globalError.message } }} />
    if (
      typeof paymentResponse.tickets_confirmation !== "undefined" &&
      paymentResponse.tickets_confirmation !== null &&
      typeof paymentResponse.tickets_confirmation.status !== "undefined"
    ) {
      if (paymentResponse.tickets_confirmation.status === 0) {
        return (
          <LocalRedirect
            to={{
              pathname: "/confirmacion",
              state: { activeSonicBrand: this.state.activeSonicBrand },
            }}
          />
        );
      }
    }

    if (
      typeof paymentResponse.url !== "undefined" &&
      this.state.valid &&
      this.state.active === "paypal_tickets"
    ) {
      window.location = paymentResponse.url;
    }
    return (
      <Fragment>
        {this.props.isMobileOrDesktop && !isLoading && (
          <Return to={this.getReturnRoute()} />
        )}

        <form onSubmit={this.handleRecaptcha} autoComplete="off">
          <div className="container container--mid-large payments">
            {isLoading || this.state.isLoading_kueski ? (
              <Loader text="" />
            ) : (
              <div
                className={`${this.state.valid && isLoading ? "blur-overlay" : ""
                  }`}
              >
                <PaymentSharedFields
                  bill_to={this.state.bill_to}
                  onInputChange={this.handleInputChange}
                  folio={this.props.tickets}
                  onInputBlur={this.handleInputBlur}
                  updateValue={
                    loginResponse !== null &&
                    loginResponse.access_token !== null
                  }
                  active={this.state.active}
                  isLoyalty={this.props.isLoyalty}
                  countryCode={this.props.countryCode}
                />

                {
                  this.props.countryCode === 'CO' &&
                  <BillingData
                    onInputChange={this.handleInvoicingData}
                    onInputBlur={this.handleInputBlur}
                    isLoyalty={this.props.isLoyalty}
                    countryCode={this.props.countryCode}
                    label={'Prueba'}
                    active={this.state.active}
                    finalConsumer={this.state.finalConsumer}
                    handleFinalConsumer={() => this.handleFinalConsumer()}
                    invoicing_data={this.state.invoicing_data}
                  />
                }

                <PaymentMethods
                  active={this.state.active}
                  isMobile={isMobile && wSize < 680}
                  mobilePaymentMethodsOpen={this.state.mobilePaymentMethodsOpen}
                  handleActiveForm={this.handleActiveForm}
                  onInputChange={this.handleInputChange}
                  onInputBlur={this.handleInputBlur}
                  onSubmit={this.handleInputs}
                  onChangeMobileMethods={this.handleOpenMobileMethods}
                  modal={this.props.modal}
                  disabled={this.props.paymentMethodsDisabled}
                  terms={this.props.terms}
                  privacy_policy={this.props.privacy_policy}
                  paymentMethodsSettings={this.props.paymentMethodsSettings}
                  isPriceZero={this.props.isPriceZero}
                  isLoyalty={this.props.isLoyalty}
                  isAnnualPass={this.props.isAnnualPass}
                  accept_terms={this.state.bill_to.accept_terms}
                  hasFolio={this.props.hasFolio}
                  countryCode={this.props.countryCode}
                  availablePayments={this.props.availablePayments}
                  showModal={this.props.showModal}
                  isMX={this.props.isMX}
                />

                {
                  this.props.countryCode !== 'CO' &&
                  <BillingData
                    onInputChange={this.handleInvoicingData}
                    onInputBlur={this.handleInputBlur}
                    isLoyalty={this.props.isLoyalty}
                    countryCode={this.props.countryCode}
                    label={'Prueba'}
                    active={this.state.active}
                    finalConsumer={this.state.finalConsumer}
                    handleFinalConsumer={() => this.handleFinalConsumer()}
                    invoicing_data={this.state.invoicing_data}
                  />
                }
              </div>

            )}
            <ReCAPTCHA
              ref={this.recaptchaRef}
              size="invisible"
              sitekey="6LcYxXcUAAAAAPd2-4EIfCksacpQd6xVYtQsRP_D"
              onChange={this.handleSubmit}
            />

            <div
              className={`payments__submit${withFolio !== null ? " extra-top" : ""
                }${modal !== null && modal.position === "aside"
                  ? " blur-overlay"
                  : ""
                }`}
              style={{ top: `${this.positionBtnPayment()}px` }}
            >
              {this.state.active !== "visa_checkout" && !isLoading && (
                <PaymentSubmitButton
                  isMobile={!this.props.isMobileOrDesktop}
                  wSize={wSize}
                  isPaymentLoading={isLoading}
                  active={this.state.active}
                  valid={
                    this.props.countryCode === 'CO' || this.props.countryCode === 'GT' ?
                    this.state.valid && this.state.valid_invoicing :
                    this.state.valid
                  }
                />
              )}

              <img
                alt="Visa Checkout"
                className={`v-button${this.state.active === "visa_checkout" && this.state.valid
                  ? " active"
                  : ""
                  }`}
                role="button"
                hidden={
                  this.state.valid && this.state.active === "visa_checkout"
                    ? `none`
                    : `hidden`
                }
                src="https://secure.checkout.visa.com/wallet-services-web/xo/button.png"
              />
              {!this.props.isMobileOrDesktop && (
                <img
                  alt="Visa Checkout"
                  className={`v-button v-button__header${this.state.active === "visa_checkout" && this.state.valid
                    ? " active"
                    : ""
                    }`}
                  role="button"
                  hidden={
                    this.state.valid && this.state.active === "visa_checkout"
                      ? `none`
                      : `hidden`
                  }
                  src="https://secure.checkout.visa.com/wallet-services-web/xo/button.png"
                />
              )}
            </div>

            {!isLoading || this.state.isLoading_kueski && (
              <div
                className={`payments__disclaimer${modal !== null && modal.position === "aside"
                  ? " blur-overlay"
                  : ""
                  }`}
              >
                <p className="text text--small">
                  Si requieres modificar tu compra antes de confirmar tu orden
                  puedes usar el botón Regresar.
                </p>
              </div>
            )}
          </div>
        </form>

        {!this.props.isMobileOrDesktop && !isLoading && (
          <Return to={this.getReturnRoute()} />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  ui,
  payments,
  globals,
  login,
  showtime,
  tickets,
  cards,
  seats,
  locations,
}) => {
  let availablePayments = null
  const paymentMethodsByPromotion = showtime.settings.tickets
  if (paymentMethodsByPromotion) {
    const selectedPromotion = tickets.selectedTickets.filter(ticket => ticket.folio).map(ticket => ticket.folio.toUpperCase())
    paymentMethodsByPromotion.forEach(({ promotion, payment_methods }) => {
      if (selectedPromotion.includes(promotion.toUpperCase() ? promotion.toUpperCase() : promotion)) {
        if (availablePayments === null) availablePayments = new Set()
        payment_methods.forEach(payment => availablePayments.add(payment))
      }
    })
  }

  let cityName = ''
  if (locations && locations.cities) {
    const currentCity = locations.cities.find(city => {
      const hasCurrentCinema = city?.cinemas.find(cinema => cinema.vista_id === showtime.cinema.vista_id)
      return hasCurrentCinema
    })
    cityName = currentCity?.name
  }

  return {
    countryCode: globals.countryCode,
    sessionId: globals.sessionId,
    isMobile: ui.isMobile,
    wSize: ui.size,
    modal: ui.modal,
    movieName: showtime.movie.name,
    movieFormat: showtime.movie.language,
    movieDuration: showtime.movie.length,
    movieLanguage: showtime.movie.format,
    movieScreen: showtime.showtimes.screen,
    cinemaName: showtime.cinema.name,
    cinemaLat: showtime.cinema.lat,
    cinemaLng: showtime.cinema.lng,
    loginResponse: login.loginResponse,
    cinema_id: showtime.cinema.vista_id,
    paymentMethodsDisabled:
      showtime.showtimeInfo !== null &&
        typeof showtime.showtimeInfo.payment_methods_disabled !== "undefined"
        ? showtime.showtimeInfo.payment_methods_disabled
        : [],
    paymentMastercardSettings:
      showtime.paymentSettings !== null &&
        typeof showtime.paymentSettings.mastercard_brand !== "undefined"
        ? showtime.paymentSettings.mastercard_brand
        : [],
    movieDistributor: showtime.distributor,
    terms: showtime.settings.purchases.terms,
    privacy_policy: showtime.settings.purchases.privacy_policy,
    paymentMethodsSettings: showtime.settings.payment_methods,
    withFolio: cards.folio !== null,
    isLoyalty:
      cards.folio !== null &&
      typeof cards.folio.type !== "undefined" &&
      cards.folio.type === "loyalty",
    isAnnualPass: cards.folio !== null && cards.folio.code === "pase-anual",
    isPriceZero: tickets.total === 0,
    total: seats.seatsSelectedResponse.total_value,
    selectedTickets: tickets.selectedTickets,
    payments,
    concessions: seats.concessions_data,
    bookingFee:
      typeof seats.seatsSelectedResponse.booking_fee_value !== "undefined"
        ? seats.seatsSelectedResponse.booking_fee_value
        : 0,
    isMobileOrDesktop:
      (ui.isMobile && ui.size >= 768 && ui.size < 1024) || !ui.isMobile,
    merchantId: locations.merchantId,
    hasSeats: seats.hasSeats,
    hasFolio: tickets.selectedTickets.some((ticket) => ticket.folio !== null),
    currencyCode: showtime.settings?.currency.code ? showtime.settings.currency.code : 'MXN',
    settings_purchases: showtime?.settings?.purchases?.payments_methods || [],
    availablePayments,
    isMX: showtime.settings?.currency.code === 'MXN',
    cityName,
    showtime,
    folio: cards.folio,
    textselectedSeats: selectedSeatsHumanSelector({
      ui, payments, globals, tickets, seats, showtime
    }),
  };
};

export default connect(mapStateToProps, {
  requestReservation,
  setSessionId,
  setStartTime,
  resetOrder,
  resetTime,
  routeChanged,
  hideModal,
  showModal,
  requestBankCard,
  requestCineCashPayment,
  requestClubCinepolisPayment,
  requestPaypalOrder,
  requestKueskiOrder,
  requestVisaCheckOutDecrypt,
  requestVisaCheckout,
  setTokenRecaptcha,
  requestAnnualPassPayment,
  requestPriceZeroPayment,
  setPaymentType,
  paymentRequest,
  requestSpreedly,
  clearPaymentResponse,
  timeResetCancelLoading,
  kueskiOrderComplete,
  kueskiCompleteOrderCheckout
})(Payments);
