import React from 'react';
import { connect } from 'react-redux';

import hideFromRoutes from '../hoc/hideFromRoutes';
import Timer from './Timer';

const MobileTopBar = (props) => {
  if (props.timer.startDate === null || !props.isMobile) {
    return null;
  }

  return (
    <div className="mobile-bar">
      <Timer />
    </div>
  );
};

const mapStateToProps = ({ globals, ui }) => ({
  isMobile: ui.isMobile,
  timer: globals.timer
});

export default hideFromRoutes(
  connect(
    mapStateToProps
  )(MobileTopBar),
['/error', '/confirmacion', '/']);
