import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import isEqual from 'lodash/isEqual';

import { validations, mask } from '../helpers';


class Input extends Component {
  constructor (props) {
    super (props);

    this.inputRef = React.createRef();
    this.state = {
      value: props.value,
      active: props.value && props.value.length,
      touched: false,
      errors: [],
      updated: false,
      className: typeof props.className === 'string' ? props.className : '',
      validationRules: typeof props.validations === 'function' ? props.validations() : props.validations
    };
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    if (nextProps.updateValue && !prevState.updated) {
      const val = nextProps.value;

      return {
        ...prevState,
        value: val,
        active: val !== '',
        errors: val !== '' ? [] : prevState.errors,
        touched: val !== '' ? prevState.touched : false,
        updated: true
      };
    }

    if (!isEqual(typeof nextProps.validations === 'function' ? nextProps.validations() : nextProps.validations, prevState.validationRules)) {
      return {
        ...prevState,
        validationRules: typeof nextProps.validations === 'function' ? nextProps.validations() : nextProps.validations
      };
    }

    return null;
  }

  componentDidMount () {
    if (this.props.autofocus) {
      this.handleFocus();
      this.inputRef.current.focus();
    }
  }

  shouldComponentUpdate (prevProps, prevState) {
    const { value, active, touched, errors } = this.state;

    if (prevState.value === value && prevProps.value === value && prevState.active === active && prevState.touched === touched 
      && prevState.errors.length === errors.length && isEqual(typeof prevProps.validations === 'function' ? prevProps.validations() : prevProps.validations, prevState.validationRules)) {
      return false;
    }

    return true;
  }

  isValid (elem) {
    const { value } = elem;

    return this.state.validationRules.reduce((acc, rule) => {
      let [ ruleName, args ] = rule.split(':');

      if (ruleName === 'confirmation') {
        args = elem.parentElement.parentElement.querySelector(`#${args}`).value;
      }

      //      if (validations[ruleName](this.props.pattern ? (this.props.noReplaceSpace ? value : value.replace(/\s/g, '')) : value, args)) {
      if (validations[ruleName](this.props.pattern ? value.replace(/\s/g, '') : value, args)) {
        return acc;
      }

      return acc.concat(ruleName);
    }, []);
  }

  handleKeyDown = (e) => {
    return this.key = e.which || e.keyCode || 0;
  };

  handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    this.props.onChange(name, checked, checked);
    this.setState({ value: checked });
  };

  handleChange = (e) => {
    const { target } = e;
    let { value, name } = target;
    const errors = this.isValid(target);

    if (this.props.pattern) {
      value = mask(this.props.pattern, value, this.key);
    }
    
    value = value.replace(/\s\s+/g, ' ');
    /*  if (errors.indexOf('alpha') > -1 || errors.indexOf('numeric') > -1 ||
      errors.indexOf('maxLength') > -1 || errors.indexOf('noSpaces') > -1 ||
      errors.indexOf('onlyNumberLetters') > -1 || errors.indexOf('numbersHyphens') > -1 ||
      errors.indexOf('maxLengthBillingCO') > -1 || errors.indexOf('dui') > -1 ||
      errors.indexOf('taxName') > -1 || errors.indexOf('address') > -1) {
       */
    if (name === "tax_number") value = value.toUpperCase();

    if (errors.indexOf('alpha') > -1 || errors.indexOf('numeric') > -1 || 
        errors.indexOf('maxLength') > -1 || errors.indexOf('noSpaces') > -1 ||
         errors.indexOf('onlyNumberLetters') > -1 || errors.indexOf('numbersHyphens') > -1 ||
        errors.indexOf('maxLengthBillingCO') > -1) {
      this.setState({ errors });
      window.setTimeout(() => {
        this.setState({ errors: [] });
      }, 800);
      return;
    } else {
      this.props.onChange(name, value, errors.length === 0);
      this.setState((prevState) => ({
        value: value,
        active: prevState.value.length + 1 > 0,
        errors
      }));
    }

    if (typeof this.props.className === 'function') {
      const className = this.props.className(value);
      this.setState({ className });
    }
  }

  handleFocus = (e) => {
    this.setState(() => ({ active: true, touched: true }));
  };

  handleBlur = (e) => {
    const { target } = e;
    const { value, name } = target;
    const errors = this.isValid(target);

    this.props.onChange(name, value, errors.length === 0);
    this.setState((prevState) => ({
      active: prevState.value && prevState.value.length,
      touched: true,
      errors
    }));
  };

  showErrorMessage () {
    if (this.state.errors.length) {
      return this.state.validationRules.map(rule => {
        const [ ruleName, args ] = rule.split(':');

        if (this.state.errors.indexOf(ruleName) >= 0 && validations.messages[ruleName]) {
          return <span className="form-control-error" key={ruleName}>{ validations.messages[ruleName](args) }</span>;
        }

        return null;
      });
    }

    if( this.props.emptyValidInit && this.state.value == '' )
      return <span className="form-control-error">Este campo es requerido.</span>;

    return null;
  }

  showPlaceholder = () => {

  };

  clearValue() {
    if (this.inputRef.current) {
      this.setState({ value: '' });
    }
  }

  render () {
    const { name, id, type, label, required, defaultChecked } = this.props;

    // input type checkbox
    if (type === 'checkbox') {
      return (
        <div className="form-group form-group--checkbox">
          <input 
            type={type}
            name={name}
            id={id}
            value={this.state.value}
            className="check-control"
            onChange={this.handleCheckboxChange}
            defaultChecked={defaultChecked}
          />
          <label htmlFor="accept_terms" className="form-label">
            <span className="fake-check"></span>
            <span className="text">
              {this.props.children}
            </span>
          </label>
        </div>
      );
    }

    // input type: text, email, etc...
    return (
      <div className={`form-group${this.state.active ? ' active' : ''}${this.state.touched && this.state.errors.length > 0 ? ' form-group--error' : ''}`}>
        <input 
          type={type} 
          name={name} 
          id={id} 
          className={`form-control ${this.state.className}`}
          autoComplete="off" 
          value={this.state.value}
          placeholder={this.props.placeholder && this.state.active ? this.props.placeholder : ''}
          onKeyDown={this.handleKeyDown}
          onChange={this.handleChange}
          onFocus={this.handleFocus} 
          onBlur={this.handleBlur} 
          ref={this.inputRef}
        />
        {label && <label htmlFor={id} className="form-label">{required && <span className="required-field">*</span>}{label}</label>}
        { this.showErrorMessage() }
      </div>
    );
  }
}

Input.defaultProps = {
  type: 'text',
  value: '',
  className: '',
  updateValue: false,
  onChange: () => {},
  onBlur: () => {}
};

Input.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  validations: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  pattern: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  updateValue: PropTypes.bool,
  emptyValidInit: PropTypes.bool,
  //  noReplaceSpace: PropTypes.bool,
};

export default Input;
