import React, { Component } from 'react';
import cinepolisLogo from "../static/images/icons/cinepolis_logo.svg";
import paymentsIcon from "../static/images/icons/icon_payment.svg";
import { connect } from 'react-redux';
import { billingDataInput, createInvoice } from '../services';
import { showModal } from '../store/ui'
import LoaderSpinner from './LoaderSpinner';

class PaymentSuccessBillingForm extends Component {

    constructor(props) {
        super(props)
        this.countryCode = this.props.countryCode
        this.handleDisplayForm = this.props.handleDisplayForm
        this.state = {
            CR: {
                documentType: '01',
                idNumber: '',
                name: '',
                email: '',
                foreigner: 0
            },
            PA: {
                name: '',
                customerRUC: '',
                customerType: '',
                customerAddress: '',
                email: this.props.email,
                foreigner: false
            },
            
            formIsValid: false,
            successfulBilling: false,
            isLoading: false,
            enableCustomerAddress: false,
        }
    }

    validateNumber = (type, idNumber) => {
        if (this.countryCode === "CR") {
            if (!idNumber) {
                return 'El campo de cédula es requerido';
            }
            switch (type) {
                case '01':
                    if (idNumber.length !== 9) return 'La cédula física debe tener 9 dígitos';
                    if (idNumber[0] === '0') return 'La cédula física no puede comenzar con cero';
                    if (!/^[0-9]+$/.test(idNumber)) return 'La cédula física solo puede contener números';
                    break;
                case '02':
                    if (idNumber.length !== 10) return 'La cédula jurídica debe tener 10 dígitos';
                    if (!/^[0-9]+$/.test(idNumber)) return 'La cédula jurídica solo puede contener números';
                    break;
                case '03':
                    if (idNumber.length < 11 || idNumber.length > 12) return 'La DIMEX debe tener entre 11 y 12 dígitos';
                    if (idNumber[0] === '0') return 'La DIMEX no puede comenzar con cero';
                    if (!/^[0-9]+$/.test(idNumber)) return 'La DIMEX solo puede contener números';
                    break;
                case '04':
                    if (idNumber.length !== 10) return 'La NITE debe tener 10 dígitos';
                    if (!/^[0-9]+$/.test(idNumber)) return 'La NITE solo puede contener números';
                    break;
                case '05':
                    if (idNumber.length < 1 || idNumber.length > 50) return 'El número de extranjero debe tener entre 1 y 50 caracteres';
                    if (!/^[0-9a-zA-Z-]+$/.test(idNumber)) return 'El número de extranjero solo puede contener números, letras y guiones medios';
                    break;
                default:
                    return 'Tipo de cédula no válido';
            }
            return null;
        }
        if (this.countryCode === "PA") {
            if (!idNumber) return 'El campo de dígito verificador es requerido';
            if (idNumber.length < 1) return 'El dígito debe tener al menos 1 caracter';
            if (idNumber.length > 2) return 'El dígito no puede tener más de 2 caracteres';
        }
    }

    validateName = (name) => {
        if (this.countryCode === "CR") {
            if (!name) return 'El campo de nombre es requerido';
            if (name.length > 50) return 'El nombre no puede tener más de 50 caracteres';
            return null;
        }
        if (this.countryCode === "PA") {
            if (!name) return 'El campo de razón social es requerido';
            if (name.length < 3) return 'El campo de razón social debe tener al menos 3 caracteres';
            return null;
        }
    }

    validateEmail = (email) => {
        if (this.countryCode === "CR") {
            if (!email) return 'El campo de email es requerido';
            if (email.length > 50) return 'El email no puede tener más de 50 caracteres';
            if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) return 'El email no tiene un formato válido';
            return null;
        }
    }

    validateRUC = (RUC) => {
        if (this.countryCode === "PA") {
            if (!RUC) return 'El campo RUC es requerido';
            if (RUC.length < 6 || RUC.length > 16) return 'La identificación debe tener entre 6 y 16 caracteres';
            return null;
        }
    }

    validateaddress = (address) => {
        if (this.countryCode === "PA") {
            if (!address) return 'El campo address es requerido';
            if (address.length < 3) return 'La identificación debe tener al menos 3 caracteres';
            return null
        }
    }

    handleDocumentTypeChange = (event) => {
        const documentType = event.target.value;
        const errorMessage = this.validateNumber(documentType, this.state[this.countryCode].idNumber)
        this.setState({
            ...this.state,
            [this.countryCode]: {
                ...this.state[this.countryCode],
                documentType,
                idNumberError: errorMessage,
                foreigner: documentType === "04" || documentType === "05" ? 1 : 0
            }
        });
    }

    handleTaxIdentifierTypeChange = (event) => {
        const documentType = event.target.value;
        const errorMessage = this.validateNumber(documentType, this.state[this.countryCode].idNumber);
        this.setState({
            ...this.state,
            [this.countryCode]: {
                ...this.state[this.countryCode],
                documentType,
                idNumberError: errorMessage,
            }
        });
    }

    handleChange = (event, validationFn) => {
        const name = event.target.name;
        const value = event.target.value;
        const errorMessage = validationFn === this.validateNumber ? validationFn(this.state[this.countryCode].documentType, value) : validationFn(value);
        
        if (this.countryCode === "PA" && name === "customerRUC") {
            this.state.enableCustomerAddress = !errorMessage ? true : false; 
        }

        this.setState({
            ...this.state,
            [this.countryCode]: {
                ...this.state[this.countryCode],
                [name]: value,
                [`${name}Error`]: errorMessage
            }
        });
    }

    validateForm = () => {
        if (this.countryCode === "CR") {
            const { idNumber, name, email, idNumberError, nameError, emailError, documentTypeError } = this.state.CR;
            this.setState({
                ...this.state,
                formIsValid: idNumber && email && name && !idNumberError && !nameError && !emailError && !documentTypeError
            });
        } else if (this.countryCode === "PA") {
            const { name, customerRUC, customerType, customerAddress, nameError, customerRUCError, customerTypeError, customerAddressError } = this.state.PA;
            this.setState({
                ...this.state,
                formIsValid: name && customerRUC && customerType && customerAddress && !nameError && !customerRUCError && !customerTypeError && !customerAddressError
            });
        }
    };

    handleSubmit = (event) => {
        event.preventDefault()
        const idNumberErrorMessage = this.validateNumber(this.state[this.countryCode].documentType, this.state[this.countryCode].idNumber)
        const nameErrorMessage = this.validateName(this.state[this.countryCode].name)
        const emailErrorMessage = this.validateEmail(this.state[this.countryCode].email)
        const nameError = this.validateName(this.state[this.countryCode].name)
        const customerRUCError = this.validateRUC(this.state[this.countryCode].customerRUC)
        const customerTypeError = this.validateNumber(this.state[this.countryCode].customerType)
        const customerAddressError = this.validateaddress(this.state[this.countryCode].customerAddress)
        this.setState({
            ...this.state,
            isLoading: true
        })
        if (!idNumberErrorMessage || !nameErrorMessage || !emailErrorMessage || !nameError || !customerRUCError || !customerTypeError || !customerAddressError) {
            if (this.countryCode === "CR") {
                createInvoice(this.state[this.countryCode], this.countryCode, this.props.cinemaVistaId, this.props.transactionNumber)
                    .then(res => {
                        if (res.data.status_code !== 0) {
                            this.handleDisplayForm(false);
                            this.props.showModal({ type: 'error iframe', message: res.data.message });
                        } else {
                            this.setState({
                                ...this.state,
                                successfulBilling: true,
                                isLoading: false
                            });
                        }
                    })
                    .catch((err) => {
                        this.setState({
                            ...this.state,
                            successfulBilling: false,
                            isLoading: false
                        });
                        this.handleDisplayForm(false);
                        this.props.showModal({ type: 'error iframe', message: 'Hubo un error. Inténtalo de nuevo más tarde' });
                    });
            }
            else if (this.countryCode === "PA") {
                createInvoice(this.state[this.countryCode], this.countryCode, this.props.cinemaVistaId, this.props.transactionNumber)
                    .then(res => {
                        if (res.data.status_code !== 0) {
                            this.handleDisplayForm(false);
                            this.props.showModal({ type: 'error iframe', message: res.data.message });
                        } else {
                            this.setState({
                                ...this.state,
                                successfulBilling: true,
                                isLoading: false
                            });
                        }
                    })
                    .catch((err) => {
                        this.setState({
                            ...this.state,
                            successfulBilling: false,
                            isLoading: false
                        });
                        this.handleDisplayForm(false);
                        this.props.showModal({ type: 'error iframe', message: 'Hubo un error. Inténtalo de nuevo más tarde' });
                    });
            }
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (
            prevState[this.countryCode] !== this.state[this.countryCode]
        ) {
            this.validateForm()
        }
    }

    render() {
        return (
            <>
                {this.countryCode &&
                    <div className='billing'>
                        <div className='billing__container' style={this.state.successfulBilling ? { display: 'none' } : { display: '-moz-initial' }}>
                            {
                                this.state.isLoading && <LoaderSpinner />
                            }
                            <button className='billing__close-btn' onClick={() => this.handleDisplayForm(false)}>
                                <svg width="24" height="24" viewBox="0 0 24 24">
                                    <path fill="#000" fillRule="nonzero" d="M24 17.9c0 3.223-2.662 5.833-5.957 5.833H5.966C2.67 23.733 0 21.123 0 17.9V6.1C0 2.877 2.67.267 5.966.267h12.077C21.338.267 24 2.877 24 6.1v11.8zm-1.014 0V6.1c0-2.687-2.196-4.833-4.943-4.833H5.966c-2.748 0-4.943 2.146-4.943 4.833v11.8c0 2.687 2.195 4.833 4.943 4.833h12.077c2.747 0 4.943-2.146 4.943-4.833zm-7.432-7.75a.534.534 0 0 1-.361.483.554.554 0 0 1-.594-.15l-2.054-2.175v8.734a.532.532 0 0 1-.295.499.558.558 0 0 1-.545-.026.53.53 0 0 1-.25-.473V8.308l-2.046 2.175a.555.555 0 0 1-.52.163.542.542 0 0 1-.405-.358.524.524 0 0 1 .115-.521l3-3.192a.551.551 0 0 1 .81 0l3 3.192c.099.104.15.241.145.383z" opacity=".5">
                                    </path>
                                </svg>
                            </button>
                            <form className='billing__form' onSubmit={this.handleSubmit}>
                                <div className='billing__logo'><img src={cinepolisLogo} /></div>
                                <div className='billing__fields'>
                                    <fieldset className='billing__fs'>
                                        <h2 className='billing__title'>
                                            <i className='billing__logo-payments'>
                                                <img src={paymentsIcon} alt="" />
                                            </i>
                                            &nbsp;Datos de facturación
                                        </h2>
                                        {
                                            this.countryCode === "CR" ? (
                                                <>
                                                    <div className='billing__fs-field'>
                                                        <label className='billing__label'>
                                                            Tipo de documento
                                                            <select
                                                                className='billing__input-select'
                                                                value={this.state[this.countryCode].documentType}
                                                                onChange={this.handleDocumentTypeChange}
                                                            >
                                                                <option value="01">Cédula física</option>
                                                                <option value="02">Cédula jurídica</option>
                                                                <option value="03">DIMEX</option>
                                                                <option value="04">NITE</option>
                                                                <option value="05">Extranjero</option>
                                                            </select>
                                                        </label>
                                                    </div>
                                                    <div className='billing__fs-field'>
                                                        <label className='billing__label'>
                                                            <span>Cédula</span>
                                                            <input
                                                                type={this.state[this.countryCode].documentType !== "05" ? "number" : "text"}
                                                                name="idNumber"
                                                                className='billing__input'
                                                                value={this.state[this.countryCode].idNumber}
                                                                onChange={(e) => this.handleChange(e, this.validateNumber)}
                                                            />
                                                        </label>
                                                    </div>
                                                    <div className='billing__fs-field'>
                                                        <label className='billing__label'>
                                                            <span>Nombre</span>
                                                            <input
                                                                type="text"
                                                                name="name"
                                                                className='billing__input'
                                                                value={this.state[this.countryCode].name}
                                                                onChange={(e) => this.handleChange(e, this.validateName)}
                                                            />
                                                        </label>

                                                    </div>
                                                    <div className='billing__fs-field'>
                                                        <label className='billing__label'>
                                                            <span>Email</span>
                                                            <input type="email"
                                                                name="email"
                                                                className='billing__input'
                                                                value={this.state[this.countryCode].email}
                                                                onChange={(e) => this.handleChange(e, this.validateEmail)}
                                                            />
                                                        </label>
                                                    </div>
                                                </>)
                                                :
                                                this.countryCode === "PA" && (
                                                    <>
                                                        <div className='billing__fs-field'>
                                                            <label className='billing__label'>
                                                                <span>Razón social</span>
                                                                <input
                                                                    type="text"
                                                                    name="name"
                                                                    className='billing__input'
                                                                    value={this.state[this.countryCode].name}
                                                                    onChange={(e) => this.handleChange(e, this.validateName)}
                                                                />
                                                            </label>
                                                        </div>
                                                        <div className='billing__fs-field'>
                                                            <label className='billing__label'>
                                                                <span>RUC</span>
                                                                <input
                                                                    type="text"
                                                                    name="customerRUC"
                                                                    className='billing__input'
                                                                    value={this.state[this.countryCode].customerRUC}
                                                                    onChange={(e) => this.handleChange(e, this.validateRUC)}
                                                                />
                                                            </label>
                                                        </div>
                                                        <div className='billing__fs-field'>
                                                            <label className='billing__label'>
                                                                <span>Dígito verificador</span>
                                                                <input type="number"
                                                                    name="customerType"
                                                                    className='billing__input'
                                                                    value={this.state[this.countryCode].customerType}
                                                                    onChange={(e) => this.handleChange(e, this.validateNumber)}
                                                                />
                                                            </label>
                                                        </div>
                                                        {
                                                            this.state.enableCustomerAddress &&
                                                            <div className='billing__fs-field'>
                                                                <label className='billing__label'>
                                                                    <span>Dirección</span>
                                                                    <input type="text"
                                                                        name="customerAddress"
                                                                        className='billing__input'
                                                                        value={this.state[this.countryCode].customerAddress}
                                                                        onChange={(e) => this.handleChange(e, this.validateaddress)}
                                                                    />
                                                                </label>
                                                            </div>
                                                        }
                                                    </>
                                                )
                                        }
                                        
                                        {
                                            this.countryCode === "CR" ? (
                                                [this.state[this.countryCode].idNumberError &&
                                                    <div key={1} className='billing__error-msg'>{this.state[this.countryCode].idNumberError}</div>,
                                                this.state[this.countryCode].nameError &&
                                                <div key={2} className='billing__error-msg'>{this.state[this.countryCode].nameError}</div>,
                                                this.state[this.countryCode].emailError &&
                                                <div key={3} className='billing__error-msg'>{this.state[this.countryCode].emailError}</div>
                                                ]
                                            ) : (
                                                this.countryCode === "PA" && [this.state[this.countryCode].nameError &&
                                                    <div key={1} className='billing__error-msg'>{this.state[this.countryCode].nameError}</div>,
                                                this.state[this.countryCode].customerRUCError &&
                                                <div key={2} className='billing__error-msg'>{this.state[this.countryCode].customerRUCError}</div>,
                                                this.state[this.countryCode].customerTypeError &&
                                                <div key={3} className='billing__error-msg'>{this.state[this.countryCode].customerTypeError}</div>,
                                                this.state[this.countryCode].customerAddressError &&
                                                <div key={4} className='billing__error-msg'>{this.state[this.countryCode].customerAddressError}</div>
                                                ]
                                            )
                                        }
                                    </fieldset>
                                </div>
                                <fieldset className='billing__submit-field'>
                                    <button
                                        type='submit'
                                        className={`billing__submit-btn ${this.state.formIsValid ? '' : 'disabled'}`}
                                        disabled={!this.state.formIsValid}
                                    >Aceptar</button>
                                </fieldset>
                            </form>
                        </div>
                        <div className='billing__container' style={this.state.successfulBilling ? { display: 'block' } : { display: 'none' }}>
                            <div>
                                <p>Se generó su factura con éxito y le será enviada a su correo electrónico.</p>
                                <fieldset className='billing__submit-field'>
                                    <button
                                        type='submit'
                                        className="billing__submit-btn"
                                        onClick={() => this.handleDisplayForm(false)}
                                    >Salir</button>
                                </fieldset>
                            </div>
                        </div>
                    </div>}
            </>
        );
    }
}

const mapStateToProps = ({ globals, payments }) => {
    return {
        cinemaVistaId: globals.cinemaVistaId,
        transactionNumber: payments.paymentResponse.tickets_confirmation?.transaction_number,
        email: payments.bill_to?.email
    }
}

export default connect(mapStateToProps, {
    showModal
})(PaymentSuccessBillingForm);