import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PerfectScrollbar from "perfect-scrollbar";
import {
  hideMessage,
  isValidCard,
  clearCard,
  clearCardResponse,
} from "../store/cards";
import { getTicketTypes, resetSelectedTickets } from "../store/tickets";
import hideFromRoutes from "../hoc/hideFromRoutes";
import Card from "./Card";
import CardAddedList from "./CardAddedList";
const folioList = [
  "716333697731",
  "716333697768",
  "716333697851",
  "716333698052",
  "716333698089",
  "716333698168",
  "716333698211",
  "716333698277",
  "716333698306",
  "716333698577",
  "890250043240",
  "890251300699",
  "890251388349",
  "890251884387",
  "890251920943",
  "890251954611",
  "890252766103",
  "890253352082",
  "890253852626",
  "890254028346"
];
class CardsList extends PureComponent {
  constructor(props) {
    super(props);

    this.containerRef = React.createRef();

    this.state = {
      activeCard: null,
      open: false,
      activeInvalidateCard: null
    };
  }
  
  static getDerivedStateFromProps(props, state) {
    if (state.activeCard !== null && props.route !== "/boletos") {
      return {
        activeCard: null,
        open: false,
      };
    }

    return null;
  }

  componentDidMount() {
    if (this.containerRef.current !== null) {
      this.ps = new PerfectScrollbar(this.containerRef.current, {
        suppressScrollX: true,
      });
      setTimeout(() => {
        if (this.ps !== null) this.ps.update();
      }, 1500);
    }

    //if (this.props.folio === null) {
    //  this.props.clearCard();
    //}
  }

  componentWillUnmount() {
    if (this.containerRef.current !== null) {
      this.ps.destroy();
      this.ps = null;
    }
  }

  handleActiveCard = (cardName) => {
    this.setState(() => ({ activeCard: cardName }));
  };

  cancelActiveCard = () => {
    if (this.props.isLoading) {
      this.props.hideMessage();
    }
    this.setState(() => ({ activeCard: null }));
  };

  handleAddCard = (folio, token) => {
    this.props.isValidCard(folio, false, token);
     if(folio){
      const numeroStr = folio.number.toString();
       // Verificar si el primer carácter es '7' o '8' y si la longitud es 12
       if ((numeroStr[0] === '7') && numeroStr.length === 12) {
         localStorage.setItem("foliosPromocionCL", true);
       }
       else{
         localStorage.setItem("foliosPromocionCL", false);
       }
    }
   
    //folio != "" && localStorage.setItem("foliosPromocionCL", folioList.includes(folio.number));
  };

  handleRemoveCard = () => {
    this.props.clearCard();
    this.props.getTicketTypes(
      this.props.cinemaVistaId,
      this.props.showtimeVistaId,
      true,
      this.props.countryCode,
    );
    this.props.resetSelectedTickets();
  };

  handleEditCard = () => {
    this.setState({ activeCard: this.props.folio.code });
  };

  render() {
    const { activeCard } = this.state;
    const shouldCardChange = this.props.route === "/boletos";

    if (
      !this.props.folio &&
      (this.props.route === "/asientos" || this.props.route === "/pago")
    ) {
      return null;
    }

    return (
      <div
        className={`cards-list${this.state.open ? " mob-active" : ""}${
          this.state.activeCard !== null && this.props.isMobile
            ? " mob-open"
            : ""
        }`}
      >
        <div className="grabber" onClick={this.props.toggleCards}></div>

        <header className="cards-list__head">
          <p className={`text${activeCard !== null ? " hidden" : ""}`}>
            <span>¿Cuentas con alguna tarjeta o promoción?</span>
          </p>

          <span
            type="button"
            className={`icon-cross icon-cross--circle${
              activeCard !== null ? " hidden" : ""
            }`}
            onClick={this.props.toggleCards}
            aria-label="Cerrar listado tarjeta o promoción"
            style={{cursor: 'pointer'}}
          >
            <svg width="11" height="11" viewBox="0 0 11 11">
              <g fill="#000000" fillRule="nonzero">
                <path d="M.793 2.207A1 1 0 1 1 2.207.793l8.062 8.062a1 1 0 0 1-1.414 1.414L.793 2.207z" />
                <path d="M2.207 10.207A1 1 0 0 1 .793 8.793L8.855.73a1 1 0 1 1 1.414 1.414l-8.062 8.062z" />
              </g>
            </svg>
          </span>
        </header>
        <div
          className={`cards${
            this.state.activeCard !== null || this.props.folio !== null
              ? " cards--auto"
              : ""
          }`}
          ref={this.containerRef}
        >
          {this.props.cardsData.map((card, cardIndex) => {
            const hasLoyalty = Boolean(card.is_card)
            return (
              this.props.countryCode === 'CL' && !card.is_featured && card.code === 'otros' ?
              <></> :
              <Card
                hasLoyalty={hasLoyalty}
                key={`${card.name}-${card.order}`}
                active={activeCard === card.code}
                activeFolio={
                  this.props.folio !== null &&
                  card.code !== "otros"
                }
                folio={null}
                hide={activeCard !== null && activeCard !== card.code}
                name={card.name}
                code={card.code}
                use_pin={card.use_pin}
                cssTypeName={hasLoyalty ? "card--loyalty" : "card--voucher"}
                numInputs={hasLoyalty ? (card.code === "rut" ? 1 : 4) : 1}
                buttonImage={card.images.select}
                bgImage={card.images.reference}
                addedImage={card.images.added}
                style={{ animationDelay: `${cardIndex * 0.088 + 0.13}s` }}
                submitButtonText={hasLoyalty ? (card.code === "rut" ? "12345678-K" : "0000") : "folio"}
                showForm={this.handleActiveCard}
                onSubmit={this.handleAddCard}
                isLoading={this.props.isLoading}
                hideForm={this.cancelActiveCard}
                removeCard={this.handleRemoveCard}
                response={this.props.response}
                modalOpen={this.props.modalOpen}
                shouldCardChange={shouldCardChange}
                hasCardListBenefits={this.props.cardList !== []}
                isTCC={card.code === 'club-cinepolis'}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ cards, ui, globals, showtime }) => ({
  isMobile: ui.isMobile,
  showCardsList: ui.showCardsList,
  isLoading: cards.isLoading,
  response: cards.response,
  folio: cards.folio,
  cardList: cards.cardList,
  cardsData: showtime.benefits,
  cinemaVistaId: globals.cinemaVistaId,
  showtimeVistaId: globals.showtimeVistaId,
  countryCode: globals.countryCode,
  modalOpen: ui.modal !== null && ui.modal.type.indexOf("pin") > -1,
  hasLoyalty: cards.hasLoyalty,
});

export default hideFromRoutes(
  connect(mapStateToProps, {
    isValidCard,
    hideMessage,
    clearCard,
    clearCardResponse,
    getTicketTypes,
    resetSelectedTickets,
  })(CardsList),
  ["/error", "/confirmacion", "/"]
);
