import React, { Component } from 'react';

class LoaderSpinner extends Component {
    render() {
        return (
            <div className='loader-spinner'>
                <span className="loader-spinner__item"></span>
            </div>
        );
    }
}

export default LoaderSpinner;
