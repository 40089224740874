import React, { Fragment }  from 'react';
import preventBack from '../hoc/preventBack';
import localSessionStorage from "../services/localSessionStorage";
import { ClockIcon } from '../components/Icons'


const Error = (props) => {
  const sessionExpired = props.location.state && props.location.state.sessionExpired;
  const message = props.location.state && props.location.state.message ? props.location.state.message : null;
  const statusCode = props.location.state && props.location.state.statusCode ? props.location.state.statusCode : null;
  const isPresaleMovie = statusCode === 330
  const getStorage = localSessionStorage.get("cccw-state");
  let countryCode = getStorage.globals?.countryCode ? `.${getStorage.globals.countryCode.toLowerCase()}` : "";

  if((countryCode === '' || countryCode === undefined) && localStorage.getItem("toErrorCountryCode") === 'CL') {
    countryCode = '.cl';
  }
  
  const printMessage = () => {
    if (sessionExpired) {
      return (
        <Fragment>
          <p className="error__text">Lo sentimos, pero tu sesión ha terminado.</p>
          <p className="error__text">Puedes comenzar nuevamente dando clic en el siguiente botón.</p>
        </Fragment>
      );
    } else if (message !== null && message !== '') {
      return <p 
          className="error__text" 
          style={{ margin: isPresaleMovie && "2em 0 3.5em" }}
          >
          {message}
        </p>;
    }

    return <p className="error__text">Lo sentimos algo ha salido mal, estamos trantando de solucionarlo.</p>;
  };

  return (
    <div className="error">
      {!isPresaleMovie && 
        <h1 className="title">{sessionExpired ? 'Tu sesión expiró' : 'Algo salió mal'}</h1>
      }

      <figure className="error__img">
        {isPresaleMovie 
          ? <ClockIcon size={80} color='#002069'/>
          : <img src={sessionExpired ? require('../static/images/expiro.png') : require('../static/images/error.png')} alt="" /> }
      </figure>

      {printMessage()}

      <a href={
        countryCode === '.cl' ? (
          (document.location.href).includes('sls-pre') ?
            'https://preprod.cinepolischile.cl/cartelera/' :
            'https://cinepolischile.cl/cartelera/'
        ) :
        `https://cinepolis.co${countryCode !== ".cr" ? "m" : ""}${countryCode ? `${countryCode}` : ''}/cartelera/`} className="btn btn--primary" style={{ marginTop: isPresaleMovie && "0" }}>Ir al inicio</a>
    </div>
  );
};

export default preventBack(Error);
