
export const formatMinSec = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  let secs = seconds - minutes * 60;
  if (secs < 10)
    secs = `0${secs}`;

  return `${minutes}:${secs}`;
};
export const formatPrice = (cents) => (cents / 100).toLocaleString('es-MX');

export const formatPriceTwoDecimals = (cents) => (cents / 100).toFixed(2).toLocaleString('es-MX');

export const formatPriceCL = (cents) => (cents / 100).toLocaleString('es-CL');

export const catchErrors = (fn) => {
  return function (...args) {
    return fn(...args).catch(err => {
      return Promise.reject('An error ocurred: ' + err);
    });
  };
};

export const reverseObj = (obj) => {
  let newObj = {}

  Object.keys(obj)
    .sort()
    .forEach((key) => newObj[key] = obj[key]);

  return newObj;
}

export const mask = (pattern, value, key) => {
  if (key === 8) { return value; }

  const idx = value.length === 0 ? 0 : value.length;

  if (pattern[idx] && pattern[idx] !== '*') {
    return `${value}${pattern[idx]}`;
  }

  return value;
};
export const mutateValidRut = (value, key) => key !== 8 && /^[0-9a-zA-Z]{8}$/.test(value) ? value + '-' : value;

export const validations = {
  messages: {
    notEmpty: () => 'El campo no puede ir vacío.',
    email: () => 'El campo no contiene un correo electrónico válido.',
    maxLength: (length) => `El campo no puede exceder ${length} caracteres.`,
    minLength: (length) => `El campo debe tener al menos ${length} caracteres.`,
    minLengthNit: () => `El campo debe tener al menos 2 caracteres.`,
    alpha: () => 'El campo no puede incluir caracteres especiales.',
    confirmation: () => 'Los campos no corresponde.',
    rut: () => 'RUT Cinépolis inválido.',
    noSpaces: () => 'El campo no puede contener espacios.',
    numeric: () => 'El campo solo puede contener números.',
    date: () => 'Por favor ingresa una fecha válida.',
    emptyBillingName: () => 'El campo "Nombre o razón social" es requerido para su factura, por favor ingrese la información, o seleccione la opción Cosumidor final.',
    emptyBillingNameCO: () => 'Este campo es requerido.',
    emptyNit: () => 'El campo "Número de Identificación Tributaria" es requerido para su factura, por favor ingrese la información, o seleccione la opción Cosumidor final.',
    validateNit: () => 'Número de Identificación Tributaria no válido, por favor, verifique que esté correcto.',
    emptyiIdentifierDocument: () => 'Este campo es requerido.',
    withoutPointsCommas: () => 'No utilice puntos (.) ni comas (,).',
    noHyphens: () => 'No guiones (-).',
    isNumberBillingCO: () => 'No es un número válido.',
    isNumberBillingNoRutCO: () => 'No es un número válido.',
    rangeBillingCO: () => 'Rango no permitido.',
    rangeBillingCO_11_12_13: () => 'Valor no válido para el tipo de identificador seleccionado.',
    rangeBillingCO_22: () => 'Valor no válido para el tipo de identificador seleccionado.',
    rangeBillingCO_31: () => 'Valor no válido para el tipo de identificador seleccionado.',
    nuip: () => 'NUIP no válido.',
    numbersHyphens: () => 'Sólo números y guiones.',
    identifierNit: () => 'Falta identificador final.',
    maxLengthBillingCO: (length) => `El campo no puede exceder ${length} caracteres.`,
    minLengthBillingCO: (length) => `El campo debe tener al menos ${length} caracteres.`,
    onlyNumberLetters: () => 'Sólo números y letras.',
  },
  notEmpty: (value) => value && value !== '',
  email: (value) => /[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?/.test(value),
  maxLength: (value, length) => value.length <= length,
  minLength: (value, length) => value.length >= length,
  alpha: function (value) {
    if (!this.notEmpty(value)) {
      return true;
    }
    return /^[a-zA-ZÑñáéíóúÁÉÍÓÚ´]+$/.test(value.replace(/\s+/g, ''));
  },
  confirmation: (value, valueToCompare) => value === valueToCompare,
  rut: (value) => /^([0-9]{8}-(?:[0-9]|k|K){1})*$/.test(value),
  noSpaces: (value) => !/\s+/g.test(value),
  numeric: function (value) {
    if (!this.notEmpty(value)) {
      return true;
    }

    return /^[0-9]+$/.test(value.replace(/\s+/g, ''));
  },
  date: (value) => {
    const valsArr = value.split('/');
    const month = valsArr[0];
    const year = valsArr[1];
    const currentDate = new Date();
    const supplyDate = new Date(year, month, currentDate.getDay());

    return supplyDate >= currentDate;
  },
  emptyBillingName: (value) => value && value.trim() !== "",
  emptyBillingNameCO: (value) => value && value.trim() !== "",
  emptyNit: function (value) {
    if (this.notEmpty(value)) {
      return true
    }
    return value && value.trim() !== ""
  },
  minLengthNit: (value) => value.length !== 1,
  validateNit: function (value) {
    if (!this.notEmpty(value)) {
      return true
    }
    const regex = /^[1-9][0-9]{0,10}[0-9?K]$/gm;
    return value.length < 2 || regex.test(value);
  },
  isNumberBillingCO: (value) => {
    return /^\d+(\.\d+)?$/.test(value.replaceAll('-',''));
  },
  maxLengthBillingCO: (value, length) => value.length <= length,
  minLengthBillingCO: (value, length) => value.replaceAll('-','').length >= length,
  onlyNumberLetters: (value) => {
    if (!(value && value !== '')) {
      return true;
    }

    return /^[a-zA-Z0-9ñÑ]+$/.test(value);
  },
  isNumberBillingNoRutCO: (value) => {
    return /^[1-9]\d*(\.\d+)?$/.test(value);
  },
  withoutPointsCommas: (value) => {
    return /^[^.,]+$/.test(value);
  },
  emptyiIdentifierDocument: function (value) {
    if (this.notEmpty(value)) {
      return true
    }
    return value && value.trim() !== ""
  },
  noHyphens: (value) => {
    return !value.includes('-');
  },
  rangeBillingCO: (value) => {
    const isNumber    = /^\d+(\.\d+)?$/.test(value.replaceAll('-',''));
    const identifier = isNumber ? parseInt(value.replaceAll('-','')) : null ;
    if(isNumber) {
      if(identifier === 222222222222)
          return false;
      else if(identifier >= 444444001 && identifier <= 444449999)
          return false;
    }
    return true;
  },
  rangeBillingCO_11_12_13: (value) => {
    const isNumber    = /^\d+(\.\d+)?$/.test(value.replaceAll('-',''));
    const identifier = isNumber ? parseInt(value.replaceAll('-','')) : null ;
    if(isNumber) {
      if(!((identifier >= 1 && identifier <= 99999999) || (identifier >= 1000000000 && identifier <= 1999999999)))
        return false;
    }
    return true;
  },
  rangeBillingCO_22: (value) => {
    const isNumber    = /^\d+(\.\d+)?$/.test(value.replaceAll('-',''));
    const identifier = isNumber ? parseInt(value.replaceAll('-','')) : null ;
    if(isNumber) {
      if(!(identifier >= 1 && identifier <= 9999999))
        return false;
    }
    return true;
  },
  rangeBillingCO_31: (value) => {
    const isNumber    = /^\d+(\.\d+)?$/.test(value.substring(0,9).replace('-',''));
    const identifier = isNumber ? parseInt(value.substring(0,9).replace('-','')) : null ;
    if(isNumber) {
      if(!(identifier >= 800000000 && identifier <= 999999999))
        return false;
    }
    return true;
  },
  nuip: (value) => {
    return /^[1-9]\d{0,2}-\d{1,7}-\d{1,3}$/.test(value);
  },
  numbersHyphens: (value) => {
    if (!(value && value !== '')) {
      return true;
    }
    
    return /^[0-9-]+$/.test(value);
  },
  identifierNit: (value) => {
    return /-\d$/.test(value);
  },
};

export const check_os = () => {
  let userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
};
