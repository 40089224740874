import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { requestLogin, clearLoginResponse } from '../store/login';
import { toggleCardsListClose } from '../store/ui';
import { resetSelectedTickets } from '../store/tickets';
import { resetSeatsData } from '../store/seats';
import Input from '../components/Input';
import Loader from '../components/Loader';
import LocalRedirect from '../components/LocalRedirect';
import TagManager from 'react-gtm-module';


class Login extends Component {

  constructor(props) {
    super(props);

    this.state = {
      valid: false,
      user: {},
      error: null
    };
  }

  componentDidMount() {
    if (this.state.error) { this.removeErrorMessage(); }

    let paymentsTagManagerPageView;
    if(this.props.isMX) {
      paymentsTagManagerPageView = {
        dataLayer: {
          event: 'page_view',
          channel: {
            type: 'web',
            subtype: 'legacy',
            language: 'ES',
            country: 'MX'
          }
        }
      }
    } else {
      paymentsTagManagerPageView = {
        dataLayer: {
          item_name: this.props.movieName,
          event: 'page_view',
          path: '/visPaymentLogin',
          times: new Date().toLocaleTimeString('es-MX', { hour12: false })
        }
      }
    }
    
    TagManager.dataLayer(paymentsTagManagerPageView);
    this.props.resetSeatsData();
    this.props.resetSelectedTickets();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.login.loginResponse !== null && nextProps.login.loginResponse.error && prevState.error === null) {
      return {
        user: {
          email: prevState.user.email
        },
        valid: false,
        error: nextProps.login.loginResponse.error_description
      };
    }

    return null;
  };

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.valid) {
      this.isLoginValid();
    }
    if (this.props.login.loginResponse !== null && this.props.login.loginResponse.access_token !== null) {
      this.props.history.push('/boletos');
    }
  }

  handleChange = (name, value, isValid) => {
    const user = { ...this.state.user };

    if (isValid) {
      user[name] = value;
      this.setState({ user });
    } else {
      delete user[name];
      this.setState({ user, valid: false });
    }
  };

  pushData = (e) => {
    var value = e.target.dataset.letter;

    const tagManagerArgs = {
      dataLayer: {
        metodo: value,
        event: 'login'
      }
    }

    TagManager.dataLayer(tagManagerArgs);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.requestLogin(this.state.user.email, this.state.user.password);
  };

  isLoginValid() {
    const { user } = this.state;

    if (Object.keys(user).length !== 0 && !this.state.valid &&
      user.hasOwnProperty('email') && user.hasOwnProperty('password')) {
      this.setState({ valid: true });
    }
  }

  renderLoading() {
    const { isLoading } = this.props.login;
    if (isLoading) return <Loader />;
    return null;
  }

  removeErrorMessage = () => {
    this.props.clearLoginResponse();
    this.setState({ error: null });
  };

  render() {

    const { isShowCinepolisId } = this.props;

    if (!isShowCinepolisId) {
      return <LocalRedirect to="/boletos" />
    }

    if (this.props.login.loginResponse !== null && this.props.login.loginResponse.access_token !== null) {
      return <LocalRedirect to="/boletos" />
    }

    if (this.props.isLoadingTickets) return <Loader />;

    return (
      <div className="login">
        <h3 className="title">Iniciar sesión</h3>
        <p className="text">
          Te recomendamos ingresar tus datos de acceso de Cinépolis® ID, esto agilizará tu proceso de compra. Si no cuentas con una, <a href="http://www.cinepolis.com/id/registro" className="link" target="_blank" rel="noopener noreferrer">créala aquí</a>.
        </p>

        <div className="container container--small login__form">
          {this.renderLoading()}

          <form onSubmit={this.handleSubmit} className={this.props.login.isLoading ? 'blur-overlay' : ''} autoComplete="off">
            <Input
              type="email"
              name="email"
              id="email"
              label="Correo electrónico"
              onChange={this.handleChange}
              validations={['email']}
            />

            <Input
              type="password"
              name="password"
              id="password"
              label="Contrase&ntilde;a"
              onChange={this.handleChange}
              validations={['notEmpty']}
            />

            <div className="forgot">
              <a href="http://www.cinepolis.com/id/inicio-recuperar-contrasena" className="link" target="_blank" rel="noopener noreferrer">¿Olvidaste tu contraseña?</a>
            </div>

            {this.state.error !== null &&
              <div className="alert alert--warning" role="alert">
                <button type="button" className="alert__cross" onClick={this.removeErrorMessage} aria-label="close">
                  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11">
                    <g fill="#69560e" fillRule="nonzero">
                      <path d="M.793 2.207A1 1 0 1 1 2.207.793l8.062 8.062a1 1 0 0 1-1.414 1.414L.793 2.207z" />
                      <path d="M2.207 10.207A1 1 0 0 1 .793 8.793L8.855.73a1 1 0 1 1 1.414 1.414l-8.062 8.062z" />
                    </g>
                  </svg>
                </button>
                <span className="alert__msg">{this.state.error}</span>
              </div>
            }

            <button type="submit" className="btn btn--primary" data-letter="Club Cinépolis" onClick={this.pushData} disabled={this.props.login.isLoading || !this.state.valid ? 'disabled' : false}>INGRESAR</button>
          </form>
        </div>

        <div className="login__guest">
          <h3 className="title">Continuar como invitado</h3>
          <p className="text">Se solicitarán todos los datos necesarios para adquirir tus boletos.</p>

          <Link to="/boletos" data-letter="Invitado" onClick={this.pushData} className="btn btn--primary">CONTINUAR</Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ login, showtime, tickets }) => {
  let isShowCinepolisId = false;
  if (showtime) {
    if (showtime.settings) {
      if (showtime.settings.profile) {
        let profile = showtime.settings.profile;
        isShowCinepolisId = profile.indexOf("cinepolis_id") !== -1;
      }
    }
  }

  return {
    login,
    isShowCinepolisId,
    movieName: showtime.movie.name,
    movieDistributor: showtime.movie.distributor,
    isLoadingTickets: tickets.isLoading,
    isMX: showtime.settings?.currency.code === 'MXN',
  }
};


export default connect(mapStateToProps, { requestLogin, clearLoginResponse, resetSeatsData, resetSelectedTickets })(Login);
