import React, { Component } from 'react';
import Input from './Input';

class BillingData extends Component {
    constructor(props) {
        super(props);
        this.countryCode = this.props.countryCode;
        this.bill_to = this.props.bill_to;
        this.onInputChange = this.props.onInputChange;
        this.updateValue = this.props.updateValue;
        this.invoicing_data = this.props.invoicing_data;
        this.state = {
            CO: {
                enableDocument: false,
                validationDocument: [],
            }
            /*
            SV: {
        typeBilling: 'FACTURA_CLIENTES_VARIOS',
        enableEmptyDocument: true,
        validationDocument: [],
        municipalities: [],
        enableOptionEmpty: true,
        departmentId: '',
        departmentName: '',
        documents: [],
      }*/
        }
        this.placeholder = '';
        this.documentEnable = '';
        this.validEmptyInit = true;
        this.taxNumberInputRef = React.createRef();
    }
    /*componentWillMount() {

    if (this.countryCode === 'SV') {

      getSettingsInvoicing(this.countryCode).then((response) => {
        const { activities, departments, documents } = response.data[0];
        console.log('CATÁLOGOS', { activities, departments, documents });
        this.setState({
          [this.countryCode]: {
            ...this.state[this.countryCode],
            activities,
            departments,
            documents,
          }
        });
      });

    }

  }  */

    handleDocumentTypeChange = (event) => {
        const name = event.target.name;
        const documentType = event.target.value;
        const validationDocument = this.validateDocumentAssign( documentType );
        this.invoicing_data.tax_id = documentType;
        const enableDocument = documentType === '00' || documentType === '' ? false : true; 
        this.setState({
            ...this.state,
            [this.countryCode]: {
                ...this.state[this.countryCode],
                enableDocument,
                validationDocument,
            }
        });
        
        if (this.taxNumberInputRef.current) {
            this.taxNumberInputRef.current.clearValue();
        }

        this.onInputChange(name, documentType, enableDocument);

    }

    /* 
    handleBillingTypeChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [this.countryCode]: {
        ...this.state[this.countryCode],
        typeBilling: value,
      }
    });

    this.props.handleOptionConsumer(value);
  }

  handleTaxDocumentChange = (event) => {
    const { name, value } = event.target;
    const validationDocument = this.validateDocumentAssign(value);
    this.invoicing_data.tax_id_type = value;
    const enableDocument = value === '' ? false : true;
    this.setState({
      ...this.state,
      [this.countryCode]: {
        ...this.state[this.countryCode],
        enableEmptyDocument: false,
        validationDocument,
      }
    });

    if (this.taxNumberInputRef.current) {
      this.taxNumberInputRef.current.clearValue();
    }

    this.onInputChange(name, value, enableDocument);
  }

  handleTaxDepartmentChange = (event) => {
    const { name, value } = event.target;
    this.invoicing_data.tax_department = value;
    const municipalities = (this.state[this.countryCode].departments)
      .filter((v) => v.code === value)[0].municipalities
      //.map((v) => { return { ...v, code: `${value}|${v.code}|${v.name}`, } })
      || [];

    const nameDepartment = (this.state[this.countryCode].departments).find((v) => v.code === value).name;
    this.setState({
      ...this.state,
      [this.countryCode]: {
        ...this.state[this.countryCode],
        municipalities,
        enableOptionEmpty: false,
        departmentId: value,
        departmentName: nameDepartment
      }
    });
    this.onInputChange(name, value, true);
    this.invoicing_data.tax_municipality = '';
  }

  handleTaxSelectChange = (event) => {
    const { name, value } = event.target;
    if (name === 'tax_municipality')
      this.invoicing_data.tax_municipality = value;
    else if (name === 'tax_activity')
      this.invoicing_data.tax_activity = value;

    this.onInputChange(name, value, true);
  }*/

    validateDocumentAssign = (type) => {
        let valid = [];
        // if (this.countryCode === 'CO') {
        switch (type) {
            case '11': // Registro civil
                valid = ['emptyiIdentifierDocument', 'numeric', 'rangeBillingCO', 'rangeBillingCO_11_12_13', 'maxLengthBillingCO:10'];
                this.placeholder = 'Ej. 50000000';
                break;
            case '1': // Tarjeta de identidad
                valid = ['emptyiIdentifierDocument', 'numeric', 'isNumberBillingCO', 'rangeBillingCO', 'rangeBillingCO_11_12_13', 'maxLengthBillingCO:10'];
                this.placeholder = 'Ej. 1075795671';
                break;
            case '2': // Cédula de ciudadanía
                valid = ['emptyiIdentifierDocument', 'numeric', 'rangeBillingCO', 'rangeBillingCO_11_12_13', 'maxLengthBillingCO:10'];
                this.placeholder = 'Ej. 80050514';
                break;
            case '3': // Tarjeta de extranjería
                valid = ['emptyiIdentifierDocument', 'onlyNumberLetters', 'rangeBillingCO', 'maxLengthBillingCO:7'];
                this.placeholder = 'Ej. 9368261';
                break;
            case '4': // Cédula de extranjería
                valid = ['emptyiIdentifierDocument', 'numeric', 'isNumberBillingCO', 'rangeBillingCO', 'rangeBillingCO_22', 'maxLengthBillingCO:6'];
                this.placeholder = 'Ej. 539103';
                break;
            case '5': // NIT
                valid = ['emptyiIdentifierDocument', 'numbersHyphens', 'maxLengthBillingCO:11', 'withoutPointsCommas', 'rangeBillingCO_31', 'identifierNit'];
                this.placeholder = 'Ej. 999999999-9';
                break;
            case '6': // Pasaporte
                valid = ['emptyiIdentifierDocument', 'onlyNumberLetters', 'rangeBillingCO', 'minLength:9', 'maxLengthBillingCO:9'];
                this.placeholder = 'Ej. G16910914';
                break;
            case '7': // Documento de identificación extranjero
                valid = ['emptyiIdentifierDocument', 'onlyNumberLetters', 'rangeBillingCO', 'maxLengthBillingCO:20'];
                this.placeholder = 'Ej. RAW010139OF7';
                break;
            case '77': // NIT de otro país
                valid = ['emptyiIdentifierDocument', 'numbersHyphens', 'maxLengthBillingCO:11', 'withoutPointsCommas', 'rangeBillingCO_31', 'identifierNit'];
                this.placeholder = 'Ej. 999999999-9';
                break;
            case '12': // NUIP
                valid = ['emptyiIdentifierDocument', 'numbersHyphens', 'nuip', 'withoutPointsCommas', 'minLengthBillingCO:7'];
                this.placeholder = 'Ej. 987-65432-10';
                break;
            default:
                break;
        }
        /* } 
        } else if (this.countryCode === 'SV') {
      switch (type) {
        case 'DUI': // DUI
          valid = ['emptyBillingNameDui', 'numbersHyphens', 'dui', 'maxLength:10'];
          // valid = ['dui'];
          this.placeholder = 'Ej. 99999999-9';
          break;
        case 'NIT': // NIT
          valid = ['minLengthNitSV:14', 'noSpaces', 'numeric', 'maxLength:14'];
          this.placeholder = '12345678911234';
          break;
        case 'PASAPORTE': // NIT
          valid = ['minLengthPasaporte:9', 'noSpaces', 'onlyNumberLetters', 'maxLength:12'];
          this.placeholder = 'ERYA12349412';
          break;
        default:
          break;
      }
    }*/
        return valid;
    }

    render() {
        const {finalConsumer, handleFinalConsumer} = this.props
        return (
            <>
                {
                    this.countryCode === "GT" &&
                    <div className='billing-data'>
                        <h2 className='title' >Datos de facturación</h2>
                        <div>
                            <div onChange={handleFinalConsumer} className="billing-data__fields">
                                <div className="billing-data__radio-btn">
                                    <input type="radio"
                                        name="billingValue"
                                        value="finalConsumer"
                                        defaultChecked={this.props.finalConsumer}
                                    /> <span>Consumidor final</span>
                                </div>
                                <div className="billing-data__radio-btn">
                                    <input type="radio"
                                        name="billingValue"
                                        value="addDetails"
                                        defaultChecked={!this.props.finalConsumer}
                                    /> <span>Agregar mis datos</span>
                                </div>
                            </div>
                            {
                                !finalConsumer &&
                                <div className="billing-data__details row">
                                    <Input
                                        name="customer_tax_name"
                                        id="customer_tax_name"
                                        label="Nombre o razón social"
                                        value={this.invoicing_data.customer_tax_name}
                                        onChange={this.onInputChange}
                                        validations={['emptyBillingName']}
                                        updateValue={this.updateValue}
                                        required={true}
                                    />
                                    <Input
                                        name="customer_tax_number"
                                        id="customer_tax_number"
                                        label="NIT (sin guiones)"
                                        value={this.invoicing_data.customer_tax_number}
                                        onChange={this.onInputChange}
                                        validations={['minLengthNit', 'maxLength:12', 'emptyNit', 'validateNit']}
                                        updateValue={this.updateValue}
                                        required={true}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                }
                {/* {
          (this.countryCode === "SV") &&
          <div className='billing-data'>
            <div className='billing-data__details row'>
              <p className='poster-item__text form-control'>
                Selecciona un tipo de facturación. Si no requieres factura o no conoces tus datos selecciona clientes varios y procede a seleccionar el método de pago.
              </p>
            </div>
            <div className="billing-data__fields">
              <div className='form-group form-group--select'>
                <select
                  name="tax_id"
                  id="tax_id"
                  className='select-control'
                  onChange={this.handleBillingTypeChange}
                >
                  {
                    (this.state[this.countryCode].documents).map((value) => {
                      return <option value={value.key} key={`${value.key}-${value.name}`}>{value.name}</option>
                    })
                  }
                </select>
              </div>
            </div>
            {this.state[this.countryCode].typeBilling !== 'FACTURA_CLIENTES_VARIOS' &&
              <>
                <div className="billing-data__details row">
                  <Input
                    name="tax_name"
                    id="tax_name"
                    label="Nombre o razón social"
                    value={this.invoicing_data.tax_name}
                    onChange={this.onInputChange}
                    validations={['notEmpty', 'taxName', 'maxLength:50']}
                    updateValue={this.updateValue}
                    required={true}
                  />
                  {this.state[this.countryCode].typeBilling === 'CREDITO_FISCAL' &&
                    <Input
                      name="tax_nrc"
                      id="tax_nrc"
                      label="NRC"
                      value={this.invoicing_data.tax_nrc}
                      onChange={this.onInputChange}
                      validations={['numeric', 'minLengthNrc:2', 'maxLength:7']}
                      updateValue={this.updateValue}
                      required={true}
                    />
                  }
                </div>
                <div className="billing-data__details row">
                  <div className={`form-group form-group--select ${this.invoicing_data.tax_id === '' ? 'form-group-billing' : ''}`}>
                    <select
                      name="tax_id_type"
                      id="tax_id_type"
                      className='select-control'
                      value={this.invoicing_data.tax_id_type}
                      onChange={this.handleTaxDocumentChange}
                    >
                      {
                        this.state[this.countryCode].enableEmptyDocument &&
                        <option value="">&nbsp;&nbsp;Selecciona un documento</option>
                      }

                      {
                        this.state[this.countryCode].documents.filter((value) => {
                          return value.key === this.state[this.countryCode].typeBilling;
                        })[0].identifier_types.map((value) => {
                          return <option value={value} key={`${value}`}>{value}</option>
                        })
                      }
                    </select>
                  </div>
                  {
                    this.invoicing_data.tax_id_type !== '' &&
                    <Input
                      ref={this.taxNumberInputRef}
                      name="tax_number"
                      id="tax_number"
                      label="Identificador fiscal"
                      value={this.invoicing_data.tax_number}
                      onChange={this.onInputChange}
                      validations={this.state[this.countryCode].validationDocument}
                      updateValue={this.updateValue}
                      placeholder={this.placeholder}
                      pattern={this.invoicing_data.tax_id_type === 'DUI' ? '********-*' : ''}
                      clearValue={() => { if (this.taxNumberInputRef.current) this.taxNumberInputRef.current.clearValue(); }}
                      required={true}
                      noReplaceSpace={true}
                    />
                  }
                </div>
                <div className="billing-data__details row">
                  <Input
                    name="tax_address"
                    id="tax_address"
                    label="Dirección"
                    value={this.invoicing_data.tax_address}
                    onChange={this.onInputChange}
                    validations={['address', 'maxLength:100']}
                    updateValue={this.updateValue}
                    required={true}
                  />
                  <div className={`form-group form-group--select ${this.invoicing_data.tax_department === '' ? 'form-group-billing' : ''}`}>
                    <select
                      name="tax_department"
                      id="tax_department"
                      className='select-control'
                      value={this.invoicing_data.tax_department}
                      onChange={this.handleTaxDepartmentChange}
                    >
                      {
                        this.state[this.countryCode].enableOptionEmpty &&
                        <option value="">&nbsp;&nbsp;Selecciona un departamento</option>
                      }
                      {
                        (this.state[this.countryCode].departments).map((value) => <option value={value.code} key={`${value.code}-${value.name}`}>{value.name}</option>)
                      }
                    </select>
                  </div>
                </div>
                <div className="billing-data__details row">
                  <div className={`form-group form-group--select ${this.invoicing_data.tax_municipality === '' ? 'form-group-billing' : ''}`}>
                    <select
                      name="tax_municipality"
                      id="tax_municipality"
                      className='select-control'
                      value={this.invoicing_data.tax_municipality}
                      onChange={this.handleTaxSelectChange}
                      disabled={this.state[this.countryCode].enableOptionEmpty}
                    >
                      <option value="">&nbsp;&nbsp;Municipio</option>
                      {
                        (this.state[this.countryCode].municipalities).map((value) => <option value={`${this.state[this.countryCode].departmentId}|${value.code}|${this.state[this.countryCode].departmentName}-${value.name}`} key={`${value.code}-${value.name}`}>{value.name}</option>)
                      }
                    </select>
                  </div>
                  {this.state[this.countryCode].typeBilling === 'CREDITO_FISCAL' &&
                    <div className={`form-group form-group--select ${this.invoicing_data.tax_activity === '' ? 'form-group-billing' : ''}`}>
                      <select
                        name="tax_activity"
                        id="tax_activity"
                        className='select-control'
                        value={this.invoicing_data.tax_activity}
                        onChange={this.handleTaxSelectChange}
                      >
                        <option value="">&nbsp;&nbsp;Actividad principal</option>
                        {
                          (this.state[this.countryCode].activities).map((value) => <option value={`${value.code}|${value.activity}`} key={`${value.code}-${value.activity}`}>{value.activity}</option>)
                        }
                      </select>
                    </div>
                  }
                </div>
              </>
            }
          </div>
        }*/}
                {
                    this.countryCode === "CO" &&
                    <div className='billing-data col-2'>
                        <h2 className='title' >Datos de facturación</h2>
                        <div>
                            <div onChange={handleFinalConsumer} className="billing-data__fields">
                                <div className="billing-data__radio-btn">
                                    <input type="radio"
                                        name="billingValue"
                                        value="finalConsumer"
                                        defaultChecked={this.props.finalConsumer}
                                    /> <span>Consumidor final</span>
                                </div>
                                <div className="billing-data__radio-btn">
                                    <input type="radio"
                                        name="billingValue"
                                        value="addDetails"
                                        defaultChecked={!this.props.finalConsumer}
                                    /> <span>Agregar mis datos</span>
                                </div>
                            </div>
                            {
                                !finalConsumer &&
                                <>
                                    <div className="billing-data__details row">
                                        <Input
                                            name="tax_name"
                                            id="tax_name"
                                            label="Nombre o razón social"
                                            value={this.invoicing_data.tax_name}
                                            onChange={this.onInputChange}
                                            validations={['emptyBillingNameCO', 'maxLengthBillingCO:50']}
                                            updateValue={this.updateValue}
                                            required={true}
                                            emptyValidInit={this.validEmptyInit}
                                        />
                                    </div>
                                    <div className="billing-data__details row">
                                        <div className='form-group form-group--select'>
                                            <select
                                                name="tax_id"
                                                id="tax_id"
                                                className='select-control'
                                                value={this.invoicing_data.tax_id}
                                                onChange={this.handleDocumentTypeChange}
                                            >
                                                <option value="">Tipo de identificador fiscal</option>
                                                <option value="11">Registro civil</option>
                                                <option value="1">Tarjeta de identidad</option>
                                                <option value="2">Cédula de ciudadanía</option>
                                                <option value="3">Tarjeta de extranjería</option>
                                                <option value="4">Cédula de extranjería</option>
                                                <option value="5">NIT</option>
                                                <option value="6">Pasaporte</option>
                                                <option value="7">Documento de identificación extranjero</option>
                                                {/* <option value="77">NIT de otro país</option> */}
                                                <option value="12">NUIP</option>
                                            </select>
                                            {
                                                this.invoicing_data.tax_id === '' &&
                                                <span className="form-control-error">Este campo es requerido.</span> 
                                            }
                                        </div>
                                        {
                                            
                                            this.invoicing_data.tax_id !== '' && 
                                            <Input
                                                ref={this.taxNumberInputRef}
                                                name="tax_number"
                                                id="tax_number"
                                                label="Identificador fiscal"
                                                value={this.invoicing_data.tax_number.toUpperCase()}
                                                onChange={this.onInputChange}
                                                validations={this.state[this.countryCode].validationDocument}
                                                updateValue={this.updateValue}
                                                required={true}
                                                placeholder={this.placeholder}
                                                pattern={this.invoicing_data.tax_id === '5' ? '*********-*' : ''}
                                                emptyValidInit={this.validEmptyInit}
                                                clearValue={() => { if (this.taxNumberInputRef.current) this.taxNumberInputRef.current.clearValue(); }}
                                            />
                                        }
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                }
            </>
        );
    }
};

export default BillingData;
