import React from 'react';
import { connect } from 'react-redux';


const hideFromRoutes = (WrappedComponent, routes = []) => {
  const mapStateToProps = ({ globals }) => ({
    route: globals.route
  });

  return connect(mapStateToProps)((props) => {
    if (routes.some(route => props.route.replace(/\/$/, '') === route.replace(/\/$/, ''))) {
      return null;
    }

    return <WrappedComponent {...props} />
  });
};



export default hideFromRoutes;
