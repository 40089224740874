import React from 'react';
import { PropTypes } from 'prop-types';


const PaymentCardPoints = (props) => (
  <div className="payment__card-points">
    <div className="payment__card-points__left"><p className="text">AL DÍA DE HOY TIENES:</p></div>
    <div className="payment__card-points__right">
      <div className="payment__card-points__num">${props.points}</div>
      <p className="text">{props.text}</p>
    </div>
  </div>
);

PaymentCardPoints.propTypes = {
  points: PropTypes.number,
  text: PropTypes.string
};

export default PaymentCardPoints;
