import {
  cardsService,
  recoverPinService,
  benefitInvalidateService,
} from "../services/index";
import {
  reloadTickets,
  GET_TICKET_TYPES,
  reloadCurrentTickets,
  getTicketTypes,
} from "./tickets";
import { toggleCardsList, showModal } from "../store/ui";

/*
  Constants
*/
const IS_VALID_CARD = "IS_VALID_CARD";
const ADD_CARD = "ADD_CARD";
const CLEAR_CARD = "CLEAR_CARD";
const CLEAR_CARD_RESPONSE = "CLEAR_CARD_RESPONSE";
const CLEAR_LOADER = "CLEAR_LOADER";
const HIDE_MESSAGE = "HIDE_MESSAGE";
const RECOVER_PIN_REQUEST = "RECOVER_PIN_REQUEST";
const RECOVER_PIN_RESPONSE = "RECOVER_PIN_RESPONSE";
const CLEAR_RECOVER_PIN_RESPONSE = "CLEAR_RECOVER_PIN_RESPONSE";
const INVALIDATE_CARD = "INVALIDATE_CARD";
const DELETE_LOADING = "DELETE_LOADING";
const DELETE_BENEFITS = "DELETE_BENEFITS";
/*
  Action creators
*/
export const isValidCard =
  (folio, setTicketsLoader = false, tokenR = undefined) =>
  async (dispatch, getState) => {
    const dataFromFolioToCardsService = {
      number: !isNaN(folio.number) ? folio.number : (folio.number).toUpperCase(),
      pin: folio.pin,
    };
    if (getState().cards.isLoading) {
      return;
    }

    const { showtimeVistaId, cinemaVistaId, countryCode } = getState().globals;
    const { movie, showtimes } = getState().showtime;
    const { sessionId } = getState().globals;
    var app_dynamics = {
      cinema_type: movie.format,
      screen: "Sala " + showtimes.screen,
    };
    if (setTicketsLoader) dispatch({ type: GET_TICKET_TYPES });
    dispatch({ type: IS_VALID_CARD });
    cardsService(
      showtimeVistaId,
      cinemaVistaId,
      dataFromFolioToCardsService,
      app_dynamics,
      sessionId,
      tokenR,
      countryCode
    ).then((data) => {
      dispatch({ type: ADD_CARD, payload: { folio, response: data } });
      if (data.status_code === 0 || data.status_code === -1) {
        window.setTimeout(() => {
          // wait for cards loader animation to end
          dispatch(reloadTickets(data));
          dispatch({ type: CLEAR_LOADER });
        }, 4000);
      } else {
        //dispatch(toggleCardsList());
        dispatch(reloadCurrentTickets());
        dispatch({ type: CLEAR_LOADER });
        if (data.status_code === 1043) {
          //sessionId not found
          dispatch(getTicketTypes(cinemaVistaId, showtimeVistaId, false, countryCode));
          dispatch(showModal({ type: "error card", position: "aside" }));
          dispatch({ type: DELETE_BENEFITS });
        }
      }
    });
  };

export const benefitInvalidate =
  (folio, setTicketsLoader = false, tokenR = undefined) =>
  async (dispatch, getState) => {
    if (getState().cards.isLoading) {
      return;
    }

    const { showtimeVistaId, cinemaVistaId, countryCode } = getState().globals;
    const { movie, showtimes } = getState().showtime;
    const { sessionId } = getState().globals;
    var app_dynamics = {
      cinema_type: movie.format,
      screen: "Sala " + showtimes.screen,
    };
    if (setTicketsLoader) dispatch({ type: GET_TICKET_TYPES });
    dispatch({ type: IS_VALID_CARD });
    dispatch({ type: DELETE_LOADING });
    benefitInvalidateService(
      showtimeVistaId,
      cinemaVistaId,
      folio,
      app_dynamics,
      sessionId,
      tokenR, 
      countryCode
    ).then((data) => {
      dispatch({ type: INVALIDATE_CARD, payload: { folio, response: data } });
      if (data.status_code === 0) {
        window.setTimeout(() => {
          // wait for cards loader animation to end
          dispatch(reloadTickets(data));
        }, 4000);
      } else {
        if (data.status_code === 1043) {
          //sessionId not found
          dispatch(getTicketTypes(cinemaVistaId, showtimeVistaId, false, countryCode));
          dispatch(showModal({ type: "error card", position: "aside" }));
          dispatch({ type: DELETE_BENEFITS });
        }
      }
      dispatch({ type: CLEAR_LOADER });
      dispatch({ type: CLEAR_CARD_RESPONSE });
    });
  };
export const clearLoader = () => ({ type: CLEAR_LOADER });
export const clearCardResponse = () => ({ type: CLEAR_CARD_RESPONSE });
export const clearCard = () => ({
  type: CLEAR_CARD,
  metadata: { local: "save" },
});
export const hideMessage = () => ({ type: HIDE_MESSAGE });
export const clearRecoverPinResponse = () => ({
  type: CLEAR_RECOVER_PIN_RESPONSE,
});

export const recoverPin = (card, countryCode) => (dispatch) => {
  dispatch({ type: RECOVER_PIN_REQUEST });
  recoverPinService(card, countryCode).then((response) => {
    dispatch({ type: RECOVER_PIN_RESPONSE, payload: response.data });
  });
};
/*
  Reducer
*/
const initialState = {
  isLoading: false,
  response: null,
  folio: null,
  recoverPinResponse: null,
  cardList: [],
  isDeleteLoading: false,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case IS_VALID_CARD:
      return { ...state, isLoading: true };
    case ADD_CARD: {
      const { folio, response } = action.payload;
      let modifications = {};
      if (response.status_code === 0) {
        modifications = {
          cardList: [...state.cardList, folio],
          folio,
        };
      }
      return {
        ...state,
        response,
        ...modifications,
      };
    }
    case INVALIDATE_CARD: {
      const { folio, response } = action.payload;
      let cardList = [...state.cardList];
      if (response.status_code === 0) {
        cardList = cardList.filter((benefit) => {
          return benefit !== folio;
        });
      }
      return {
        ...state,
        response,
        folio: null,
        cardList,
      };
    }
    case CLEAR_LOADER:
      return { ...state, isLoading: false, isDeleteLoading: false };
    case CLEAR_CARD_RESPONSE:
      return { ...state, response: null };
    case CLEAR_CARD:
      return initialState;
    case HIDE_MESSAGE:
      return { ...state, isLoading: false };
    case RECOVER_PIN_REQUEST:
      return { ...state, isLoading: true };
    case RECOVER_PIN_RESPONSE:
      return { ...state, isLoading: false, recoverPinResponse: action.payload };
    case CLEAR_RECOVER_PIN_RESPONSE:
      return { ...state, recoverPinResponse: null };
    case DELETE_LOADING:
      return { ...state, isDeleteLoading: true };
    case DELETE_BENEFITS:
      return { ...state, cardList: [] };
    default:
      return state;
  }
};
