import React, { Component } from 'react';
import { connect } from 'react-redux';
import { cancelOrder } from '../store/order';
import { decreaseTime } from '../store/globals';
import { showModal, hideModal } from '../store/ui';
import { formatMinSec } from '../helpers';
import LocalRedirect from './LocalRedirect';
import hideFromRoutes from '../hoc/hideFromRoutes';

class Timer extends Component {
  constructor(props) {
    super(props);

    this.sessionKey = '__fVwf9JLcCU66KprY';
    let countDown = sessionStorage.getItem(this.sessionKey);
    if (countDown === null) {
      countDown = props.globals.timer.countdown;
    }

    this.state = {
      time: countDown,
      limitDate: props.globals.timer.limitDate
    };
  }

  static getDerivedStateFromProps (props, state) {
    if (props.globals.timer.limitDate !== state.limitDate) {
      return {
        limitDate: props.globals.timer.limitDate
      };
    }

    return null;
  }

  componentDidMount() {
    this.intervalId = window.setInterval(this.setTimer, 1000);
  }

  shouldComponentUpdate(prevProps, prevState) {
    if (prevState.limitDate !== this.state.limitDate && prevState.time !== this.state.time
      && this.props.globals.timer.limitDate === prevProps.globals.timer.limitDate) {
      return false;
    }

    return true;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.limitDate > prevState.limitDate) {
      this.setState({ time: this.props.globals.timer.countdown });
      return;
    }
    if (this.state.time <= 30 && (this.props.ui.modal === null || (this.props.ui.modal !== null && this.props.ui.modal.type !== 'time limit')) 
      && prevState.time > this.state.time) {
      this.props.showModal({ type: 'time limit', position: 'aside' });
    }
  }

  clearIntervalId () {
    clearInterval(this.intervalId);
    this.intervalId = null;
  }

  componentWillUnmount() {
    this.clearIntervalId();
    sessionStorage.clear(this.sessionKey);
  }

  setTimer = () => {
    this.setState(prevState => ({
      time: prevState.time - 1
    }));
     
    if (this.state.time === 0) {
      this.clearIntervalId();
      this.props.cancelOrder(this.props.globals.sessionId, true, this.props.globals.countryCode);
      this.props.hideModal();
      return;
    }
    sessionStorage.setItem(this.sessionKey, this.state.time);
  };

  render() {
    if (this.state.time === 0) {
      return <LocalRedirect to={{ pathname: '/error', state: { sessionExpired: true } }} />;
    }

    return (
      <div className="container timer">
        <div className="timer__text">
          <span className="timer__title">
            <svg width="14" height="14" viewBox="0 0 14 14">
              <g fill="#010002" fillRule="evenodd">
                <path d="M6.794 13.588A6.802 6.802 0 0 1 0 6.794 6.802 6.802 0 0 1 6.794 0a6.802 6.802 0 0 1 6.794 6.794 6.801 6.801 0 0 1-6.794 6.794zm0-12.811A6.024 6.024 0 0 0 .777 6.794c0 3.318 2.699 6.018 6.017 6.018 3.318 0 6.017-2.7 6.017-6.018A6.024 6.024 0 0 0 6.794.777z" />
                <path d="M8.878 9.266a.386.386 0 0 1-.275-.114L6.52 7.07a.388.388 0 0 1-.114-.275V2.386a.388.388 0 0 1 .776 0v4.247l1.97 1.97a.388.388 0 0 1-.274.663z" />
              </g>
            </svg>
            <span className="text">Tiempo restante</span>
          </span>
          <span className="timer__time">{formatMinSec(this.state.time)}</span>
        </div>
        <div className="timer__progressbar"><div style={{ width: `${(this.state.time * 100) / this.props.globals.timer.countdown}%` }}></div></div>
      </div>
    )
  }
}
export default hideFromRoutes(
  connect(
    ({ globals, ui }) => ({ globals, ui }), 
    { cancelOrder, decreaseTime, showModal, hideModal }
  )(Timer)
, ['/error', '/confirmacion', '/', '/boletos']);
