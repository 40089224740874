import {
  reservationService,
  bankCardService,
  cineCashBalance,
  cineCashPaymentService,
  clubCinepolisPaymentService,
  paypalOrderService,
  payPalService,
  kueskiServiceOrder,
  kueskiServiceOrderComplete,
  visaCheckOutDecryptService,
  visaCheckoutService,
  annualPassService,
  priceZeroService,
  spreedlyService,
  getAppleWalletService,
  spreedlyPurchaseStatus,
  reservationServicePurchaseStatus,
  bankCardServicePurchaseStatus,
  cineCashPurchaseStatus,
  clubCinepolisPurchaseStatus,
  payPalPurchaseStatus,
  visaPurchaseStatus,
  annualPassPurchaseStatus,
  priceZeroPurchaseStatus,
} from '../services';
import { showModal } from './ui';
import fileSaver from 'file-saver';
import { resetOrder } from '../store/order';
/*
  Constants
*/
const CLOSE_MODAL_SPREEDLY = 'CLOSE_MODAL_SPREEDLY';
export const SET_SESSIONID_PAYMENTS = 'SET_SESSIONID_PAYMENTS';
export const REMOVE_SESSIONID_PAYMENTS = 'REMOVE_SESSIONID_PAYMENTS';
const CINECASH_BALANCE_REQUEST = 'CINECASH_BALANCE_REQUEST';
const CINECASH_BALANCE_RESPONSE_SUCCESS = 'CINECASH_BALANCE_RESPONSE_SUCCESS';
const CLEAR_PAYMENT_RESPONSE = 'CLEAR_PAYMENT_RESPONSE';
const PAYMENT_REQUEST = 'PAYMENT_REQUEST';
const PAYMENT_RESPONSE = 'PAYMENT_RESPONSE';
const VISA_CHECKOUT_DECRYPT_RESPONSE = 'VISA_CHECKOUT_DECRYPT_RESPONSE';
const VISA_CHECKOUT_DECRYPT_REQUEST = 'VISA_CHECKOUT_DECRYPT_REQUEST';
const SET_TOKEN_RECAPTCHA = 'SET_TOKEN_RECAPTCHA';
const SET_PAYMENT_TYPE = 'SET_PAYMENT_TYPE';
const APPLEWALLET_SUCCESS = 'APPLEWALLET_SUCCESS';
const PAYMENT_REQUEST_ERROR = 'PAYMENT_REQUEST_ERROR';
/*
  Action creators
*/

function _base64ToArrayBuffer(base64) {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}

export const spreedlyCheckTimeOut = (iteration, data, sessionId, fingerprintId, paymentMethod, tokenR, firstSixDigits, appDynamics, countryCode, paymentProvider) => (dispatch, getState) => {
  dispatch({ type: PAYMENT_REQUEST });
  resetOrder(sessionId, true, countryCode)
  spreedlyPurchaseStatus(data, sessionId, fingerprintId, paymentMethod, tokenR, firstSixDigits, appDynamics, countryCode, paymentProvider)
    .then((response) => {
      const baseActionResult = { type: PAYMENT_RESPONSE, payload: response.data, sessionId };
      if (response.status !== 200) {
        iteration += 1
        if (iteration <= 18) {
          setTimeout(() => spreedlyCheckTimeOut(iteration, data, sessionId, fingerprintId, paymentMethod, tokenR, firstSixDigits, appDynamics, countryCode, paymentProvider)(dispatch, getState), 5000);
        }
        else {
          document.location.href = "/error";
        }
      } else {
        if (response.data.tickets_confirmation === null) {
          dispatch(baseActionResult);
          dispatch(showModal({ type: 'error payment', position: 'aside' }));
        } else {
          if (response.data.tickets_confirmation.transaction_number === null || response.data.tickets_confirmation.booking_number === null || response.data.tickets_confirmation.booking_id === null) {
            dispatch(baseActionResult);
            dispatch(showModal({ type: 'error payment', position: 'aside' }));
          } else {
            dispatch({ ...baseActionResult, metadata: { local: 'clearAll' } });
          }
        }
      }
    })
    .catch((error) => {
      if (error.response.status >= 500) {
        if (iteration <= 5) {
          iteration += 1
          setTimeout(() => spreedlyCheckTimeOut(iteration, data, sessionId, fingerprintId, paymentMethod, tokenR, firstSixDigits, appDynamics, countryCode, paymentProvider)(dispatch, getState), 0);
        } else {
          document.location.href = "/error";
        }
      } else {
        document.location.href = "/error";
      }
    })
}

export const reservationServiceCheckTimeOut = (iteration, data, sessionId, tokenR, appDynamics, countryCode) => (dispatch, getState) => {
  dispatch({ type: PAYMENT_REQUEST });
  resetOrder(sessionId, true, countryCode)
  reservationServicePurchaseStatus(data, sessionId, tokenR, appDynamics, countryCode)
    .then((response) => {
      const baseActionResult = { type: PAYMENT_RESPONSE, payload: response.data, sessionId };
      if (response.status !== 200) {
        iteration += 1
        if (iteration <= 18) {
          setTimeout(() => reservationServiceCheckTimeOut(iteration, data, sessionId, tokenR, appDynamics, countryCode)(dispatch, getState), 5000);
        }
        else {
          document.location.href = "/error";
        }
      } else {
        if (response.data.tickets_confirmation === null) {
          dispatch(baseActionResult);
          dispatch(showModal({ type: 'error payment', position: 'aside' }));
        } else {
          if (response.data.tickets_confirmation.transaction_number === null || response.data.tickets_confirmation.booking_number === null || response.data.tickets_confirmation.booking_id === null) {
            dispatch(baseActionResult);
            dispatch(showModal({ type: 'error payment', position: 'aside' }));
          } else {
            dispatch({ ...baseActionResult, metadata: { local: 'clearAll' } });
          }
        }
      }
    })
    .catch((error) => {
      if (error.response.status >= 500) {
        if (iteration <= 5) {
          iteration += 1
          setTimeout(() => reservationServiceCheckTimeOut(iteration, data, sessionId, tokenR, appDynamics, countryCode)(dispatch, getState), 0);
        } else {
          document.location.href = "/error";
        }
      } else {
        document.location.href = "/error";
      }
    })
}

export const bankCardCheckTimeOut = (iteration, data, sessionId, fingerprintId, tokenR, appDynamics, countryCode) => (dispatch, getState) => {
  dispatch({ type: PAYMENT_REQUEST });
  resetOrder(sessionId, true, countryCode)
  bankCardServicePurchaseStatus(data, sessionId, fingerprintId, tokenR, appDynamics, countryCode)
    .then((response) => {
      const baseActionResult = { type: PAYMENT_RESPONSE, payload: response.data, sessionId };
      if (response.status !== 200) {
        iteration += 1
        if (iteration <= 18) {
          setTimeout(() => bankCardCheckTimeOut(iteration, data, sessionId, fingerprintId, tokenR, appDynamics, countryCode)(dispatch, getState), 5000);
        }
        else {
          document.location.href = "/error";
        }
      } else {
        if (response.data.tickets_confirmation === null) {
          dispatch(baseActionResult);
          dispatch(showModal({ type: 'error payment', position: 'aside' }));
        } else {
          if (response.data.tickets_confirmation.transaction_number === null || response.data.tickets_confirmation.booking_number === null || response.data.tickets_confirmation.booking_id === null) {
            dispatch(baseActionResult);
            dispatch(showModal({ type: 'error payment', position: 'aside' }));
          } else {
            dispatch({ ...baseActionResult, metadata: { local: 'clearAll' } });
          }
        }
      }
    })
    .catch((error) => {
      if (error.response.status >= 500) {
        if (iteration <= 5) {
          iteration += 1
          setTimeout(() => bankCardCheckTimeOut(iteration, data, sessionId, fingerprintId, tokenR, appDynamics, countryCode)(dispatch, getState), 0);
        } else {
          document.location.href = "/error";
        }
      } else {
        document.location.href = "/error";
      }
    })
}

export const cineCashCheckTimeOut = (iteration, sessionId, accessToken, cinemaName, movieName, loyaltyCard, tokenR, appDynamics, countryCode) => (dispatch, getState) => {
  dispatch({ type: PAYMENT_REQUEST });
  resetOrder(sessionId, true, countryCode)
  cineCashPurchaseStatus(sessionId, accessToken, cinemaName, movieName, loyaltyCard, tokenR, appDynamics, countryCode)
    .then((response) => {
      const baseActionResult = { type: PAYMENT_RESPONSE, payload: response.data, sessionId };
      if (response.status !== 200) {
        iteration += 1
        if (iteration <= 18) {
          setTimeout(() => cineCashCheckTimeOut(iteration, sessionId, accessToken, cinemaName, movieName, loyaltyCard, tokenR, appDynamics, countryCode)(dispatch, getState), 5000);
        }
        else {
          document.location.href = "/error";
        }
      } else {
        if (response.data.tickets_confirmation === null) {
          dispatch(baseActionResult);
          dispatch(showModal({ type: 'error payment', position: 'aside' }));
        } else {
          if (response.data.tickets_confirmation.transaction_number === null || response.data.tickets_confirmation.booking_number === null || response.data.tickets_confirmation.booking_id === null) {
            dispatch(baseActionResult);
            dispatch(showModal({ type: 'error payment', position: 'aside' }));
          } else {
            dispatch({ ...baseActionResult, metadata: { local: 'clearAll' } });
          }
        }
      }
    })
    .catch((error) => {
      if (error.response.status >= 500) {
        if (iteration <= 5) {
          iteration += 1
          setTimeout(() => cineCashCheckTimeOut(iteration, sessionId, accessToken, cinemaName, movieName, loyaltyCard, tokenR, appDynamics, countryCode)(dispatch, getState), 0);
        } else {
          document.location.href = "/error";
        }
      } else {
        document.location.href = "/error";
      }
    })
}

export const clubCinepolisCheckTimeOut = (iteration, data, sessionId, pin, loyaltyNumber, tokenR, appDynamics, countryCode) => (dispatch, getState) => {
  dispatch({ type: PAYMENT_REQUEST });
  resetOrder(sessionId, true, countryCode)
  clubCinepolisPurchaseStatus(data, sessionId, pin, loyaltyNumber, tokenR, appDynamics)
    .then((response) => {
      const baseActionResult = { type: PAYMENT_RESPONSE, payload: response.data, sessionId };
      if (response.status !== 200) {
        iteration += 1
        if (iteration <= 18) {
          setTimeout(() => clubCinepolisCheckTimeOut(iteration, data, sessionId, pin, loyaltyNumber, tokenR, appDynamics, countryCode)(dispatch, getState), 5000);
        }
        else {
          document.location.href = "/error";
        }
      } else {
        if (response.data.tickets_confirmation === null) {
          dispatch(baseActionResult);
          dispatch(showModal({ type: 'error payment', position: 'aside' }));
        } else {
          if (response.data.tickets_confirmation.transaction_number === null || response.data.tickets_confirmation.booking_number === null || response.data.tickets_confirmation.booking_id === null) {
            dispatch(baseActionResult);
            dispatch(showModal({ type: 'error payment', position: 'aside' }));
          } else {
            dispatch({ ...baseActionResult, metadata: { local: 'clearAll' } });
          }
        }
      }
    })
    .catch((error) => {
      if (error.response.status >= 500) {
        if (iteration <= 5) {
          iteration += 1
          setTimeout(() => clubCinepolisCheckTimeOut(iteration, data, sessionId, pin, loyaltyNumber, tokenR, appDynamics, countryCode)(dispatch, getState), 0);
        } else {
          document.location.href = "/error";
        }
      } else {
        document.location.href = "/error";
      }
    })
}

export const paypalCheckTimeOut = (iteration, sessionId, token, payerId, billTo, loyaltyCard, appDynamics, countryCode) => (dispatch, getState) => {
  dispatch({ type: PAYMENT_REQUEST });
  resetOrder(sessionId, true, countryCode)
  payPalPurchaseStatus(sessionId, token, payerId, billTo, loyaltyCard, appDynamics)
    .then((response) => {
      const baseActionResult = { type: PAYMENT_RESPONSE, payload: response.data, sessionId };
      if (response.status !== 200) {
        iteration += 1
        if (iteration <= 18) {
          setTimeout(() => paypalCheckTimeOut(iteration, sessionId, token, payerId, billTo, loyaltyCard, appDynamics, countryCode)(dispatch, getState), 5000);
        }
        else {
          document.location.href = "/error";
        }
      } else {
        if (response.data.tickets_confirmation === null) {
          dispatch(baseActionResult);
          dispatch(showModal({ type: 'error payment', position: 'aside' }));
          document.location.href = "/boletos";
        } else {
          if (response.data.tickets_confirmation.transaction_number === null || response.data.tickets_confirmation.booking_number === null || response.data.tickets_confirmation.booking_id === null) {
            dispatch(baseActionResult);
            dispatch(showModal({ type: 'error payment', position: 'aside' }));
            document.location.href = "/boletos";
          } else {
            dispatch({ ...baseActionResult, metadata: { local: 'clearAll' } });
          }
        }
      }
    })
    .catch((error) => {
      if (error.response.status >= 500) {
        if (iteration <= 5) {
          iteration += 1
          setTimeout(() => paypalCheckTimeOut(iteration, sessionId, token, payerId, billTo, loyaltyCard, appDynamics, countryCode)(dispatch, getState), 0);
        } else {
          document.location.href = "/error";
        }
      } else {
        document.location.href = "/error";
      }
    })
}

export const visaCheckTimeOut = (iteration, data, wrappedKey, billTo, callId, card, cinema, fingerPrintId, loyaltyCard, movie, sessionId, appDynamics, countryCode) => (dispatch, getState) => {
  dispatch({ type: PAYMENT_REQUEST });
  resetOrder(sessionId, true, countryCode)
  visaPurchaseStatus(data, wrappedKey, billTo, callId, card, cinema, fingerPrintId, loyaltyCard, movie, sessionId, appDynamics)
    .then((response) => {
      const baseActionResult = { type: PAYMENT_RESPONSE, payload: response.data, sessionId };
      if (response.status !== 200) {
        iteration += 1
        if (iteration <= 18) {
          setTimeout(() => visaCheckTimeOut(iteration, data, wrappedKey, billTo, callId, card, cinema, fingerPrintId, loyaltyCard, movie, sessionId, appDynamics, countryCode)(dispatch, getState), 5000);
        }
        else {
          document.location.href = "/error";
        }
      } else {
        if (response.data.tickets_confirmation === null) {
          dispatch(baseActionResult);
          dispatch(showModal({ type: 'error payment', position: 'aside' }));
        } else {
          if (response.data.tickets_confirmation.transaction_number === null || response.data.tickets_confirmation.booking_number === null || response.data.tickets_confirmation.booking_id === null) {
            dispatch(baseActionResult);
            dispatch(showModal({ type: 'error payment', position: 'aside' }));
          } else {
            dispatch({ ...baseActionResult, metadata: { local: 'clearAll' } });
          }
        }
      }
    })
    .catch((error) => {
      if (error.response.status >= 500) {
        if (iteration <= 5) {
          iteration += 1
          setTimeout(() => visaCheckTimeOut(iteration, data, wrappedKey, billTo, callId, card, cinema, fingerPrintId, loyaltyCard, movie, sessionId, appDynamics, countryCode)(dispatch, getState), 0);
        } else {
          document.location.href = "/error";
        }
      } else {
        document.location.href = "/error";
      }
    })
}

export const annualPassCheckTimeOut = (iteration, data, sessionId, tokenR, appDynamics, countryCode) => (dispatch, getState) => {
  dispatch({ type: PAYMENT_REQUEST });
  resetOrder(sessionId, true, countryCode)
  annualPassPurchaseStatus(data, sessionId, tokenR, appDynamics)
    .then((response) => {
      const baseActionResult = { type: PAYMENT_RESPONSE, payload: response.data, sessionId };
      if (response.status !== 200) {
        iteration += 1
        if (iteration <= 18) {
          setTimeout(() => annualPassCheckTimeOut(iteration, data, sessionId, tokenR, appDynamics, countryCode)(dispatch, getState), 5000);
        }
        else {
          document.location.href = "/error";
        }
      } else {
        if (response.data.tickets_confirmation === null) {
          dispatch(baseActionResult);
          dispatch(showModal({ type: 'error payment', position: 'aside' }));
        } else {
          if (response.data.tickets_confirmation.transaction_number === null || response.data.tickets_confirmation.booking_number === null || response.data.tickets_confirmation.booking_id === null) {
            dispatch(baseActionResult);
            dispatch(showModal({ type: 'error payment', position: 'aside' }));
          } else {
            dispatch({ ...baseActionResult, metadata: { local: 'clearAll' } });
          }
        }
      }
    })
    .catch((error) => {
      if (error.response.status >= 500) {
        if (iteration <= 5) {
          iteration += 1
          setTimeout(() => annualPassCheckTimeOut(iteration, data, sessionId, tokenR, appDynamics, countryCode)(dispatch, getState), 0);
        } else {
          document.location.href = "/error";
        }
      } else {
        document.location.href = "/error";
      }
    })
}

export const priceZeroCheckTimeOut = (iteration, data, sessionId, tokenR, appDynamics, countryCode) => (dispatch, getState) => {
  dispatch({ type: PAYMENT_REQUEST });
  resetOrder(sessionId, true, countryCode)
  priceZeroPurchaseStatus(data, sessionId, tokenR, appDynamics)
    .then((response) => {
      const baseActionResult = { type: PAYMENT_RESPONSE, payload: response.data, sessionId };
      if (response.status !== 200) {
        iteration += 1
        if (iteration <= 18) {
          setTimeout(() => priceZeroCheckTimeOut(iteration, data, sessionId, tokenR, appDynamics, countryCode)(dispatch, getState), 5000);
        }
        else {
          document.location.href = "/error";
        }
      } else {
        if (response.data.tickets_confirmation === null) {
          dispatch(baseActionResult);
          dispatch(showModal({ type: 'error payment', position: 'aside' }));
        } else {
          if (response.data.tickets_confirmation.transaction_number === null || response.data.tickets_confirmation.booking_number === null || response.data.tickets_confirmation.booking_id === null) {
            dispatch(baseActionResult);
            dispatch(showModal({ type: 'error payment', position: 'aside' }));
          } else {
            dispatch({ ...baseActionResult, metadata: { local: 'clearAll' } });
          }
        }
      }
    })
    .catch((error) => {
      if (error.response.status >= 500) {
        if (iteration <= 5) {
          iteration += 1
          setTimeout(() => priceZeroCheckTimeOut(iteration, data, sessionId, tokenR, appDynamics, countryCode)(dispatch, getState), 0);
        } else {
          document.location.href = "/error";
        }
      } else {
        document.location.href = "/error";
      }
    })
}

export const getAppleWallet = (obj, countryCode) => (dispatch) => {
  dispatch(paymentRequest());
  getAppleWalletService(obj, countryCode).then(response => {
    if (typeof response.status_code === 'undefined') {
      var pass = _base64ToArrayBuffer(response);
      let blob = new Blob([pass], { type: 'application/vnd.apple.pkpass' });
      fileSaver.saveAs(blob, 'pass.pkpass');
    }
    dispatch({ type: APPLEWALLET_SUCCESS });
  });
}
export const closeModalSpreedly = () => ({ type: CLOSE_MODAL_SPREEDLY });
export const setPaymentType = (paymentType) => ({
  type: SET_PAYMENT_TYPE,
  paymentType,
  metadata: { local: 'save' }
});
export const timeResetCancelLoading = () => ({ type: PAYMENT_REQUEST_ERROR });
export const paymentRequest = () => ({ type: PAYMENT_REQUEST });
export const clearPaymentResponse = () => ({ type: CLEAR_PAYMENT_RESPONSE });
export const setSessionId = (sessionId) => ({ type: SET_SESSIONID_PAYMENTS, sessionId, metadata: { local: 'save' } });
export const setTokenRecaptcha = (token) => ({ type: SET_TOKEN_RECAPTCHA, tr: token });
export const removeSessionId = () => ({ type: REMOVE_SESSIONID_PAYMENTS, metadata: { local: 'save' } });
export const requestReservation = (data, sessionId, tokenR, appDynamics, countryCode) => (dispatch, getState) => {
  if (getState().payments.isLoading) return;

  dispatch({ type: PAYMENT_REQUEST });
  dispatch({ type: CLEAR_PAYMENT_RESPONSE });

  reservationService(data, getState().globals.sessionId, tokenR, countryCode)
    .then((response) => {
      const baseActionResult = { type: PAYMENT_RESPONSE, payload: response.data, sessionId };

      if (response.data.status_code === 0) {
        if (response.data.tickets_confirmation.status === 0)
          dispatch({ ...baseActionResult, metadata: { local: 'clearAll' } });
      } else {
        dispatch(baseActionResult);
        dispatch(showModal({ type: 'error payment', position: 'aside' }));
      }
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status >= 500) {
          reservationServiceCheckTimeOut(0, data, sessionId, tokenR, appDynamics, countryCode)(dispatch, getState);
        }
      }
    });
};

export const requestBankCard = (data, sessionId, fingerprintId, tokenR, appDynamics, countryCode) => (dispatch, getState) => {
  if (getState().payments.isLoading) return;

  dispatch({ type: PAYMENT_REQUEST });
  bankCardService(data, getState().globals.sessionId, fingerprintId, '', tokenR, countryCode)
    .then((response) => {
      const baseActionResult = { type: PAYMENT_RESPONSE, payload: response.data, sessionId };

      if (response.data.status_code === 0) {
        if (response.data.tickets_confirmation.status === 0)
          dispatch({ ...baseActionResult, metadata: { local: 'clearAll' } });
      } else {
        dispatch(baseActionResult);
        dispatch(showModal({ type: 'error payment', position: 'aside' }));
      }
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status >= 500) {
          bankCardCheckTimeOut(0, data, sessionId, fingerprintId, tokenR, appDynamics, countryCode)(dispatch, getState);
        }
      }
    });
};

export const requestSpreedly = (data, sessionId, fingerprintId, paymentMethod, tokenR, firstSixDigits, appDynamics, countryCode, cinemaVistaId, paymentProvider, invoicing_data, cardType, last_four_digits) => (dispatch, getState) => {
  dispatch({ type: PAYMENT_REQUEST });
  const loyalty_card = data.tarjeta_cc ? data.tarjeta_cc.replace(/ /g, '') : '';
  spreedlyService(data, sessionId, fingerprintId, paymentMethod, tokenR, firstSixDigits, appDynamics, loyalty_card, countryCode, paymentProvider, invoicing_data, cardType, last_four_digits).then((response) => {
    const baseActionResult = { type: PAYMENT_RESPONSE, payload: response.data, sessionId, bill_to: data };
    if (response.data.status_code === 0 && response.data.tickets_confirmation.status === 0) {
      dispatch({ ...baseActionResult, metadata: { local: 'clearAll' } });
    }
    else {
      dispatch(baseActionResult);
      dispatch(showModal({ type: 'error payment', position: 'aside', redirectTo: 'boletos' }));
    }
  }

  )
    .catch((error) => {

      let attempts = 9;
      if (countryCode) {
        const checkSpreedlyPurchaseStatus = () => {
          spreedlyPurchaseStatus(data, sessionId, fingerprintId, paymentMethod, tokenR, firstSixDigits, appDynamics, countryCode, paymentProvider, cardType, last_four_digits)
            .then((res) => {
              const baseActionResult = { type: PAYMENT_RESPONSE, payload: res.data, sessionId };
              if (res.data.tickets_confirmation == null && attempts > 0) {
                attempts -= 1;
                setTimeout(() => checkSpreedlyPurchaseStatus(), 10000)
              } else if (res.data.tickets_confirmation !== null) {
                dispatch({ ...baseActionResult, metadata: { local: 'clearAll' } });
              } else {
                dispatch(baseActionResult);
                dispatch(showModal({ type: 'error payment', position: 'aside' }));
              }
            })
        }
        checkSpreedlyPurchaseStatus()
      }

      if (error.response) {
        if (error.response.status >= 500) {
          spreedlyCheckTimeOut(0, data, sessionId, fingerprintId, paymentMethod, tokenR, firstSixDigits, appDynamics, countryCode, paymentProvider, invoicing_data)(dispatch, getState);
        }
      }
    });
};

export const requestCineCashBalance = (accessToken, countryCode) => (dispatch, getState) => {
  if (getState().payments.isLoading) return;

  dispatch({ type: CINECASH_BALANCE_REQUEST });
  cineCashBalance(accessToken, countryCode).then((response) => {
    dispatch({ type: CINECASH_BALANCE_RESPONSE_SUCCESS, payload: response.data, metadata: { local: 'save' } });
  })
};

export const requestCineCashPayment = (data, sessionId, accessToken, cinemaName, movieName, loyaltyCard, tokenR, appDynamics, countryCode) => (dispatch, getState) => {
  if (getState().payments.isLoading) return;
  dispatch({ type: PAYMENT_REQUEST });
  cineCashPaymentService(data, sessionId, accessToken, cinemaName, movieName, loyaltyCard, tokenR, countryCode).then((response) => {
    const baseActionResult = { type: PAYMENT_RESPONSE, payload: response.data, sessionId };

    if (response.data.status_code === 0 && response.data.tickets_confirmation.status === 0) {
      dispatch({ ...baseActionResult, metadata: { local: 'clearAll' } });
    } else {
      dispatch(baseActionResult);
      dispatch(showModal({ type: 'error payment', position: 'aside' }));
    }
  })
    .catch((error) => {
      if (error.response) {
        if (error.response.status >= 500) {
          cineCashCheckTimeOut(0, data, sessionId, accessToken, cinemaName, movieName, loyaltyCard, tokenR, appDynamics, countryCode)(dispatch, getState);
        }
      }
    });
};

export const requestClubCinepolisPayment = (data, sessionId, pin, loyaltyNumber, tokenR, appDynamics, countryCode) => (dispatch, getState) => {

  if (getState().payments.isLoading) return;
  dispatch({ type: PAYMENT_REQUEST });
  clubCinepolisPaymentService(data, sessionId, pin, loyaltyNumber, tokenR, countryCode).then((response) => {
    const baseActionResult = { type: PAYMENT_RESPONSE, payload: response.data, sessionId };

    if (response.data.status_code === 0 && response.data.tickets_confirmation.status === 0)
      dispatch({ ...baseActionResult, metadata: { local: 'clearAll' } });
    else {
      dispatch(baseActionResult);
      dispatch(showModal({ type: 'error payment', position: 'aside' }));
    }
  })
    .catch((error) => {
      if (error.response) {
        if (error.response.status >= 500) {
          clubCinepolisCheckTimeOut(0, data, sessionId, pin, loyaltyNumber, tokenR, appDynamics, countryCode)(dispatch, getState);
        }
      }
    });

};

export const requestPaypalOrder = (sessionId, tickets, amount, cinemaVistaId, bill_to, tokenR, concessions, bookingFee, appDynamics, countryCode) => (dispatch, getState) => {
  if (getState().payments.isLoading) return;
  dispatch({
    type: PAYMENT_REQUEST
  });
  paypalOrderService(sessionId, tickets, amount, cinemaVistaId, bill_to, tokenR, concessions, bookingFee, appDynamics, countryCode).then((response) => {
    dispatch({ type: PAYMENT_RESPONSE, payload: response.data, bill_to, metadata: { local: 'save' } });
  })
};

export const requestKueskiOrder = ( sessionId, tickets, amount, cinemaVistaId, bill_to, tokenR, concessions, bookingFee, appDynamics, countryCode, card_cc ) => async (dispatch, getState) => {

  dispatch({
    type: PAYMENT_REQUEST
  });

  const res = await kueskiServiceOrder(sessionId, tickets, amount, cinemaVistaId, bill_to, tokenR, concessions, bookingFee, appDynamics, countryCode, card_cc );
  
  console.log(res);

  if(!res.checkout_id && res.message === 'Error al validar el captcha'){
    return {
      status: 400,
      msg: res.message
    }
  }

  return res;
  

  //* Error response example

  /*  {
    "checkout_id": null,
    "session_id": null,
    "provider_payment": null,
    "url": null,
    "message": "Estamos resolviendo algunos problemas en el sistema, por favor intenta más tarde.",
    "status_code": -1,
    "error": null,
    "error_description": null
  }  */ 

}

export const kueskiOrderComplete = ( sessionId, checkout_id, countryCode, tokenR, bill_to ) => async (dispatch, getState) => {

  const res = await kueskiServiceOrderComplete(sessionId, checkout_id, countryCode, tokenR);

  return res;

}

export const kueskiCompleteOrderCheckout = (data, bill_to, sessionId ) => (dispatch, getState) => {

  dispatch({ type: PAYMENT_RESPONSE, payload: data, bill_to, sessionId ,metadata: { local: 'save' } });

}

export const requestPaypal = (sessionId, token, payerId, billTo, loyaltyCard, appDynamics, countryCode) => (dispatch, getState) => {
  if (getState().payments.isLoading) return;
  dispatch({ type: PAYMENT_REQUEST });
  const loyalty_card = billTo.tarjeta_cc ? billTo.tarjeta_cc.replace(/ /g, '') : '';
  payPalService(sessionId, token, payerId, billTo, loyaltyCard, appDynamics, loyalty_card, countryCode).then((response) => {
    const baseActionResult = { type: PAYMENT_RESPONSE, payload: response.data, sessionId };

    if (response.data.status_code === 0 && response.data.tickets_confirmation.status === 0) {
      dispatch({ ...baseActionResult, metadata: { local: 'clearAll' } });
    } else {
      dispatch(baseActionResult);
      dispatch(showModal({ type: 'error payment', position: 'aside' }));
      document.location.href = "/boletos";
    }
  })
    .catch((error) => {
      if (error.response) {
        if (error.response.status >= 500) {
          paypalCheckTimeOut(0, sessionId, token, payerId, billTo, loyaltyCard, appDynamics, countryCode)(dispatch, getState);
        }
      }
    });
};

export const requestVisaCheckOutDecrypt = (data, wrappedKey, callId, countryCode) => (dispatch, getState) => {
  if (getState().payments.isLoading) return;
  dispatch({ type: VISA_CHECKOUT_DECRYPT_REQUEST, data, wrappedKey, callId });
  visaCheckOutDecryptService(data, wrappedKey, countryCode).then((response) => {
    if (response.data.status_code === 0)
      dispatch({ type: VISA_CHECKOUT_DECRYPT_RESPONSE, payload: response.data, metadata: { local: 'save' } });
    else {
      dispatch({ type: VISA_CHECKOUT_DECRYPT_RESPONSE, payload: response.data });
      dispatch(showModal({ type: 'error payment', position: 'aside' }));
    }
  })
};

export const requestVisaCheckout = (data, wrappedKey, billTo, callId, card, cinema, fingerPrintId, loyaltyCard, movie, sessionId, appDynamics, countryCode) => (dispatch, getState) => {
  if (getState().payments.isLoading) return;
  dispatch({ type: PAYMENT_REQUEST });
  visaCheckoutService(data, wrappedKey, billTo, callId, card, cinema, fingerPrintId, loyaltyCard, movie, sessionId, countryCode)
    .then((response) => {
      const baseActionResult = { type: PAYMENT_RESPONSE, payload: response.data, sessionId };

      if (response.data.status_code === 0 && response.data.tickets_confirmation.status === 0) {
        dispatch({ ...baseActionResult, metadata: { local: 'clearAll' } });
      } else {
        dispatch(baseActionResult);
        dispatch(showModal({ type: 'error payment', position: 'aside' }));
      }
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status >= 500) {
          visaCheckTimeOut(0, data, wrappedKey, billTo, callId, card, cinema, fingerPrintId, loyaltyCard, movie, sessionId, appDynamics, countryCode)(dispatch, getState);
        }
      }
    });
};

export const requestAnnualPassPayment = (data, sessionId, tokenR, appDynamics, countryCode) => (dispatch, getState) => {
  if (getState().payments.isLoading) return;

  dispatch({ type: PAYMENT_REQUEST });
  annualPassService(data, getState().globals.sessionId, tokenR, countryCode)
    .then((response) => {
      const baseActionResult = { type: PAYMENT_RESPONSE, payload: response.data, sessionId };

      if (response.data.status_code === 0 && response.data.tickets_confirmation.status === 0) {
        dispatch({ ...baseActionResult, metadata: { local: 'clearAll' } });
      } else {
        dispatch(baseActionResult);
        dispatch(showModal({ type: 'error payment', position: 'aside' }));
      }
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status >= 500) {
          annualPassCheckTimeOut(0, data, sessionId, tokenR, appDynamics, countryCode)(dispatch, getState);
        }
      }
    });
};

export const requestPriceZeroPayment = (data, sessionId, tokenR, appDynamics, countryCode) => (dispatch, getState) => {
  if (getState().payments.isLoading) return;

  dispatch({ type: PAYMENT_REQUEST });
  priceZeroService(data, getState().globals.sessionId, tokenR, countryCode)
    .then((response) => {
      const baseActionResult = { type: PAYMENT_RESPONSE, payload: response.data, sessionId };

      if (response.data.status_code === 0 && response.data.tickets_confirmation.status === 0) {
        dispatch({ ...baseActionResult, metadata: { local: 'clearAll' } });
      } else {
        dispatch(baseActionResult);
        dispatch(showModal({ type: 'error payment', position: 'aside', redirectTo: 'boletos' }));
      }
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status >= 500) {
          priceZeroCheckTimeOut(0, data, sessionId, tokenR, appDynamics, countryCode)(dispatch, getState);
        }
      }
    });
};



/*
  Reducer
*/
const initialState = {
  isLoading: false,
  sessionId: null,
  bill_to: null,
  paymentResponse: {},
  cineCashBalance: null,
  visaCheckoutDecryptResponse: null,
  visaCheckOutData: null,
  visaCheckOutCallId: null,
  visaCheckOutWrappedKey: null,
  tr: null,
  type: null,
  kueski_response: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PAYMENT_REQUEST_ERROR:
      return { ...state, isLoading: false };
    case PAYMENT_REQUEST:
      return { ...state, isLoading: true };
    case PAYMENT_RESPONSE:
      return { ...state, isLoading: false, paymentResponse: action.payload, bill_to: action.bill_to };
    case SET_SESSIONID_PAYMENTS:
      return { ...state, sessionId: action.sessionId };
    case REMOVE_SESSIONID_PAYMENTS:
      return { ...state, sessionId: null };
    case CLEAR_PAYMENT_RESPONSE:
      return { ...state, paymentResponse: {}, visaCheckoutDecryptResponse: null, visaCheckOutData: null, visaCheckOutCallId: null, visaCheckOutWrappedKey: null };
    case CINECASH_BALANCE_RESPONSE_SUCCESS:
      return { ...state, isLoading: false, cineCashBalance: action.payload };
    case VISA_CHECKOUT_DECRYPT_RESPONSE:
      return { ...state, isLoading: false, visaCheckoutDecryptResponse: action.payload };
    case VISA_CHECKOUT_DECRYPT_REQUEST:
      return { ...state, isLoading: true, visaCheckOutData: action.data, visaCheckOutWrappedKey: action.wrappedKey, visaCheckOutCallId: action.callId };
    case SET_TOKEN_RECAPTCHA:
      return { ...state, tr: action.tr };
    case SET_PAYMENT_TYPE:
      return { ...state, isLoading: false, type: action.paymentType };
    case CLOSE_MODAL_SPREEDLY:
      return { ...state, isLoading: false };
    case APPLEWALLET_SUCCESS:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};
