import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

class Seat extends Component {

  shouldComponentUpdate (prevProps) {
    if (prevProps.color === this.props.color && prevProps.width === this.props.width &&
      prevProps.status === this.props.status) {
      return false;
    }

    return true;
  }

  handleSelected = (e) => {
    if (typeof this.props.onSelected !== 'function') return;
    this.props.onSelected(this.props.position, this.props.status, this.props.color);
  };
  
  render () {
      if (this.props.letter) {
        return <li className="seat seat--letter" style={{width: this.props.width}}>{this.props.letter}</li>;
      }
      
      if (this.props.empty) {
          
          if (this.props.isMix) {
            return null
          }
          
          return <li className="seat" style={{ width: this.props.width }}></li>;
      }

      return (
        <li className="seat" style={{width: this.props.width, paddingLeft: '1px'}} onClick={this.handleSelected}>
         {
            (this.props.typeSeat == "lounge" || this.props.typeSeat == "duo") ?  
              <svg className="icon-seat" style={(this.props?.typeSection && this.props.typeSection == "pointer") ? { width: "25%" } : {}} viewBox={(this.props?.typeSection && this.props.typeSection == "pointer") ? "0 0 55 55":"0 0 53 50"}>
                <path fill={this.props.color} className="st0" d="M49.3 8.3h-2.2c-1 .1-1.8.9-1.7 1.9v4.1c.1-2.7-2.1-4.9-4.7-5H30.3c-2.6 0-4.7 1.6-4.7 4.3v.1-.1c0-2.7-2.2-4.3-4.8-4.3
                H10.4c-2.7.1-4.8 2.3-4.7 5v-4c.1-1-.7-1.9-1.7-1.9H1.7c-1 0-1.8.9-1.7 1.9V27c-.1 1.3 1 2.4 2.3 2.4h1.5v3.7c-.1 1.4 1.1 2.6 2.5 2.6h18.6c.2 0 .4-.1.6-.1.2.1.4.1.7.1h18.5c1.4-.1 2.6-1.2 2.5-2.6v-3.7H49c1.2-.2 2-1.2 2-2.4V10.2c0-1-.7-1.9-1.7-1.9z"/>
            </svg>:
           
            <svg className="icon-seat" viewBox="0 0 24 20">
                <path fill={this.props.color} className="st0" d="M22.7,0H21c-0.7,0-1.3,0.6-1.3,1.4v0.4v2.6c0-2-1.6-3.7-3.6-3.7H8c-2,0-3.6,1.7-3.6,3.7V1.8V1.4
                  C4.4,0.6,3.8,0,3,0H1.3C0.6,0,0,0.6,0,1.4v0.4v11.3v0.5c0,1,0.8,1.8,1.8,1.8h0.1c0.1,0,0.2,0,0.3,0h0.2l0,0H3v2.7
                  C3,19.1,3.8,20,4.9,20h14.3c1.1,0,1.9-0.9,1.9-1.9v-2.7h0.6l0,0h0.8c0.9-0.1,1.5-0.9,1.5-1.8v-0.5V1.8V1.4C24,0.6,23.4,0,22.7,0z"/>
            </svg>
          }
          <span className="seat__num" aria-label={`${this.props.colLetter}${this.props.id}`} style={(this.props.typeSeat == "lounge") ? { top: "1.3rem" } : (this.props.typeSeat == "duo" ? { top: "50%", transform: "translateY(-100%)"} : {})} >{this.props.id}</span>
          {this.props.children}
        </li>
      );
    
  }
}

Seat.propTypes = {
  width: PropTypes.string,
  id: PropTypes.string,
  letter: PropTypes.string,
  empty: PropTypes.bool,
  color: PropTypes.string,
  position: PropTypes.object,
  status: PropTypes.string,
  onSelected: PropTypes.func,
  typeSeat: PropTypes.string,
  typeSection: PropTypes.string,
  isMix: PropTypes.any
};

export default Seat;
