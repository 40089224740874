import { seatsService } from '../services';
import { setStartTime, requestError } from './globals';
import { reverseObj } from '../helpers';

/*
  Constants
*/
export const REQUEST_SEATS = 'REQUEST_SEATS';
export const REQUEST_SEATS_SUCCESS = 'REQUEST_SEATS_SUCCESS';
export const REQUEST_SEATS_ERROR = 'REQUEST_SEATS_ERROR';
export const REQUEST_SELECTED_SEATS = 'REQUEST_SELECTED_SEATS';
export const CLEAR_SELECTED_SEATS = 'CLEAR_SELECTED_SEATS';
export const REQUEST_SELECTED_SEATS_SUCCESS = 'REQUEST_SELECTED_SEATS_SUCCESS';
export const SELECT_SEAT = 'SELECT_SEAT';
export const DESELECT_SEAT = 'DESELECT_SEAT';
const SHOW_SEAT_MESSAGE = 'SHOW_SEAT_MESSAGE';
const REMOVE_SEAT_MESSAGE = 'REMOVE_SEAT_MESSAGE';
const RESET_SEATS_DATA = 'RESET_SEATS_DATA';
const REQUEST_SEATS_SUCCESS_WITHOUT_SEATS = 'REQUEST_SEATS_SUCCESS_WITHOUT_SEATS';

const SHOW_SEATS_MODAL = "SHOW_SEATS_MODAL";
const HIDE_SEATS_MODAL = "HIDE_SEATS_MODAL";
const SHOW_SEATS_WIZARD_MODAL = "SHOW_SEATS_WIZARD_MODAL";
const HIDE_SEATS_WIZARD_MODAL = "HIDE_SEATS_WIZARD_MODAL";

/*
  Action creators
*/
export const requestSeats = (cinemaVistaId, showtimeVistaId, sessionId, selectedTickets, addTickets, countryCode) => (dispatch, getState) => {
  if (getState().seats.isLoading) return;

  dispatch({ type: REQUEST_SEATS });
  seatsService('seats', {
      cinemaVistaId,
      showtimeVistaId,
      sessionId,
      selectedTickets,
      addTickets,
      countryCode
  })
  .then((data) => {
    if (data.start_date !== null) {
      dispatch(setStartTime(data.start_date));
    }

    if(localStorage.getItem('isFreeSeats')) {
      localStorage.removeItem('isFreeSeats')
    }

    if (data.status_code > 0) {
      if (getState().globals.route === "/asientos") {
        dispatch({ type: REQUEST_SEATS_ERROR });
        dispatch(requestError(data.status_code, data.message, countryCode));
      } else {
        dispatch(resetSeatsData());
      }
    } else if(!data.seat_layout_data) {
      localStorage.setItem('isFreeSeats', '1');
      window.location = '/?cinemaVistaId='+cinemaVistaId+'&showtimeVistaId='+showtimeVistaId+'&countryCode='+countryCode;
    } else if (data.status_code === 0 && data.seat_layout_data === null || data.seat_layout_data.areas.length === 0) {
      dispatch({ type: REQUEST_SEATS_SUCCESS_WITHOUT_SEATS, payload: data, metadata: { local: 'save' } });
    } else {
      dispatch({ type: REQUEST_SEATS_SUCCESS, payload: data, metadata: { local: 'save' } });
    }
  })
};

export const requestSetSelectedSeats = (cinemaVistaId, showtimeVistaId, sessionId, selectedSeats, countryCode) => (dispatch, getState) => {
  if (getState().seats.isLoading) return;

  dispatch({ type: REQUEST_SELECTED_SEATS });
  seatsService('seats/set', {
    session_id: sessionId,
    cinema_vista_id: cinemaVistaId,
    showtime_vista_id: showtimeVistaId,
    area_category_selected: selectedSeats,
    country_code: countryCode
  })
  .then((data) => {
    if (data.status_code > 0) {
      if (data.status_code === 310) {
        dispatch({type: REQUEST_SEATS_ERROR});
        dispatch({type: SHOW_SEATS_MODAL, modalTitle: "¡ATENCIÓN!", modalMessage: data.message});
      }else{
        dispatch(requestError(data.status_code, data.message));
      }
      
    } else
      dispatch({ type: REQUEST_SELECTED_SEATS_SUCCESS, payload: data, metadata: { local: 'save' } });
  })
};
export const selectSeat = (selected) => ({
  type: SELECT_SEAT,
  selected
});
export const deSelectSeat = (selected, status) => ({
  type: DESELECT_SEAT,
  selected,
  status
});

export const clearSelectedSeatsResponse = () => ({ type: CLEAR_SELECTED_SEATS, metadata: { local: 'clear' } });
export const showSeatMessage = () => ({ type: SHOW_SEAT_MESSAGE });
export const removeSeatMessage = () => ({ type: REMOVE_SEAT_MESSAGE });
export const resetSeatsData = () => ({ type: RESET_SEATS_DATA, metadata: { local: 'clear' } });
export const hideModal = () => ({ type: HIDE_SEATS_MODAL });
export const hideWizardModal = () => ({ type: HIDE_SEATS_WIZARD_MODAL });
export const showWizardModal = () => ({ type: SHOW_SEATS_WIZARD_MODAL });

/*
  Reducer
*/
const removeReservedSeat = (layout, selected) => {
  const seatsSelected = selected.reduce((acc, category) => acc.concat(category.selected_seats), []);

  return Object.keys(layout).reduce((acc, letter) => {
    acc[letter] = layout[letter].map(seat => {
      const seatCopy = { ...seat };

      for (let i = 0; i < seatsSelected.length; i += 1) {
        if ((seatCopy.status === 'Reserved')
          && seatsSelected[i].row_index === seatCopy.position.row_index
          && seatsSelected[i].column_index === seatCopy.position.column_index
          && seatsSelected[i].area_number === seatCopy.position.area_number) {
          seatCopy.status = 'Empty'
        }
      }

      return seatCopy;
    });

    return acc;
  }, {});
}
const initialState = {
  isLoading: false,
  response: {},
  layout: [],
  selected: [],
  message: false,
  seatsSelectedResponse: {},
  concessions_data: {},
  hasSeats: true
};
export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_SEATS: {
      return { ...state, isLoading: true };
    }

    case REQUEST_SEATS_SUCCESS: {
      console.time('set seats layout');
      const response = action.payload;
      const { seat_layout_data: { areas, area_categories }, concessions_data } = response
            
      const areasCategoriesFiltered = area_categories.filter(areaCategory => areaCategory.seats_to_allocate >= 1);
      const selectedSeats = areasCategoriesFiltered;       
      
      function reorganizeSeats(seats) {
        // Verificar si los primeros 7 elementos tienen status: "noSeat"
        const initialSevenAreNoSeat = seats.slice(0, 7).every(item => item.status === "noSeat");
        
        if (!initialSevenAreNoSeat) {
          return seats; // Si no cumplen la condición, regresar el array sin cambios
        }

        const resultArray = seats.map((seat) => ({ ...seat, isMix: true }))
    
        return resultArray;
      }
      
      if (selectedSeats.length) {
        selectedSeats.map(area => 
          area.selected_seats.map(selected => {
            selected.area_category_code = area.area_category_code;
            return selected
          })
        );
      }
      

      const maxColumnCount = areas.reduce((acc, row) => {
        if (row.column_count > acc) {
          acc = row.column_count;
        }
        return acc;
      }, 0);

      const layout = areas.reduce((acc, area) => {
        for (let i = 0; i < area.rows.length; i += 1) {
          const row = area.rows[i];

          row.seats.map(seat => {
            seat.position.area_category_code = area.area_category_code;
            seat.description = area.description;
            seat.column_count = area.column_count;
            return seat;
          });

          acc[row.physical_name] = acc[row.physical_name] || [];
          acc[row.physical_name] = [...acc[row.physical_name], ...row.seats];
        }

        return acc;
      }, {});      
      
      for (let seat in layout) {
        for (let j = 0; j < maxColumnCount; j += 1) {
          if (
            typeof layout[seat][j] === 'undefined' || 
            typeof layout[seat][j] !== 'undefined' && j !== +layout[seat][j].position.column_index
          ) {
            layout[seat].splice(j, 0, { position: { column_index: j }, status: 'noSeat' });
          }
          
        }
        
        layout[seat].reverse();
      }

      Object.keys(layout).map((key) => {
        layout[key] = reorganizeSeats(layout[key])
      })
      
      return { ...state, response, layout: reverseObj(layout), selected: selectedSeats, maxColumnCount, isLoading: false, concessions_data, hasSeats: true };
    }

    case REQUEST_SEATS_ERROR: {
      return { ...state, isLoading: false };
    }

    case SELECT_SEAT: {
      let newLayout = { ...state.layout };

      console.time('select seat');
      const newSelected = state.selected.map(areaCategory => {
        const areaCat = { ...areaCategory };
        const seatsToAllocate = areaCat.seats_to_allocate;
        if (areaCat.area_category_code === action.selected.area_category_code) {
          if (areaCat.selected_seats.length === seatsToAllocate) {
            areaCat.selected_seats = areaCat.selected_seats.slice(1).concat(action.selected);
          } else {
            areaCat.selected_seats.push(action.selected);
          }
        }
        
        return areaCat;
      });
      
      newLayout = removeReservedSeat(newLayout, state.selected);
      console.timeEnd('select seat');

      return { ...state, layout: newLayout, selected: newSelected };
    }

    case DESELECT_SEAT: {
      let newLayout = { ...state.layout };

      const newSelected = state.selected.map(areaCategory => {
        const areaCat = { ...areaCategory };
        if (areaCat.area_category_code === action.selected.area_category_code) {
          const seatSelectedIndex = areaCat.selected_seats.findIndex(seat => {
            return seat.area_number === action.selected.area_number &&
              seat.row_index === action.selected.row_index &&
              seat.column_index === action.selected.column_index;
          });

          areaCat.selected_seats = [...areaCat.selected_seats.slice(0, seatSelectedIndex), ...areaCat.selected_seats.slice(seatSelectedIndex + 1)];
        }

        return areaCat;
      });

      if (action.status === 'Reserved') {
        newLayout = removeReservedSeat(newLayout, state.selected);
      }
      return { ...state, layout: newLayout, selected: newSelected };
    }

    case SHOW_SEAT_MESSAGE: {
      return { ...state, message: true };
    }

    case REMOVE_SEAT_MESSAGE: {
      return { ...state, message: false };
    }

    case REQUEST_SELECTED_SEATS:
      return { ...state, isLoading: true };

    case REQUEST_SELECTED_SEATS_SUCCESS:
      return { ...state, seatsSelectedResponse: action.payload, isLoading: false };

    case CLEAR_SELECTED_SEATS:
      return { ...state, seatsSelectedResponse: {}}
    case RESET_SEATS_DATA:
      return initialState;

    case REQUEST_SEATS_SUCCESS_WITHOUT_SEATS:
      console.log("REQUEST_SEATS_SUCCESS_WITHOUT_SEATS")
      return { ...state, isLoading: false, hasSeats: false, response: action.payload, seatsSelectedResponse: action.payload, concessions_data: action.payload.concessions_data };

    case SHOW_SEATS_MODAL:
      return {
        ...state,
        modalIsVisible : true, 
        modalTitle : action.modalTitle,
        modalMessage : action.modalMessage
      }

    case HIDE_SEATS_MODAL:
      return {
        ...state,
        modalIsVisible : false
      }

    case SHOW_SEATS_WIZARD_MODAL:
      return {
        ...state,
        modalWizardIsVisible: true
      }

    case HIDE_SEATS_WIZARD_MODAL:
      return {
        ...state,
        modalWizardIsVisible: false
      }

    default:
      return state;
  }
};
