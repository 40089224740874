import React, { memo } from 'react';
import { PropTypes } from 'prop-types';

import Input from './Input';
import PaymentMethod from './PaymentMethod';


const PaymentCreditCard = (props) => {
  const cardRef = React.createRef();
  const baseValidations = ['notEmpty', 'numeric'];

  const setTypeCardNumber = value => {
    let className = 'form-control--card';

    if (parseInt(value[0], 10) === 4) {
      className += ' form-control--visa';
    } else if (parseInt(value[0], 10) === 5) {
      className += ' form-control--mastercard';
    } else if (parseInt(value[0], 10) === 3) {
      className += ' form-control--amex';
    }
    
    return className;
  };

  const cardValidations = (cardRef) => {
    return () => { 
      if ((cardRef && cardRef.current === null) || (cardRef.current !== null && cardRef.current.state.value === '')) {
        return baseValidations;
      }
      return parseInt(cardRef.current.state.value[0], 10) === 3 ? [...baseValidations, 'minLength:15', 'maxLength:15'] : [...baseValidations, 'minLength:16', 'maxLength:16'];
    };
  };
  const cvvValidations = (cardRef) => {
    return () => {
      if ((cardRef && cardRef.current === null) || (cardRef.current !== null && cardRef.current.state.value === '')) {
        return baseValidations;
      }
      return parseInt(cardRef.current.state.value[0], 10) === 3 ? [...baseValidations, 'minLength:4', 'maxLength:4'] : [...baseValidations, 'minLength:3', 'maxLength:3'];
    };
  };
  
  return (
    <PaymentMethod active={props.active}>
      <Input 
        name="card_number" 
        id="card_number" 
        label="Número de tarjeta" 
        className={setTypeCardNumber}
        onChange={props.onInputChange} 
        validations={cardValidations(cardRef)}
        pattern="**** **** **** ****"
        ref={cardRef}
      />
      <Input 
        name="card_name" 
        id="card_name" 
        label="Nombre del tarjetahabiente" 
        onChange={props.onInputChange} 
        validations={['notEmpty', 'alpha', 'maxLength:40']}
      />
      <div className="row">
        <Input
          name="card_date"
          id="card_date"
          label="Fecha de vencimiento"
          onChange={props.onInputChange}
          validations={['notEmpty', 'minLength:7', 'maxLength:7', 'date']}
          placeholder="MM/AAAA"
          pattern="**/****"
        />
        <Input 
          type="password"
          name="card_cvv" 
          id="card_cvv" 
          label="Código de seguridad" 
          onChange={props.onInputChange} 
          validations={cvvValidations(cardRef)}
        />
      </div>
    </PaymentMethod>
  );
};

PaymentCreditCard.propTypes = {
  active: PropTypes.bool,
  onInputChange: PropTypes.func
};

export default memo(PaymentCreditCard);
