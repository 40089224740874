import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { getCitiesService } from "../store/locations";

class MovieShowtimes extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      cities: [],
      cinemas: [],
      selectedCity: null,
      selectedCinema: null,
      countryCode: this.props.globals.countryCode ? `.${this.props.globals.countryCode.toLowerCase()}` : "",
    };
  }

  componentDidMount() {
    this.props.getCitiesService(this.props.showtime.cinema.vista_id, this.props.globals.countryCode);
  }

  componentDidUpdate() {
    if (
      this.state.selectedCity !== null &&
      this.state.selectedCinema !== null
    ) {

      if(this.state.countryCode === ".cl") {
        
        window.location = `https://cinepolischile.cl/cartelera/${this.state.selectedCity}/${this.state.selectedCinema}`;

      } else {
        
        window.location = `https://cinepolis.co${this.state.countryCode !== ".cr" ? "m" : ""}${this.state.countryCode ? `${this.state.countryCode}` : ''}/cartelera/${this.state.selectedCity}/${
          this.state.selectedCinema
          }`;

      }

    }
  }

  changeCities = event => {
    event.persist();
    let city = this.props.locations.cities.find(x => x.id.toString() === event.target.value);
    this.setState({
      cinemas: city.cinemas,
      selectedCity: event.target[event.target.selectedIndex].getAttribute(
        "data-uri"
      )
    });
  };

  changeCinemas = event => {
    this.setState({
      selectedCinema: event.target[event.target.selectedIndex].getAttribute(
        "data-uri"
      )
    });
  };

  render() {
    const { cities } = this.props.locations;
    return (
      <div className="search">
        <form className="search__form">
          <div className="form-group">
            <select
              className="form-control"
              onChange={this.changeCities}
              value={this.state.value}
              name="ctl00$cmbCiudades"
              id="ctl00$cmbCiudades"
              aria-label="Selecciona una ciudad"
            >
              <option> Selecciona una ciudad </option>
              {cities.map(city => {
                return (
                  <option key={city.id} data-uri={city.uris} value={city.id}>
                    {" "}
                    {city.name}{" "}
                  </option>
                );
              })}
            </select>
            <span className="form-arrow">
              <span />
            </span>
          </div>

          <div className="form-group">
            <select
              className="form-control"
              onChange={this.changeCinemas}
              name="ctl00$cmbComplejos"
              id="ctl00$cmbComplejos"
              aria-label="Selecciona un cine"
            >
              <option> Selecciona un cine </option>
              {this.state.cinemas.map(cinema => {
                return (
                  <option
                    key={cinema.id}
                    data-uri={cinema.uris}
                    value={cinema.id}
                  >
                    {" "}
                    {cinema.name}{" "}
                  </option>
                );
              })}
            </select>
            <span className="form-arrow">
              <span />
            </span>
          </div>
          
          <a
            href={
              this.state.countryCode === '.cl' ? 
              `https://cinepolischile.cl/cartelera/${this.state.selectedCity ? this.state.selectedCity + '/' : ''}${
                this.state.selectedCinema !== null
                  ? this.state.selectedCinema
                  : ""
                }`
              :
              `https://cinepolis.co${this.state.countryCode !== ".cr" ? "m" : ""}${this.state.countryCode ? `${this.state.countryCode}` : ''}/cartelera/${this.state.selectedCity ? this.state.selectedCity + '/' : ''}${
              this.state.selectedCinema !== null
                ? this.state.selectedCinema
                : ""
              }`
            }
            className="btn btn--yellow btnEnviar btnVerCartelera"
          >
            VER CARTELERA
          </a>
        </form>
      </div>
    );
  }
}

const mapStateToProps = ({ locations, showtime, globals }) => {
  return { locations, showtime, globals };
};

export default connect(mapStateToProps, { getCitiesService })(MovieShowtimes);
