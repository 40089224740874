import "./static/css/master.css";

import React from "react";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
//import { whyDidYouUpdate } from 'why-did-you-update';
import dayjs from "dayjs";
import querystring from "query-string";

import configureStore from "./store/configureStore";
import {
  getShowtimeInfo,
  clearError,
  routeChanged,
  clearSessionId,
} from "./store/globals";
import { resetTicketData } from "./store/tickets";
import { clearCard } from "./store/cards";
import localSessionStorage from "./services/localSessionStorage";
import MiddlewareRecaptcha from "./middlewares/MiddlewareRecaptcha";
import App from "./App";
//import registerServiceWorker from './registerServiceWorker';

import "dayjs/locale/es";
dayjs.locale("es");

const tagManagerArgs = {
  gtmId: "GTM-M66T72",
};

const tagManagerArgsCL = {
  gtmId: "GTM-T35B46L",
};
// const tagManagerArgsCR = {
//   gtmId: "GTM-WTXRLC",
// };
// const tagManagerArgsSV = {
//   gtmId: "GTM-5FZM5K",
// };
//  const tagManagerArgsGT = {
//    gtmId: "GTM-5RM8FR",
//  }
//  const tagManagerArgsPA = {
//    gtmId: "GTM-KV7S32",
//  }
// const tagManagerArgsCO = {
//   gtmId: "GTM-WHC22G",
// };
//  const tagManagerArgsPE = {
//    gtmId: "GTM-WW2B7J",
//  }
TagManager.initialize(
  querystring?.parse(window.location.search)?.countryCode === 'CL' ?
  tagManagerArgsCL :
  tagManagerArgs
);

// if (querystring?.parse(window.location.search)?.countryCode === "CL") {
//   TagManager.initialize(tagManagerArgsCL);
// } else if (querystring?.parse(window.location.search)?.countryCode === "CR") {
//   TagManager.initialize(tagManagerArgsCR);
// } else if (querystring?.parse(window.location.search)?.countryCode === "SV") {
//   TagManager.initialize(tagManagerArgsSV);
// } else if (querystring?.parse(window.location.search)?.countryCode === "CO") {
//   TagManager.initialize(tagManagerArgsCO);
// } else if (querystring?.parse(window.location.search)?.countryCode === "PE") {
//   TagManager.initialize(tagManagerArgsPE);
// } else if (querystring?.parse(window.location.search)?.countryCode === "PA") {
//   TagManager.initialize(tagManagerArgsPA);
// } else if (querystring?.parse(window.location.search)?.countryCode === "GT") {
//   TagManager.initialize(tagManagerArgsGT);
// } else {
//   TagManager.initialize(tagManagerArgs);
// }

// if (process.env.NODE_ENV !== 'production') {
//   whyDidYouUpdate(React);
// }

const {
  cinemaVistaId,
  showtimeVistaId,
  countryCode,
  angelAnonymousUserId,
  angelProjectSlug,
  utm_source,
  utm_medium,
  utm_campaign,
} = querystring.parse(window.location.search);

// If pathname is home, set the angelAnonymousUserId and angelProjectSlug in localStorage
if (window.location.pathname === "/") {
  // This is made to avoid undefined values in localStorage when the user comes from an external link
  localStorage.setItem("angelAnonymousUserId", angelAnonymousUserId || "");
  localStorage.setItem("angelProjectSlug", angelProjectSlug || "");
  localStorage.setItem("toErrorCountryCode", countryCode);
  localStorage.setItem("foliosPromocionCL", false);
}

if (sessionStorage.getItem("first-reload") !== "already-reloaded") {
  sessionStorage.setItem("first-reload", "reload");
}

const initialState = localSessionStorage.get("cccw-state");
const store = configureStore(initialState);

if (utm_source && utm_medium && utm_campaign) {
  const tagManagerBotWhatsapp = {
    dataLayer: {
      utm_source,
      utm_medium,
      utm_campaign,
      event: "utm_whatsapp",
    },
  };
  TagManager.dataLayer(tagManagerBotWhatsapp);
}
// Initial data
store.dispatch(routeChanged(window.location.pathname));
// clear errors if any in previous store
if (initialState.globals && initialState.globals.error !== null) {
  store.dispatch(clearError());
}

if (
  initialState.payments &&
  typeof initialState.payments.paymentResponse.status_code !== "undefined"
) {
  localSessionStorage.clear();
}

const render = (store, appWasRendered = false) => {
  ReactDOM.render(
    <MiddlewareRecaptcha
      store={store}
      cinemaVistaId={cinemaVistaId}
      showtimeVistaId={showtimeVistaId}
      countryCode={countryCode}
      appWasRendered={appWasRendered}
    />,
    document.getElementById("root")
  );
};

// Start app
if (
  (Object.keys(initialState).length === 0 &&
    initialState.constructor === Object) ||
  (showtimeVistaId &&
    initialState.globals &&
    initialState.globals.showtimeVistaId !== showtimeVistaId) ||
  (countryCode &&
    initialState.globals &&
    initialState.globals.countryCode !== countryCode)
) {
  store.dispatch(clearSessionId());
  store.dispatch(resetTicketData());
  store.dispatch(clearCard());
  render(store);
} else {
  render(store, true);
}

//registerServiceWorker();
