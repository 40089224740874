import React, { Component, Fragment } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { resetOrder, cancelOrder } from '../store/order';
import { showModal, hideModal, toggleCardsList } from '../store/ui';
import { resetSessionData, routeChanged } from '../store/globals';
import { clearPaymentResponse, timeResetCancelLoading } from '../store/payments';
import { clearCard, benefitInvalidate } from '../store/cards';

import Movie from './Movie';
import AsideModal from './AsideModal';
import Total from './Total';
import Timer from './Timer';
import PromoCardButton from './PromoCardButton';
import CardsList from './CardsList';
import CardAddedList from './CardAddedList';
import CardPinModal from './CardPinModal';

import { CancelPurchaseIcon, CautionIcon, ContinuePurchaseIcon, PurchaseErrorIcon } from './Icons';


class Aside extends Component {

  constructor(props) {
    super(props);
    this.recaptchaRef = React.createRef();

    this.state = {
      folio: []
    };
  }

  shouldComponentUpdate(prevProps) {
    const { sessionId, startDate, isMobile, modal, ticketsConfirmation, countryCode } = this.props;

    if (prevProps.sessionId === sessionId && prevProps.startDate === startDate &&
      prevProps.isMobile === isMobile && prevProps.modal === modal &&
      prevProps.error === this.props.error && prevProps.showCardsList === this.props.showCardsList &&
      prevProps.ticketsConfirmation === ticketsConfirmation &&
      prevProps.blurred === this.props.blurred &&
      prevProps.location.pathname === this.props.location.pathname) {
      return false;
    }

    return true;
  }

  clearCardResponse = () => { this.props.clearCardResponse(this.props.folio); }

  resetTime = () => { 
    this.props.resetTime(this.props.sessionId, this.props.countryCode); 
    this.props.timeResetCancelLoading();
  };

  cancelPurchase = () => { this.props.resetSession(this.props.sessionId, this.props.countryCode); };

  renderCancelBtn() {
    if (this.props.error !== null || this.props.location.pathname === '/error' || this.props.location.pathname === '/pago/paypal/callback') {
      return null;
    }

    if (this.props.ticketsConfirmation && this.props.ticketsConfirmation.status === 0) {
      return null;
    }

    return (
      <button type="button" className={`close${this.props.modal && this.props.isLoadingPyment ? '' : ' close--active'}`} onClick={this.props.modal !== null && this.props.isLoadingPyment ? null : this.props.cancelPurchase} aria-label="Cancelar compra">
        <span className="close__text">Cancelar</span>
        <span className="icon-cross icon-cross--circle close__cross">
          <svg width="11" height="11" viewBox="0 0 11 11">
            <g fill="#D61919" fillRule="nonzero"><path d="M.793 2.207A1 1 0 1 1 2.207.793l8.062 8.062a1 1 0 0 1-1.414 1.414L.793 2.207z" /><path d="M2.207 10.207A1 1 0 0 1 .793 8.793L8.855.73a1 1 0 1 1 1.414 1.414l-8.062 8.062z" /></g>
          </svg>
        </span>
      </button>
    );
  }

  asideModalConfig = () => {
    const { modal } = this.props;
    let modalConfig = null;
    if (modal === null) return modalConfig;

    if (modal.type === 'cancel') {
      modalConfig = {
        icon: <CancelPurchaseIcon />,
        iconWidth: '130px',
        cancel: this.props.continuePurchase,
        accept: this.cancelPurchase,
        acceptText: 'SÍ, CANCELAR COMPRA',
        body: <p className="text text--light">¿Realmente deseas cancelar esta compra?</p>
      };
    } else if (modal.type === 'time limit') {
      modalConfig = {
        icon: <ContinuePurchaseIcon />,
        iconWidth: '130px',
        accept: this.resetTime,
        acceptText: 'CONTINUAR COMPRA',
        body: <p className="text text--light">
          <strong>¿Sigues ahí?</strong> <br />
          El tiempo para realizar tu compra está por agotarse
        </p>
      };
    } else if (modal.type === 'error payment') {
      modalConfig = {
        icon: <PurchaseErrorIcon />,
        iconWidth: '130px',
        cancel: () => {
          this.props.clearPaymentResponse()
          if(modal.redirectTo) { 
            this.props.routeChanged(modal.redirectTo);
            this.props.history.push(modal.redirectTo);
          }
        },
        body: <p className="text text--light">
          {this.props.ticketsConfirmation !== null && this.props.ticketsConfirmation.message}
          {this.props.errorMessage}
        </p>
      };
    } else if (modal.type === 'error iframe') {
      modalConfig = {
        icon: <PurchaseErrorIcon />,
        iconWidth: '130px',
        cancel: this.props.clearPaymentResponse,
        body: <p className="text text--light">
          {modal.message}
        </p>
      };
    } else if (modal.type === 'error card') {
      const message = this.props.cardsResponseMessage !== null ? this.props.cardsResponseMessage : modal?.message
      modalConfig = {
        icon: <PurchaseErrorIcon />,
        iconWidth: '130px',
        cancel: this.clearCardResponse,
        body: <p className="text text--light">
          {
           message
          }
        </p>
      };
    } else if (modal.type === 'error seats') {
      modalConfig = {
        icon: <CautionIcon/>,
        iconWidth: '130px',
        accept: this.clearCardResponse,
        acceptText: 'ACEPTAR',
        body: <p className="text text--light">
          Los asientos de esta área no se pueden seleccionar
        </p>
      };
    }

    return modalConfig;
  };
  
  handleInvalidateCard = () => {
    const tokenR = this.recaptchaRef.current.getValue();
    this.props.handleRemoveBenefit(this.state.folio, tokenR);
    this.recaptchaRef.current.reset();
  };

  handleRecaptcha = (folio) => {
    this.setState({folio: folio})
      this.recaptchaRef.current.execute();
  }

  render() {
    const modal = this.asideModalConfig();

    return (
      <Fragment>
        {
          modal !== null &&
          <AsideModal {...modal}>
            {modal.body}
          </AsideModal>
        }
        <aside className={`main-aside${this.props.blurred && this.props.wSize <= 768 ? ' blur-overlay' : ''}`}>
          <header className="main-aside__head">
            <h3 className="title">Resumen de compra</h3>

            {this.renderCancelBtn()}
          </header>

          {this.props.modal !== null && this.props.modal.type === 'pin' && <CardPinModal />}

          <div className={`main-aside__body ${this.props.modal !== null && this.props.modal.position === 'aside' ? ' blur-overlay' : ''}`}>
            <Movie showtime={this.props.showtime} tickets={this.props.tickets} />

            <Total />
					  {!this.props.showCardsList && <CardAddedList handleInvalidateCard={this.handleRecaptcha} />}
            {this.props.showCardsList && <CardsList toggleCards={this.props.handleToggleCardsList} />}

            {this.props.startDate !== null && !this.props.isMobile ? <Timer /> : null}
          </div>
        </aside>
        <ReCAPTCHA
          ref={this.recaptchaRef}
          size="invisible"
          sitekey="6LcYxXcUAAAAAPd2-4EIfCksacpQd6xVYtQsRP_D"
          onChange={this.handleInvalidateCard}
        />
      </Fragment>
    );
  }
}


const mapStateToProps = ({ ui, globals, payments, cards }) => {
  const { isMobile, showCardsList, modal, size } = ui;
  const { sessionId, timer, error, countryCode } = globals;
  const { tickets_confirmation, status_code, message } = payments.paymentResponse;

  return {
    ticketsConfirmation: typeof tickets_confirmation !== 'undefined' && typeof status_code !== 'undefined' ? tickets_confirmation : null,
    sessionId, error, startDate: timer.startDate,
    isMobile, showCardsList, modal,
    folio: cards.folio,
    countryCode: countryCode,
    cardsResponseMessage: cards.response !== null && cards.response.status_code > 0 ? cards.response.message : null,
    wSize: size,
    errorMessage: typeof message !== 'undefined' ? message : null,
    isLoadingPyment: payments.isLoading,
    cardList: cards.cardList
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    cancelPurchase: () => dispatch(showModal({ type: 'cancel', position: 'aside' })),
    continuePurchase: () => dispatch(hideModal()),
    timeResetCancelLoading: () => dispatch(timeResetCancelLoading()),
    resetTime: (sessionId, countryCode) => dispatch(resetOrder(sessionId, true, countryCode)),
    resetSession: (sessionId, countryCode) => {
      dispatch(cancelOrder(sessionId, false, countryCode, false));
      window.setTimeout(() => {

        if(countryCode === 'CL') {
        
          if((document.location.href).includes('sls-pre'))
            window.location = 'https://preprod.cinepolischile.cl/';
          else
            window.location = 'https://cinepolischile.cl/';
  
        } else {

          window.location = `https://cinepolis.co${countryCode !== "CR" ? "m" : ""}${countryCode ? `.${countryCode}` : ''}`;

        }
        
      }, 3000);
    },
    handleToggleCardsList: () => dispatch(toggleCardsList()),
    clearPaymentResponse: () => {
      dispatch(hideModal());
      dispatch(clearPaymentResponse());
    },
    clearCardResponse: (folio) => {
      dispatch(hideModal());
      if (folio === null) {
        dispatch(clearCard());
      }
    },
    handleRemoveBenefit: (folio, tokenR) => {
      dispatch(benefitInvalidate(folio, false, tokenR));
    }
    ,
    routeChanged
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Aside));
