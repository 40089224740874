import { showtimeService, getBenefitsService, getShowtimeInfoService, getSettingsService, getFooterService, getMastercardBines } from '../services';
import dayjs from 'dayjs';
import { getMovieInfo, getBenefits, getShowtimeInfoSuccess, getSettings, getPaymentSettings, getFooter } from './showtimeInfo';

/*
  Constants
*/
export const GET_INITIAL_PARAMS = 'GET_INITIAL_PARAMS';
export const SET_SESSIONID = 'SET_SESSIONID';
export const CLEAR_SESSIONID = 'CLEAR_SESSIONID';
export const SET_SELECTED_SEATS = 'SET_SELECTED_SEATS';
export const SET_START_DATE = 'SET_START_DATE';
export const RESET_DATE = 'RESET_DATE';
export const RESET_SESSION_DATA = 'RESET_SESSION_DATA';
export const DECREASE_TIME = 'DECREASE_TIME';
const CLEAR_ERROR = 'CLEAR_ERROR';
const CLEAR_STORE = 'CLEAR_STORE';
const REQUEST_ERROR = 'REQUEST_ERROR';
const ROUTE_CHANGED = 'ROUTE_CHANGED';

/*
  Action creators
*/

export const clearStore = () => ({ type: CLEAR_STORE });
export const getInitialParams = (querys) => ({ type: GET_INITIAL_PARAMS, querys, metadata: { local: 'save' } });
export const setStartTime = (startDate) => ({ type: SET_START_DATE, startDate });
export const resetTime = () => ({ type: RESET_DATE });
export const resetSessionData = () => ({ type: RESET_SESSION_DATA, metadata: { local: 'clear' } });
export const setSelectedSeats = (selectedSeats) => ({ type: SET_SELECTED_SEATS, selectedSeats });
export const requestError = (status_code, message, countryCode) => ({ type: REQUEST_ERROR, status_code, message, country_code: countryCode, metadata: { local: 'clear' } });
export const clearError = () => ({ type: CLEAR_ERROR });
export const setSessionId = (sessionId) => ({ type: SET_SESSIONID, sessionId, metadata: { local: 'save' } });
export const decreaseTime = () => ({ type: DECREASE_TIME });
export const routeChanged = (pathname) => ({ type: ROUTE_CHANGED, pathname });

export const clearSessionId = () => ({ type: CLEAR_SESSIONID });
export const getShowtimeInfo = (dispatch, cinemaVistaId, showtimeVistaId, countryCode, tokenR) => {
  return showtimeService(cinemaVistaId, showtimeVistaId, countryCode, tokenR)
    .then((data) => {
      const { movie, cinema, showtimes } = data.data;

      if (movie === null ||  showtimes === null || cinema === null) {
        dispatch(requestError(10010, 'La URL no es correcta, favor de verificarla.', countryCode));
      } else {
        if(countryCode === 'MX' || countryCode == null || countryCode === 'PE' || countryCode === 'CL'){
          getBenefitsService(countryCode).then((response) => {
            dispatch(getBenefits(response.data));
          });
        }

        dispatch(getMovieInfo(movie, cinema, showtimes));
        getShowtimeInfoService(cinema.vista_id, showtimeVistaId, countryCode).then((response) => {
          dispatch(getShowtimeInfoSuccess(response.data));
        });
        getSettingsService(countryCode).then((response) => {
          dispatch(getSettings(response.data));
        });

        getMastercardBines(countryCode).then((response) => {
          dispatch(getPaymentSettings(response.data));
        });
        dispatch(getInitialParams({ cinemaVistaId: cinema.vista_id, showtimeVistaId, countryCode }));
      }

      getFooterService(countryCode).then((response) => {
        dispatch(getFooter(response.data));
      });

      return data;
    })
    .catch((error) => {
      console.log("error showtime: ", error)
      console.log("error showtime: ", error.response)
      console.log("error showtime: ", error.request)
      if(error.response){
        if(error.response.status >= 400){
          console.log("error 504  showtime")
        }
      }
    });
};

/*
  Reducer
*/
const initialState = {
  showtimeVistaId: null,
  cinemaVistaId: null,
  countryCode: null,
  sessionId: null,
  timer: {
    countdown: 0,
    startDate: null,
    limitDate: null
  },
  error: null,
  route: null
};
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_INITIAL_PARAMS:
      const { cinemaVistaId, showtimeVistaId, countryCode } = action.querys;
      return { ...state, showtimeVistaId, cinemaVistaId, countryCode };
    case SET_SESSIONID:
      return { ...state, sessionId: action.sessionId };
    case SET_START_DATE:
      let currentDate = dayjs(action.startDate);
      let limitDate = currentDate.add(5, 'minute');
      return {
        ...state,
        timer: {
          startDate: currentDate,
          limitDate: limitDate,
          countdown: limitDate.diff(currentDate, 'seconds')
        }
      };
    case DECREASE_TIME:
      return { ...state, timer: { ...state.timer, countdown: state.timer.countdown - 1 } };
    case RESET_DATE:
      return { ...state, timer: initialState.timer };
    case RESET_SESSION_DATA:
      return { ...initialState, route: state.route };
    case SET_SELECTED_SEATS:
      return { ...state, selectedSeats: action.selectedSeats };
    case REQUEST_ERROR:
      const { status_code, message, country_code } = action;
      return { ...state, error: { status_code, message, country_code } };
    case CLEAR_ERROR:
      return { ...state, error: null };
    case ROUTE_CHANGED:
      return { ...state, route: action.pathname };
    case CLEAR_SESSIONID:
      return { ...state, sessionId: null };
    default:
      return state;
  }
};


