import React, { memo } from 'react';

const CardLoader = ({ loaded, response, showMessage, hasLoyalty}) => (
  <div className="card__message">
    <div className={`card__loader${loaded ? ` card__loader--loaded` : ''} 
      ${response !== null ? `card__loader--${response}` : ''}
      ${showMessage ? `card__loader--message` : ''}`
    }>
      <div className="card__loader__bg"></div>
      <svg className="card__loader__arc" height="30" width="30" viewBox="0 0 30 30"><circle className="arc" fill="none" strokeWidth="3px" stroke="#0096d5" r="13" cx="15" cy="15" /></svg>
      <svg className="card__loader__check" width="13" height="11" viewBox="0 0 13 11"><path className="check" fill="none" fillRule="nonzero" stroke="#FFF" strokeLinecap="round" strokeWidth="2.051" d="M1.69 7.167l2.367 2.368 7.82-7.82"/></svg>
      <svg className="card__loader__cross" width="11" height="11" viewBox="0 0 11 11">
        <line className="cross-right" fill="none" stroke="#69560e" strokeWidth="2px" strokeLinecap="round" x1="0" y1="0" x2="11" y2="11"></line>
        <line className="cross-left" fill="none" stroke="#69560e" strokeWidth="2px" strokeLinecap="round" x1="11" y1="0" x2="0" y2="11"></line>
      </svg>
      <div className="card__loader__text">
        { response === 'success' ? (hasLoyalty ? 'Tarjeta agregada' : 'Folio agregado') : '' }
        { response === 'error' ? (hasLoyalty ? 'Tarjeta no válida' : 'Folio no válido') : '' }
      </div>
    </div>
  </div>
);

export default memo(CardLoader);
