import React, {useEffect, useRef, useState} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { BrowserRouter } from 'react-router-dom';
import App from '../App';
import { Provider } from "react-redux";

import {
  getShowtimeInfo,
} from "../store/globals";

const MiddlewareRecaptcha = ({store, cinemaVistaId, showtimeVistaId, countryCode, appWasRendered}) => {
  const recaptchaRef = useRef(null)
  const [renderProvider, setRenderProvider] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if(Boolean(recaptchaRef.current) && !appWasRendered) {
      recaptchaRef.current.execute()
    }}, [])

  const getToken = () => {
    const tokenG = recaptchaRef.current.getValue();
    if(tokenG !== ""){
      getShowtimeInfo(store.dispatch, cinemaVistaId, showtimeVistaId, countryCode, tokenG).then(() => setRenderProvider(true))
    }
  }
 
  return (
    <>
      {loading && 
       <div className="loading-app">
        <figure className="loading-app__img">
            <img src="./lg-cinepolis.png" alt="Cinépolis®" />
        </figure>
        <p className="loading-app__text">Cargando...</p>
      </div>}

      {(renderProvider || appWasRendered) && 
      <Provider store={store}>
        <BrowserRouter>
          <App setLoading={setLoading} />
        </BrowserRouter>
      </Provider>}

      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey="6LcYxXcUAAAAAPd2-4EIfCksacpQd6xVYtQsRP_D"
        onChange={getToken}
      />
    </>
  );
}
 
export default MiddlewareRecaptcha;