import React, { memo } from "react";
import PropTypes from "prop-types";
import TagManager from "react-gtm-module";

const Footer = ({ blurred, extraSpace, linksData, countryCode }) => {

  const handleClick = (e, title) => {
    const tagManagerArgs = {
      dataLayer: {
        event: "footer_clic",
        option: title,
        times: new Date().toLocaleTimeString('es-MX', { hour12: false })
      }
    }
    TagManager.dataLayer(tagManagerArgs);
  };

  return (
    <footer
      className={`main-f${blurred ? " blur-overlay" : ""}${
        extraSpace ? " main-f--extraspace" : ""
      }`}
    >
      <div className="main-f__mid">
        <div className="container">
          {linksData !== null &&
            linksData.map((section) => {
              let name = section.name;

              if (name === "EN TU MÓVIL") return null;

              return (
                <ul className="nostyle main-f__col" key={name}>
                  <li className="main-f__col-title">{name.toUpperCase()}</li>
                  {section.section.map((link) => {
                    const external = link.isExternal
                      ? { target: "_blank", rel: "noopener noreferrer" }
                      : "";
                    const titlesClickAnalytics = [
                      "Déjanos tus comentarios",
                      "Ventas Corporativas",
                      "Chatea con los expertos",
                      "01 552 122 60 60",
                    ];
                    return (
                      <li key={link.title}>
                        <a href={link.link} {...external} onClick={titlesClickAnalytics.includes(link.title) ? (e) => handleClick(e, link.title) : null}>
                          {link.title}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              );
            })}
        </div>
      </div>
      <div className="main-f__bottom">
        <div className="container">
        <p>
            {
              countryCode === "GT" ? 'Contenido del sitio 2023© Inversiones Cinematográficas de Guatemala®, S.A.' : 'Contenido del sitio 2023© Derechos reservados Exhibidora Mexicana Cinépolis®, S.A. de C.V.'
            }
          </p>
          <p>
            Desarrollado por{" "}
            <a
              href="https://www.ia.com.mx/"
              target="_blank"
              rel="noopener noreferrer"
            >
              IA Interactive
            </a>
          </p>
        </div>
      </div>
    </footer>
  )
};

Footer.propTypes = {
  blurred: PropTypes.bool,
  extraSpace: PropTypes.bool,
  linksData: PropTypes.array,
};

export default memo(Footer);
