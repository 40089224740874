import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { closeModalSpreedly } from '../store/payments';

class Modal extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      leave: false,
      hide: false
    };
  }

  cancel = () => {
    this.setState({ leave: true });
    window.setTimeout(() => {
      this.setState({ hide: true, leave: false });
      if (this.props.iframeUrl === '')
        this.props.cancel();
      else
        this.props.closeModalSpreedly();
    }, 400);
  };

  render() {
    const { className, icon, iconWidth, title, accept, acceptText, iframeUrl, acceptCancel } = this.props;
    var iframeStyle = {
      width: 500,
      height: 700
    };
    return (
      <div className={`modal ${className} active${this.state.leave ? ' leave' : ''}${this.state.hide ? ' hidden' : ''}${iframeUrl ? ' modal-iframe' : ''}`}>
        <div className="modal__box">
          <header className="modal__head">
            {(this.props.cancel || iframeUrl !== '') &&
              <button type="button" className="modal__close" onClick={this.cancel}>
                { iframeUrl.includes("c2p") ?
                  <span>
                    <b>✖</b>
                  </span>
                :
                  <svg width="24" height="24" viewBox="0 0 24 24">
                    <path fill="#000" fillRule="nonzero" d="M24 17.9c0 3.223-2.662 5.833-5.957 5.833H5.966C2.67 23.733 0 21.123 0 17.9V6.1C0 2.877 2.67.267 5.966.267h12.077C21.338.267 24 2.877 24 6.1v11.8zm-1.014 0V6.1c0-2.687-2.196-4.833-4.943-4.833H5.966c-2.748 0-4.943 2.146-4.943 4.833v11.8c0 2.687 2.195 4.833 4.943 4.833h12.077c2.747 0 4.943-2.146 4.943-4.833zm-7.432-7.75a.534.534 0 0 1-.361.483.554.554 0 0 1-.594-.15l-2.054-2.175v8.734a.532.532 0 0 1-.295.499.558.558 0 0 1-.545-.026.53.53 0 0 1-.25-.473V8.308l-2.046 2.175a.555.555 0 0 1-.52.163.542.542 0 0 1-.405-.358.524.524 0 0 1 .115-.521l3-3.192a.551.551 0 0 1 .81 0l3 3.192c.099.104.15.241.145.383z" opacity=".5" />
                  </svg> 
                }
                <div className='modal__tooltip'>
                  Cerrar
                  <div className='modal__tooltip--arrow'></div>
                </div>
              </button>
            }
            {icon &&
              <figure className="modal__icon" style={{ width: iconWidth }}>
                {typeof icon === 'string' && <img src={icon} alt="" />}
                {typeof icon === 'object' && icon}
              </figure>
            }
            {title && <h4 className="modal__title">{title}</h4>}
          </header>

          <div className="moda__body">{this.props.children}</div>

          {typeof accept === 'function' && acceptText
            && <button className="btn btn--yellow" onClick={accept}>{acceptText}</button>
          }
          {acceptCancel && acceptText
            && <button className="btn btn--yellow" onClick={this.cancel}>{acceptText}</button>
          }
          {
            iframeUrl && <iframe id="iframe-c2p" src={iframeUrl} style={iframeStyle} />
        }
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  cancel: PropTypes.func,
  iconWidth: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  accept: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  acceptText: PropTypes.string,
  iframeUrl: PropTypes.string,
  acceptCancel: PropTypes.bool,
}; 

Modal.defaultProps = {
  iframeUrl: ''
}

export default connect(null, { closeModalSpreedly})(Modal);
