import React, { Component, Fragment } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';

import { resizing, showModal, hideModal } from './store/ui';
import { routeChanged } from './store/globals';

import RouteOrError from './components/RouteOrError';

import Header from './components/Header';
import StepsList from './components/StepsList';
import Aside from './components/Aside';

import Login from './pages/Login';
import Tickets from './pages/Tickets';
import Seats from './pages/Seats';
import Payments from './pages/Payments';
import Error from './pages/Error';
import PaymentSuccess from './pages/PaymentSuccess';
import PaymentPayPalCallback from './pages/PaymentPayPalCallback';
import Footer from './components/Footer';
import MobileTopBar from './components/MobileTopBar';
import Modal from './components/Modal';
import LocalRedirect from './components/LocalRedirect';
import localSessionStorage from './services/localSessionStorage';
import PaymentSuccessBillingForm from './components/PaymentSuccessBillingForm';
import { CautionIcon } from './components/Icons';

// import Testing from './pages/Testing';

class App extends Component {

  constructor(props) {
    super(props);
    this.debouncedResizing = debounce(this.props.resize, 300);
    this.state = {
      displayForm: false
    }
  }

  componentDidMount() {
    this.props.setLoading(false)
    window.addEventListener('resize', this.debouncedResizing, false);
    window.addEventListener('popstate', this.props.handleRouteChanged, false);

    if (this.props.activeModal) {
      this.props.showModal({ type: this.props.activeModal.section, position: 'main' });
    }
    if (this.props.activeModalPayment || this.props.activeModalPaymentC2P)
      this.props.showModal({ type: 'payment', position: 'main' });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activeModal !== this.props.activeModal && this.props.activeModal) {
      this.props.showModal({ type: this.props.activeModal.section, position: 'main' });
    }

    if ((prevProps.activeModalPayment !== this.props.activeModalPayment && this.props.activeModalPayment) || 
    (prevProps.activeModalPaymentC2P !== this.props.activeModalPaymentC2P && this.props.activeModalPaymentC2P))
      this.props.showModal({ type: 'payment', position: 'main' });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedResizing, false);
    window.removeEventListener('popstate', this.props.handleRouteChanged, false);
  }

  handleDisplayForm = (value) => {
    this.setState({displayForm: value})
  };

  guardPaymentsRoute = () => {
    if (this.props.seatsSessionId === null || this.props.selectedTickets.length <= 0) {
      return true;
    }

    return false;
  };

  render() {
    const { modal, overlay, isMobile, activeModal, activeModalPayment, activeModalPaymentC2P, iframeUrl, iframeUrlC2P, invoice_status } = this.props;
    const { pathname } = this.props.location;
    const blurred = !isMobile && modal !== null && modal.position === 'main' && (activeModalPayment || activeModalPaymentC2P);
    const simpleView = !(isMobile && (pathname === '/' || pathname === '/error' || pathname === '/confirmacion'));

    return (
      <Fragment>
        <Header blurred={blurred} countryCode={this.props.countryCode}/>
        <div className="page-wrap">
          <Aside blurred={blurred} />

          <section className="main-wrap" role="main">
            <div className={blurred ? ' blur-overlay' : ''}>
              <MobileTopBar />
              <StepsList />
            </div>

            {this.state.displayForm && <PaymentSuccessBillingForm handleDisplayForm={this.handleDisplayForm} countryCode={this.props.countryCode}/>}

            <section className="main">
              <div className={blurred ? ' blur-overlay' : ''}>
                <Switch>
                  <RouteOrError path="/" exact component={Login} />
                  <RouteOrError path="/boletos" component={Tickets} />
                  <RouteOrError path="/asientos" component={Seats} />
                  {/* <RouteOrError path="/testing" component={Testing} /> */}
                  <RouteOrError path="/pago" exact component={Payments} guard={this.guardPaymentsRoute()} />
                  <Route path="/error" component={Error} />
                  <Route path="/confirmacion" render={() => {
                    return <PaymentSuccess handleDisplayForm={this.handleDisplayForm}/>
                  }}/>
                  <Route path="/pago/paypal/callback" component={PaymentPayPalCallback} />
                  <Route render={() => {
                    localSessionStorage.clear();
                    return <LocalRedirect to="/error" />;
                  }} />
                </Switch>
              </div>

              {
                modal !== null && modal.position === 'main' && activeModal &&
                <Modal
                  className="modal--main"
                  accept={this.props.hideModal}
                  acceptText="OK, ENTENDIDO"
                  title={activeModal.title}
                  icon={<CautionIcon />}
                  iconWidth="21px"
                >
                  {
                    localStorage.getItem('isFreeSeats') === '1' ?
                    <p className="text">El ticket que has seleccionado no está disponible en este momento. Por favor, intenta con uno diferente.</p> :
                    <p className="text">{activeModal.message}</p>
                  }
                </Modal>
              }
              {
                modal !== null && modal.position === 'main' && (activeModalPayment || activeModalPaymentC2P) &&
                <Modal
                  className="modal--main"
                  accept={this.props.hideModal}
                  iframeUrl={activeModalPayment ? iframeUrl : iframeUrlC2P}
                >
                </Modal>
              }
              {
                invoice_status === -1 &&
                <Modal 
                  className="modal--aside"
                  cancel={this.props.hideModal}
                  acceptText="OK, ENTENDIDO"
                  title="¡Lo sentimos!"
                  icon={<CautionIcon />}
                  iconWidth="21px"
                  acceptCancel={true}
                >
                  <p className="text text--small">
                    No pudimos generar<br />tu factura. Por favor, comunícate a<br />facturacion_co@cinepolis.com<br />para darte atención.
                  </p>
                </Modal>
              }
            </section>
          </section>
        </div>
        <Footer blurred={blurred} extraSpace={simpleView} linksData={this.props.footer} countryCode={this.props.countryCode}/>
        <div className={`overlay ${overlay ? 'active' : ''}`}></div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ showtime, ui, globals, tickets, payments }) => {
  const { modal, overlay, isMobile } = ui;
  const { showtimeInfo } = showtime;
  return {
    modal, overlay, isMobile,
    activeModal: showtimeInfo && showtimeInfo.alerts ? showtimeInfo.alerts.find(alert => {
      let route = globals.route.replace(/(compra|\/)/g, '');
      if (route === 'boletos') { route = 'tickets'; }
      if (route === 'asientos') { route = 'seats'; }
      return alert.section === route;
    }) : null,
    activeModalPayment: payments.type === 'spreedly_tickets' && payments.isLoading && globals.route === '/pago',
    activeModalPaymentC2P: payments.type === 'c2p_tickets' && payments.isLoading && globals.route === '/pago',
    seatsSessionId: globals.sessionId,
    selectedTickets: tickets.selectedTickets,
    countryCode: globals.countryCode,
    footer: showtime.footer,
    iframeUrl: showtime.settings !== null && typeof showtime.settings.others.spreedly_iframe !== 'undefined' ? showtime.settings.others.spreedly_iframe : null,
    iframeUrlC2P: showtime.settings !== null && typeof showtime.settings.others.spreedly_c2p_iframe !== 'undefined' ? showtime.settings.others.spreedly_c2p_iframe : null,
    invoice_status: payments.paymentResponse.invoice_confirmation,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    resize: () => {
      if (window.innerWidth < 1024) {
        dispatch(resizing(true, window.innerWidth));
      } else {
        dispatch(resizing(false, window.innerWidth));
      }
    },
    handleRouteChanged: () => dispatch(routeChanged(ownProps.history.location.pathname)),
    showModal: (obj) => dispatch(showModal(obj)),
    hideModal: () => dispatch(hideModal())
  }
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(App));
