import React, { Component } from 'react';


const preventBack = (WrappedComponent) => {
  return class extends Component {
    componentDidMount () {
      if (this.props.history) {
        this.props.history.block();
      }
    }

    componentWillUnmount () {
      this.props.history.go();
    }

    render () {
      return <WrappedComponent {...this.props} />;
    }
  };
}

export default preventBack;
