import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import querystring from "query-string";
import { requestPaypal } from "../store/payments";
import { resetTime } from "../store/globals";
import PaymentSharedFields from "../components/PaymentSharedFields";
import Loader from "../components/Loader";
import LocalRedirect from "../components/LocalRedirect";

const { token, session_id, PayerID } = querystring.parse(
  window.location.search
);

class PaymentPayPalCallback extends Component {
  constructor(props) {
    super(props);
    let initialBillTo = {
      email: "",
      first_name: "",
      last_name: "",
      phone: "",
      loyalty: "",
      tarjeta_cc: "",
    };

    if (this.props.payments.bill_to !== null) {
      const { email, first_name, last_name, phone, loyalty, tarjeta_cc } =
        this.props.payments.bill_to;

      initialBillTo = {
        email,
        first_name,
        last_name,
        phone,
        loyalty,
        tarjeta_cc,
      };
    }

    this.state = {
      bill_to: initialBillTo,
      valid: true,
    };
  }

  componentDidMount() {
    const appDynamics = {
      cinema_type: this.props.movieLanguage,
      screen: this.props.movieScreen,
    };
    this.props.resetTime();
    this.props.requestPaypal(
      session_id,
      token,
      PayerID,
      this.state.bill_to,
      this.state.bill_to.loyalty,
      appDynamics,
      this.props.countryCode
    );
  }

  render() {
    const { isLoading, paymentResponse } = this.props.payments;
    if (
      typeof paymentResponse.tickets_confirmation !== "undefined" &&
      paymentResponse.tickets_confirmation !== null &&
      paymentResponse.tickets_confirmation.status === 0
    ) {
      return (
        <LocalRedirect
          to={{ pathname: "/confirmacion", state: { activeSonicBrand: false } }}
        />
      );
    }

    return (
      <Fragment>
        <div className="container container--mid-large payments">
          {isLoading && <Loader />}

          <div className={isLoading ? "blur-overlay" : ""}>
            <PaymentSharedFields
              bill_to={this.state.bill_to}
              onInputChange={this.handleInputChange}
              onInputBlur={this.handleInputBlur}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = ({ payments, showtime, globals }) => {
  return {
    payments,
    countryCode: globals.countryCode,
    movieLanguage: showtime.movie.format,
    movieScreen: showtime.showtimes.screen,
  };
};

export default connect(mapStateToProps, { requestPaypal, resetTime })(
  PaymentPayPalCallback
);
