import React, { Component, Fragment } from "react";
import PaymentCreditCard from "./PaymentCreditCard";
import PaymentCinecash from "./PaymentCinecash";
import PaymentCC from "./PaymentCC";
import Input from "./Input";
import PriceZero from "./PriceZero";
import TagManager from "react-gtm-module";

class PaymentMethods extends Component {
  constructor(props) {
    super(props);
    this.countryCode = undefined;
    this.paymentTypes = [
      {
        cssName: "card",
        img: "tarjeta-cd.svg",
        title: "Tarjeta crédito / débito",
        code: "spreedly_tickets",
      },
      {
        cssName: "c2p",
        img: "c2p-logo.png",
        title: "Click to Pay",
        code: "c2p_tickets",
      },
      {
        cssName: "reserve",
        img: "ticket-reserva.svg",
        title: "Reservación",
        code: "unpaid_booking",
      },
      {
        cssName: "cinecash",
        img: "t-cinecash.png",
        title: "Cinecash®",
        code: "cinecash",
      },
      {
        cssName: "cc",
        img: "tarjeta-cc.svg",
        title: "Club Cinépolis®",
        code: "loyalty_tickets",
      },
      {
        cssName: "paypal",
        img: "paypal.svg",
        title: "PayPal",
        code: "paypal_tickets",
      },
      {
        cssName: "visa",
        img: "visa-checkout.svg",
        title: "Visa Checkout",
        code: "visa_checkout",
      },
      {
        cssName: "kueski",
        img: "ico_kueski.png",
        title: "Kueski Pay",
        code: "kueski_tickets"
      },
    ];
  }

  showDisabledCCModal = () => {
    this.props.showModal({ type: 'error card', message: 'Para usar tus beneficios Club Cinépolis, por favor, inicia sesión en la app y completa tu transacción ahí.' })
  }

  sendPaymentMethodDataLayer(title) {
    const tagManagerArgs = {
      dataLayer: {
        event: "select_payment_form",
        channel: {
          module: "payment",
          component: "payment_forms",
          element: title,
          action: "select_payment"
        }
      }
    }

    TagManager.dataLayer(tagManagerArgs)
  }

  handleActivePayment = (e, title, isCC = false) => {
    if(this.props.isMX) this.sendPaymentMethodDataLayer(title)
    
    if (isCC) {
      this.showDisabledCCModal();
      return;
    }

    const clickedElem = e.currentTarget;

    if (
      e.target.tagName === "BUTTON" &&
      e.target.className === "btn btn--clean"
    )
      return;

    this.props.handleActiveForm(clickedElem.dataset.code);
  };

  render() {
    const {
      modal,
      isMobile,
      active,
      paymentMethodsSettings,
      isPriceZero,
      isAnnualPass,
      accept_terms,
      hasFolio,
      countryCode,
      availablePayments,
    } = this.props;
    let containerClassName = "";

    if (!this.props.mobilePaymentMethodsOpen) {
      containerClassName = " select";
    } else {
      containerClassName = " selected";
    }

    return (
      <section className="payment__methods">
        {isPriceZero && <PriceZero />}

        {!isPriceZero && (
          <Fragment>
            <h1 className="title">Selecciona el método de pago</h1>

            <ul
              className={`nostyle payment__types-list${containerClassName} ${this.paymentTypes.length >= 8 ? 'list-big' : ''}`}
              data-selectedmobiletext="Método de pago seleccionado"
            >
              {this.paymentTypes.map((type) => {
                const isCC = type.code === "loyalty_tickets";
                if (
                  this.props.disabled !== null &&
                  this.props.disabled.includes(type.code)
                ) {
                  return null;
                }

                if (hasFolio && type.code === "unpaid_booking") return null;
                if (!paymentMethodsSettings.includes(type.code)) return null;
                if (availablePayments && !availablePayments.has(type.code)) return null;

                if(!this?.props?.paymentMethodsSettings?.find( paymentType => paymentType === "kueski_tickets" ) && type.code === 'kueski_tickets') return null;

                return (
                  <li
                    className={`payment__types-item payment__types-item--${
                      type.cssName
                    }${active === type.code ? " active" : ""}`}
                    key={type.title}
                    data-code={type.code}
                    onClick={(event) => this.handleActivePayment(event, type.title, isCC)}
                    style={{
                     display: 
                     (countryCode === undefined) 
                      ? 'block'
                      : (countryCode !== "MX" 
                        && (type.cssName === "paypal" ||
                            type.cssName === "cc" ||
                            type.cssName === "reserve" ||
                            type.cssName === "cinecash" ||
                            type.cssName === "visa") ? "none" : "block"
                        )
                    }}
                  >
                    <figure className="payment__type-img">
                      <img
                        src={countryCode === 'CL' && type.code === 'c2p_tickets' ? require('../static/images/icons/c2p-visa-mc.png') : require(`../static/images/icons/${type.img}`)}
                        alt={`Cinépolis Chile - ${type.title}`}
                      />
                    </figure>
                    <span className="payment__type-text">{type.title}</span>

                    {isMobile && (
                      <button
                        type="button"
                        className="btn btn--clean"
                        onClick={this.props.onChangeMobileMethods}
                      >
                        CAMBIAR
                      </button>
                    )}
                  </li>
                );
              })}
            </ul>

            <div className="payment__types">
              <PaymentCreditCard
                active={
                  active === "bank_card" &&
                  (this.props.mobilePaymentMethodsOpen ||
                    this.props.mobilePaymentMethodsOpen === null)
                }
                onInputChange={this.props.onInputChange}
              />
              <PaymentCinecash
                active={
                  active === "cinecash" &&
                  (this.props.mobilePaymentMethodsOpen ||
                    this.props.mobilePaymentMethodsOpen === null)
                }
              />
              <PaymentCC
                active={
                  active === "loyalty_tickets" &&
                  (this.props.mobilePaymentMethodsOpen ||
                    this.props.mobilePaymentMethodsOpen === null)
                }
                onInputChange={this.props.onInputChange}
                modal={modal}
              />
            </div>
          </Fragment>
        )}

        <div className="payment__terms">
          <Input
            type="checkbox"
            name="accept_terms"
            id="accept_terms"
            defaultChecked={this.props.accept_terms}
            onChange={this.props.onInputChange}
          >
            He leído y estoy de acuerdo con los{" "}
            <a
              href={this.props.terms}
              className="link"
              target="_blank"
              rel="noopener noreferrer"
            >
              Términos y Condiciones
            </a>{" "}
            y &nbsp;
            <a
              href={this.props.privacy_policy}
              className="link"
              target="_blank"
              rel="noopener noreferrer"
            >
              Aviso de Privacidad
            </a>
            .
          </Input>
        </div>
      </section>
    );
  }
}

export default PaymentMethods;
