
export default {
  get: (key) => {
    try {
      let data = JSON.parse(sessionStorage.getItem(key));
      if (data === null) {
        data = {};
        
        // Return empty string for these keys
        if(key === 'angelAnonymousUserId' || key === 'angelProjectSlug') {
          data = ''
        }
      }

      return data;
    } catch (err) {
      console.log('An error occurred with sessionStorage: ', err);
    }
  },

  set: (key, data) => {
    sessionStorage.setItem(key, JSON.stringify(data));
  },

  clear: (key) => {
    if (key) {
      sessionStorage.removeItem(key);
    } else {
      sessionStorage.clear();
    }
  }
};
