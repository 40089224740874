import React, { Component, Fragment } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getTicketTypes, reloadTickets, resetSelectedTickets, reloadCurrentTickets } from '../store/tickets';
import { isValidCard } from '../store/cards';
import { toggleCardsList, toggleCardsListClose, showCards } from '../store/ui';
import { cancelOrder } from "../store/order";
import TicketCategory from '../components/TicketCategory';
import Loader from '../components/Loader';
import Return from '../components/Return';
import PromoCardButton from '../components/PromoCardButton';
import TagManager from 'react-gtm-module';
import { maxQuantitySelector } from '../selectors';
import { formatPriceTwoDecimals } from '../helpers';
import { selectedSeatsHumanSelector } from '../selectors/index';
class Tickets extends Component {
  constructor(props) {
    super(props);
    this.recaptchaRef = React.createRef();
    this.state = {
      tokenG: null
    };
  }
  componentDidMount() {
    this.handleRecaptcha();
    this.props.resetSelectedTickets();
    this.props.handleToggleCardsListClose()
    console.log(this.props.movieName, this.props.movieName.toLowerCase().includes("deadpool y wolverine"), this.props.countryCode)
  
    let ticketsTagManagerPageView;
    if(this.props.isMX) {
      ticketsTagManagerPageView = {
        dataLayer: {
          event: 'page_view',
          channel: {
            type: 'web',
            subtype: 'legacy',
            language: 'ES',
            country: 'MX'
          }
        }
      }
    } else {
      ticketsTagManagerPageView = {
        dataLayer: {
          item_name: this.props.showtime.movie.name,
          event: 'page_view',
          path: '/visSelectTickets',
          times: new Date().toLocaleTimeString('es-MX', { hour12: false })
        }
      }
    }
    const tagManagerArgs = {
      dataLayer: {
        item_name: this.props.showtime.movie.name,
        times: new Date().toLocaleTimeString('es-MX', { hour12: false }),
        event: 'boletos',
        items: [{
          step: 1,
          action: "checkout"
        }]
      }
    }
    if(this.props.countryCode === 'CL') {
      tagManagerArgs.dataLayer.items[0].item_name = this.props.showtime.movie.name;
      delete tagManagerArgs.dataLayer.item_name;
    }
    TagManager.dataLayer(tagManagerArgs);
    TagManager.dataLayer(ticketsTagManagerPageView);
    
    if(localStorage.getItem('tickets')) {
      localStorage.removeItem('tickets');
    }
  }


  shouldComponentUpdate(prevProps) {
    if (!this.props.isMobileOrDesktop && prevProps.cardsResponse !== null && !prevProps.isLoadingCards && prevProps.continueCloseModalFolios && prevProps.cardsResponse.status_code === 0) {
      this.props.handleToggleCardsListClose();
    }
    if (Object.keys(this.props.tickets).length === 0 && !prevProps.isLoadingTickets &&
      prevProps.tickets && prevProps.tickets.session_id === this.props.tickets.session_id) {
      return false;
    }
    return true;
  }
  isJunior() {
    const { showtime, tickets } = this.props;
    if(tickets.areas && tickets.areas.length > 1){
      return tickets.areas.some(cat => cat.name.toLowerCase().indexOf('junior') >= 0);
    }else if(showtime.movie.format === "SJ ESP" ){
      return true;
    }else{
      return false;
    }
  }
  renderTicketTypes() {
    const { isLoadingTickets, tickets, hasDuoSeatModifier } = this.props;
    if (isLoadingTickets) return <Loader text="boletos" />;
    if (!tickets.areas) return null;
    let ticketsAreas = []
    if(hasDuoSeatModifier) {
      const butacaDuo = tickets.areas.find(area => area.name.toLowerCase().includes("butaca duo"))
      if(butacaDuo) ticketsAreas[0] = butacaDuo
      ticketsAreas.push(...tickets.areas.filter(area => !area.name.toLowerCase().includes("butaca duo")))
    } else ticketsAreas = tickets.areas
    return ticketsAreas.map((area) => {
      return <TicketCategory
        key={area.code}
        area={area.code}
        name={area.name}
        types={area.tickets}
        selected={this.props.selectedTicketsTypes || 0}
        quantity={this.props.quantity}
        isJunior={this.isJunior()}
        hasDuoSeatModifier={this.props.hasDuoSeatModifier}
        maxQuantity={this.props.maxQuantity}
        currencyCharacter={this.props.symbol}
        countryCode={this.props.countryCode}
      />
    });
  }
  pushData = () => {
    const { showtime, selectedTickets, tickets, cityName, folio, countryCode, totalAmount } = this.props;
    let products = [];
    let productsToPayments = [];
    let i = 1;
    for (let item of selectedTickets) {
      let category = '';
      let price = '';
      let ticketFacility = '';
      for (let areaItem of tickets.areas) {
        let area = areaItem.tickets.find(c => c.code === item.ticket_type_code);
        if (typeof area !== 'undefined') {
          category = typeof area.description !== 'undefined' ? area.description : typeof area.name !== 'undefined' ? area.name : '';
          price = area.price;
          ticketFacility= areaItem.name;
        }
      }
      const product = {
        item_id: ''+showtime.movie.id,//i.toString() + tickets.session_id + showtime.showtimes.datetime.split('T')[1].replace(/:/g, ""),
        item_name: showtime.movie.original_name,
        item_brand: showtime.movie.distributor === '' ? 'NA' : (showtime.movie.distributor ?? 'NA'),
        item_category: "boleto",
        item_category2: category,
        pass_name: this.props.folio?.name || '',
        price: countryCode === 'CL' || countryCode === 'PE' || countryCode === 'CO' || countryCode === 'SV' || countryCode === 'PA' || countryCode === 'GT' || countryCode === 'CR' ? parseFloat(formatPriceTwoDecimals(price)) : price,
        quantity: item.quantity,
        item_languaje: showtime.movie.format,
        facility: ticketFacility,
      };
      
      if(countryCode === 'CL') {
        delete product.pass_name;
        product.item_variant = showtime.cinema.name;
        const producToPayment = {
          ...product,
          affiliation: 'Canal Web',
        }
        
        productsToPayments.push(producToPayment);
      }
      products.push(product);
      i++;
    }
    if(!this.props.isMX) {
      const addToCartTagManagerArgs = {
        dataLayer: {
          event: 'add_to_cart',
          ecommerce: {
            city_name: cityName,
            movie_date: showtime.showtimes.datetime.split('T')[0].replaceAll("-", " "),
            movie_time: showtime.showtimes.datetime.split('T')[1],
            times: new Date().toLocaleTimeString('es-MX', { hour12: false }),
            language: showtime.movie.language,
            item_variant: showtime.cinema.name,
            currency: this.props.currencyCode,
            items: [...products]                                                                                            
          }
        }
      };
      localStorage.setItem('tickets', JSON.stringify(products));
      if(countryCode === 'CL') {
        delete addToCartTagManagerArgs.dataLayer.ecommerce.item_variant;
        addToCartTagManagerArgs.dataLayer.ecommerce.coupon = this.props.folio?.name || 'NA';
        const value = +formatPriceTwoDecimals(totalAmount);
        addToCartTagManagerArgs.dataLayer.ecommerce.value = !isNaN(value) ? parseFloat(value) : 'NA' ;
        localStorage.setItem('tickets', JSON.stringify(productsToPayments));
      }
      TagManager.dataLayer(addToCartTagManagerArgs);
    } else {
      const ticketDetails = {} 
      for(const { tickets: ticketsAreas } of tickets.areas) {
        ticketsAreas.forEach(ticket => {
          ticketDetails[ticket.description] = {
            price: +formatPriceTwoDecimals(ticket.price),
            quantity: selectedTickets.find(selectedTicket => selectedTicket.hopk === ticket.hopk)?.quantity || 0 
          }
        })
      }
      const checkoutTagManagerArgs = {
        dataLayer: {
          event: "begin_checkout",
          showtime: {
            date: this.props.movieDate,
            id: this.props.sessionId,
            complex: this.props.cinemaVistaId,
            facility: this.props.movieFacility,
            language: this.props.movieLanguage,
            city: this.props.cityName,
            country: "MX"
          },
          movie: {
            id: this.props.movieId,
            name: this.props.movieName,
            rating: this.props.movieRating,
            genre: this.props.movieGenre.split(",").map(gen => gen.trim()),
            distributor: this.props.movieDistributor,
            length: +this.props.movieDuration.replaceAll(/\D/g, "")
          },
          channel: {
            type: "web",
            subtype: "legacy",
            language: "ES",
            country: "MX",
            module: "ticket",
            component: "ticket_selector",
            element: "continue_button",
            action: "add_tickets",
          },
          transaction: {
            total_tickets: selectedTickets.reduce((acc, ticket) => acc + ticket.quantity, 0),
            ticket_details: ticketDetails,
            total_amount: +formatPriceTwoDecimals(this.props.totalAmount),
            currency: "MXN"
          },
          items: products.map(product => ({
            item_id: product.item_id,
            item_name: product.item_name,
            item_category: "ticket",
            item_category2: product.item_category2,
            quantity: product.quantity
          }))
        }
      }
      TagManager.dataLayer(checkoutTagManagerArgs);
    }
  }
  renderButton() {
    if (this.props.isMobileOrDesktop
    ) {
      if (this.props.quantity === 0) {
        return (
          <button type="button" className="btn btn--primary" disabled style={{borderRadius: '30px'}}>
            CONTINUAR
          </button>
        );
      } else {
        return (
          <Link
            to="/asientos"
            onClick={this.pushData}
            className="btn btn--primary"
            style={{borderRadius: '30px'}}
          >
            CONTINUAR
          </Link>
        );
      }
    }
    if (this.props.quantity === 0) {
      return (
        <Fragment>
          <button type="button" className="btn btn--border btn--next" disabled>
            CONTINUAR
          </button>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Link to="/asientos" className="btn btn--border btn--next" onClick={this.pushData}>
            CONTINUAR
          </Link>
        </Fragment >
      );
    }
  }
  handleRecaptcha = () => {
    this.recaptchaRef.current.execute();
  }
  getToken = () => {
    const tokenG = this.recaptchaRef.current.getValue();
    if(tokenG !== "" && window && sessionStorage.getItem("first-reload") !== "reload"){
      this.setState({tokenG: tokenG});
      if (this.props.selectedTickets.length > 0 && this.props.sessionId !== null) {
        this.props.getTicketTypes(this.props.cinemaVistaId, this.props.showtimeVistaId, this.props.reload, this.props.cinema_type, this.props.screen, this.props.sessionId, tokenG, this.props.countryCode);
      }
      else {
        this.props.getTicketTypes(this.props.cinemaVistaId, this.props.showtimeVistaId, this.props.reload, this.props.cinema_type, this.props.screen, this.props.sessionId, tokenG, this.props.countryCode);
      }
    } else {
      if(this.props.initialServicesFinished && window && sessionStorage.getItem("first-reload") === "reload") {
        sessionStorage.setItem("first-reload", "already-reloaded")
        window.location.reload()
      }
    }
  }
  render() {
    const { isLoadingTickets, showCardsList, isShowCinepolisId } = this.props;
    return (
      <div className={showCardsList ? 'prevent-interaction' : ''}>
        {!this.props.isLoggedIn && this.props.isMobileOrDesktop && isShowCinepolisId && <Return to="/" />}
        {!this.state.tokenG ?
         <Loader text="boletos" />
        :
          <div className={`container container--small tickets ${this.isJunior() ? 'tickets--junior' : ''}`}>
            <h3 className="title ticket-title">Selecciona tus boletos</h3>
            {!this.props.isLoadingTickets && <p className="text">Puedes comprar {this.props.maxQuantity} boletos máximo por transacción.</p>}
            <div className="ticket-container margin-to-btn">
              {this.renderTicketTypes()}
            </div>
            {this.renderButton()}
            {(!isLoadingTickets && (
              this.props.countryCode === 'MX' || 
              this.props.countryCode == null ||
              this.props.countryCode == 'CL'
              )) && <PromoCardButton hasFolio={this.props.hasFolio} toggleCards={this.props.handleToggleCardsList} countryCode={this.props.countryCode} />}
            {!this.props.isLoggedIn && !this.props.isMobileOrDesktop && isShowCinepolisId && <Return to="/" />}
          </div>
        }
          <ReCAPTCHA
            ref={this.recaptchaRef}
            size="invisible"
            sitekey="6LcYxXcUAAAAAPd2-4EIfCksacpQd6xVYtQsRP_D"
            onChange={this.getToken}
          />
      </div>
    );
  }
}
const mapStateToProps = ({ login, tickets, cards, globals, ui, showtime, seats, locations, payments }) => {
  let isShowCinepolisId = false;
  
  const duoSeatModifierCinemas = [
    "plaza morelia",
    "oasis coyoacán"
  ]

  let initialServicesFinished = false;
  if (showtime) {
    if (showtime.settings) {
      if (showtime.settings.profile) {
        let profile = showtime.settings.profile;
        isShowCinepolisId = profile.indexOf("cinepolis_id") !== -1;
      }
    }
    const { benefits, showtimeInfo, settings, paymentSettings, footer} = showtime
    
    const hasBenefits = globals.countryCode === 'MX' || globals.countryCode == null || globals.countryCode === 'PE' || globals.countryCode === 'CL'
    const benefitsValidation = (benefits.length > 0 || typeof benefits !== "object")

    const objectServicesFinished = showtimeInfo !== null && settings !== null && paymentSettings !== null
    const arrayServicesFinished = footer.length > 0 || typeof footer !== "object"
    
    const benefitsServiceFinished = hasBenefits ? benefitsValidation : true 
    initialServicesFinished =  objectServicesFinished && arrayServicesFinished && benefitsServiceFinished
  }
  
  const hasDuoSeatModifier = duoSeatModifierCinemas.filter(cinema => showtime.cinema.name.toLowerCase().includes(cinema)).length > 0
  let cityName = ''
  if (locations && locations.cities) {
    const currentCity = locations.cities.find(city => {
      const hasCurrentCinema = city?.cinemas.find(cinema => cinema.vista_id === showtime.cinema.vista_id)
      return hasCurrentCinema
    })
    cityName = currentCity?.name
  }
  return {
    quantity: Object.keys(tickets.types).length > 0 ?
      Object.keys(tickets.types).reduce((acc, key) => {
        return acc + tickets.types[key];
      }, 0)
      : 0,
    isShowCinepolisId,
    maxQuantity: maxQuantitySelector(tickets),
    isLoadingTickets: tickets.isLoading,
    tickets: tickets.tickets,
    selectedTicketsTypes: tickets.types,
    selectedTickets: tickets.selectedTickets,
    folio: cards.folio,
    cardsResponse: cards.response,
    isLoadingCards: cards.isLoading,
    cardList: cards.cardList,
    continueCloseModalFolios: ui.continueToggleCardsLis,
    cinemaVistaId: globals.cinemaVistaId,
    showtimeVistaId: globals.showtimeVistaId,
    countryCode: globals.countryCode,
    isMobile: ui.isMobile,
    windowSize: ui.size,
    showCardsList: ui.showCardsList,
    isLoggedIn: login.loginResponse !== null,
    showtime,
    hasDuoSeatModifier,
    sessionId: globals.sessionId,
    reload: seats.response.status_code === 0 || tickets.showtimeVistaId !== globals.showtimeVistaId,
    isMobileOrDesktop: (ui.isMobile && ui.size >= 768 && ui.size < 1024) || !ui.isMobile,
    cinema_type: showtime.movie.format,
    screen: showtime.showtimes.screen,
    hasFolio: cards.cardList.length > 0,
    symbol: showtime.settings?.currency.symbol ? showtime.settings.currency.symbol : '$',
    currencyCode: showtime.settings?.currency.code ? showtime.settings.currency.code : 'MXN',
    cityName,
    isMX: showtime.settings?.currency && showtime.settings.currency.code === 'MXN',
    cinemaApiId: showtime.cinema.id,
    movieFacility: showtime.movie.format,
    movieLanguage: showtime.movie.language,
    movieName: showtime.movie.name,
    movieRating: showtime.movie.rating,
    movieGenre: showtime.movie.genre,
    movieDistributor: showtime.movie.distributor,
    movieDuration: showtime.movie.length,
    movieId: showtime.movie.id,
    totalAmount: tickets.total,
    movieDate: showtime.showtimes.datetime,
    initialServicesFinished
  }
};
const mapDispatchToProps = (dispatch) => {
  return {
    reloadTickets: (cardsResponse) => dispatch(reloadTickets(cardsResponse)),
    resetSelectedTickets: () => dispatch(resetSelectedTickets()),
    getTicketTypes: (cinemaVistaId, showtimeVistaId, reload, cinemaType, screen, sessionId, tokenG, countryCode) => dispatch(getTicketTypes(cinemaVistaId, showtimeVistaId, reload, cinemaType, screen, sessionId, tokenG, countryCode)),
    isValidCard: (folio, setTicketLoader) => dispatch(isValidCard(folio, setTicketLoader)),
    handleToggleCardsList: () => {
      dispatch(toggleCardsList());
    },
    handleToggleCardsListClose: () => {
      dispatch(toggleCardsListClose());
    },
    reloadCurrentTickets: () => dispatch(reloadCurrentTickets()),
    cancelOrder: (sessionId, resetSession, countryCode) =>
      dispatch(cancelOrder(sessionId, resetSession, countryCode))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Tickets);
