import React from 'react';


const PaymentMethod = (props) => (
  props.active
    ? (<div className={`payment__method-box${props.className ? ` payment__method-box--${props.className}` : ''}`}>
        { props.children }
      </div>)
    : null
);

export default PaymentMethod;
