
import axios from 'axios';
var url_api = process.env.REACT_APP_CNPL_PURCHASE_BASE_API;

export const orderService = (url, sessionId, countryCode) => {
  return axios.post(url_api + `order/${url}`, { session_id: sessionId, country_code: countryCode })
};

export const seatsService = (url, data) => {
  return axios.post(url_api + url, data).then(data => data.data);
};

export const ticketsService = (cinemaVistaId, showtimeVistaId, reload, cinema_type, screen, sessionId, tokenG, countryCode) => {
  return axios.post(url_api + 'tickets', { cinema_vista_id: cinemaVistaId, showtime_vista_id: showtimeVistaId, reload, app_dynamics: { cinema_type, screen }, session_id: sessionId, token: tokenG, country_code: countryCode }, { headers: { 'sessionId': sessionId } });
};

export const reservationService = (data, sessionId, tokenR, countryCode) => {
  return axios.post(url_api + 'purchase/unpaid_booking', {
    session_id: sessionId,
    bill_to: data,
    token_google: tokenR,
    country_code: countryCode,
    angel_anon_user_id: localStorage.getItem('angelAnonymousUserId'),
    angel_project_slug: localStorage.getItem('angelProjectSlug')
  });
};

export const bankCardService = (data, sessionId, fingerprintId, cardType, tokenR, countryCode) => {
  let month = data.card_date.split('/')[0];
  let year = data.card_date.split('/')[1];
  return axios.post(url_api + 'purchase/bank_card', {
    session_id: sessionId,
    bill_to: data,
    card: {
      cvc: data.card_cvv,
      expiry_month: month,
      expiry_year: year,
      number: data.card_number,
      type: cardType
    },
    loyalty_card: data.loyalty,
    fingerprint_id: fingerprintId,
    token_google: tokenR,
    country_code: countryCode,
    angel_anon_user_id: localStorage.getItem('angelAnonymousUserId'),
    angel_project_slug: localStorage.getItem('angelProjectSlug')
  });
};

export const spreedlyService = (data, sessionId, fingerprintId, paymentMethod, tokenR, first_six_digits, appDynamics, loyalty_card, countryCode, paymentProvider, invoicing_data, cardType, last_four_digits) => {
  const requestBody = {
    bill_to: data,
    payment_method: paymentMethod,
    fingerprint_id: fingerprintId,
    token_google: tokenR,
    session_id: sessionId,
    first_six_digits,
    app_dynamics: appDynamics,
    loyalty_card: loyalty_card,
    country_code: countryCode,
    payment_provider: paymentProvider,
    card_type: cardType,
    last_four_digits,
    angel_anon_user_id: localStorage.getItem('angelAnonymousUserId'),
    angel_project_slug: localStorage.getItem('angelProjectSlug')
  }
  //   if (countryCode === "GT" || countryCode === "CO" || countryCode === "SV") { requestBody["invoicing_data"] = invoicing_data }
  if (countryCode === "GT" || countryCode === "CO") {requestBody["invoicing_data"] = invoicing_data}
  return axios.post(url_api + 'purchase/spreedly', requestBody);
};
/*

export const deunaService = (data, sessionId, fingerprintId, paymentToken, tokenR, first_six_digits, appDynamics, loyalty_card, countryCode, paymentProvider, invoicing_data, cardType, last_four_digits, amount) => {
  const requestBody = {
    bill_to: data,
    payment_token: paymentToken,
    fingerprint_id: fingerprintId,
    token_google: tokenR,
    session_id: sessionId,
    first_six_digits,
    app_dynamics: appDynamics,
    loyalty_card: loyalty_card,
    payment_provider: paymentProvider,
    card_type: cardType,
    angel_anon_user_id: localStorage.getItem('angelAnonymousUserId'),
    angel_project_slug: localStorage.getItem('angelProjectSlug'),
    country_code: countryCode,
    amount: parseInt(amount),
  }
  if (countryCode === "GT") { requestBody["invoicing_data"] = invoicing_data }
  return axios.post(url_api + 'purchase/card/complete_order', requestBody);
};  */

export const cineCashBalance = (accessToken, countryCode) => {
  return axios.get(url_api + 'purchase/cinecash/balance', {
    params: { accessToken, countryCode }
  });
};

export const cineCashPaymentService = (data, sessionId, accessToken, cinemaName, movieName, loyaltyNumber, tokenR, countryCode) => {
  return axios.post(url_api + 'purchase/cinecash',
    {
      session_id: sessionId,
      access_token: accessToken,
      cinema_name: cinemaName,
      movie_name: movieName,
      bill_to: data,
      loyalty_card: loyaltyNumber,
      token_google: tokenR,
      country_code: countryCode,
      angel_anon_user_id: localStorage.getItem('angelAnonymousUserId'),
      angel_project_slug: localStorage.getItem('angelProjectSlug')
    });
};

export const clubCinepolisPaymentService = (data, sessionId, pin, loyaltyNumber, tokenR, countryCode) => {
  return axios.post(url_api + 'purchase/club_cinepolis',
    {
      session_id: sessionId,
      bill_to: data,
      pin,
      card_number: loyaltyNumber,
      token_google: tokenR,
      country_code: countryCode,
      angel_anon_user_id: localStorage.getItem('angelAnonymousUserId'),
      angel_project_slug: localStorage.getItem('angelProjectSlug')
    });
};

export const paypalOrderService = (sessionId, tickets, amount, cinemaVistaId, bill_to, tokenR, concessions, bookingFee, appDynamics, countryCode) => {
  try {
    var items = tickets.map((ticket) => {
      return {
        price: ticket.price,
        product: ticket.type,
        description: ticket.type,
        quantity: ticket.quantity
      };
    });
    if (typeof concessions.concessions !== 'undefined' && concessions.concessions !== null) {
      concessions.concessions.map((concession) => {
        items.push({
          price: parseInt(concessions.concessions_total / concessions.concessions.length),
          product: concession.name,
          description: concession.name,
          quantity: concession.quantity
        });
      });
    }
    if (bookingFee > 0) {
      items.push({
        price: bookingFee,
        product: 'CARGO POR SERVICIO',
        description: 'CARGO POR SERVICIO',
        quantity: 1
      });
    }
    return axios.post(url_api + 'purchase/paypal/order',
      {
        session_id: sessionId,
        cinema_vista_id: cinemaVistaId,
        amount,
        items,
        token_google: tokenR,
        country_code: countryCode
      });
  } catch (e) {
    console.log(e);
  }

};

export const payPalService = (sessionId, token, payerId, billTo, loyaltyCard, appDynamics, loyalty_card, countryCode) => {
  return axios.post(url_api + 'purchase/paypal',
    {
      session_id: sessionId,
      token,
      payer_id: payerId,
      bill_to: billTo,
      loyalty_card: loyaltyCard,
      app_dynamics: appDynamics,
      loyalty_card: loyalty_card,
      country_code: countryCode,
      angel_anon_user_id: localStorage.getItem('angelAnonymousUserId'),
      angel_project_slug: localStorage.getItem('angelProjectSlug')
    });
};

export const kueskiServiceOrder = async (sessionId, tickets, amount, cinemaVistaId, bill_to, tokenR, concessions, bookingFee, appDynamics, countryCode, card_cc ) => {

  
  var items = tickets.map((ticket) => {
    return {
      amount: parseInt(ticket.price),
      name: ticket.type,
      description: ticket.type,
      quantity: ticket.quantity,
      sku: ticket.type
    };
  });
  if (typeof concessions.concessions !== 'undefined' && concessions.concessions !== null) {
    concessions.concessions.map((concession) => {
      items.push({
        amount: parseInt(concessions.concessions_total / concessions.concessions.length),
        name: concession.name,
        description: concession.name,
        quantity: concession.quantity,
        sku: concession.name
      });
    });
  }
  if (bookingFee > 0) {
    items.push({
      amount: parseInt(bookingFee),
      name: 'CARGO POR SERVICIO',
      description: 'CARGO POR SERVICIO',
      quantity: 1,
      sku: 'CARGO POR SERVICIO'
    });
  }

  const datos = {
    session_id: sessionId,
    cinema_vista_id: cinemaVistaId,
    amount: parseInt(amount),
    items,
    country_code: countryCode || 'MX',
    order_type: 'tickets',
    currency: 'MXN',
    tip: 0,
    token_google: tokenR,
    customer: {
      last_name: bill_to.last_name,
      first_name: bill_to.first_name,
      email: bill_to.email
    },
    loyalty_card: card_cc?.replace(/\s+/g, '') || '',
  }

  try {

    const { data } = await axios.post(`${url_api}purchase/kueski/checkout`, datos);

    return data;

  } catch (error) {
    return {
      error,
      msg: 'Ocurrio un error',
      status: 400
    }
  }
}

export const kueskiServiceOrderComplete = async (sessionId, checkout_id, countryCode, tokenR ) => {

  const datos = {
    session_id: sessionId,
    checkout_id,
    country_code: countryCode || 'MX',
    token_google: tokenR,
    angel_anon_user_id: localStorage.getItem('angelAnonymousUserId'),
    angel_project_slug: localStorage.getItem('angelProjectSlug')
  }

  try {

    const { data } = await axios.post(`${url_api}purchase/kueski/complete_order`, datos);

    return data;

  } catch (error) {
    return {
      error,
      msg: 'Ocurrio un error',
      status: 400
    }
  }
}
 
export const visaCheckOutDecryptService = (data, wrappedKey, countryCode) => {
  return axios.post(url_api + 'purchase/visa_checkout/decrypt',
    {
      data,
      wrapped_key: wrappedKey,
      country_code: countryCode
    });
};

export const visaCheckoutService = (data, wrappedKey, billTo, callId, card, cinema, fingerPrintId, loyaltyCard, movie, sessionId, countryCode) => {
  return axios.post(url_api + 'purchase/visa_checkout',
    {
      data,
      wrapped_key: wrappedKey,
      session_id: sessionId,
      cinema,
      movie,
      card,
      call_id: callId,
      finger_print_id: fingerPrintId,
      bill_to: billTo,
      loyalty_card: loyaltyCard,
      country_code: countryCode,
      angel_anon_user_id: localStorage.getItem('angelAnonymousUserId'),
      angel_project_slug: localStorage.getItem('angelProjectSlug')
    });
};

export const annualPassService = (data, sessionId, tokenR, countryCode) => {
  return axios.post(url_api + 'purchase/annual_pass', {
    session_id: sessionId,
    bill_to: data,
    token_google: tokenR,
    country_code: countryCode,
    angel_anon_user_id: localStorage.getItem('angelAnonymousUserId'),
    angel_project_slug: localStorage.getItem('angelProjectSlug')
  });
};

export const priceZeroService = (data, sessionId, tokenR, countryCode) => {
  return axios.post(url_api + 'purchase/promotions', {
    session_id: sessionId,
    bill_to: data,
    token_google: tokenR,
    country_code: countryCode,
    angel_anon_user_id: localStorage.getItem('angelAnonymousUserId'),
    angel_project_slug: localStorage.getItem('angelProjectSlug')
  });
};

export const showtimeService = (cinemaVistaId, showtimeVistaId, countryCode, tokenR) => {
  return axios.get(url_api + 'schedule', { params: { cinemaVistaId, showtimeVistaId, countryCode }, headers: { '_ga_z': tokenR } });
};

export const loginService = (userName, password) => {
  return axios.post(url_api + 'login/validate', { userName, password });
};

export const cardsService = (showtimeVistaId, cinemaVistaId, folio, app_dynamics, session_id, tokenR, countryCode) => {
  return axios.post(url_api + 'tickets/benefit/validate', {
    showtime_vista_id: showtimeVistaId,
    cinema_vista_id: cinemaVistaId,
    folio,
    app_dynamics,
    session_id,
    token: tokenR,
    country_code: countryCode
  }).then(data => data.data);
};

export const benefitInvalidateService = (showtimeVistaId, cinemaVistaId, folio, app_dynamics, session_id, tokenR, countryCode) => {
  return axios.post(url_api + 'tickets/benefit/invalidate', {
    showtime_vista_id: showtimeVistaId,
    cinema_vista_id: cinemaVistaId,
    folio,
    app_dynamics,
    session_id,
    token: tokenR,
    country_code: countryCode
  }).then(data => data.data);
};

export const getCinemas = (cityId) => {
  return axios.get(url_api + 'location/cinemas', { params: { cityId } });
};

export const getBenefitsService = (countryCode) => {
  return axios.get(url_api + 'tickets/benefits', { params: { countryCode } });
};

export const getFooterService = (countryCode) => {
  return axios.get(url_api + 'footer', { params: { countryCode } });
};

export const getShowtimeInfoService = (cinemaVistaId, showtimeVistaId, countryCode) => {
  return axios.get(url_api + 'showtime',
    {
      params: {
        cinemaVistaId,
        showtimeVistaId,
        countryCode
      }
    });
};

export const getSettingsService = (countryCode) => {
  return axios.get(url_api + 'settings', { params: { countryCode } });
};

export const recoverPinService = (card, countryCode) => {
  return axios.post(url_api + 'loyalty/recover_pin',
    {
      card_number: card,
      country_code: countryCode
    });
};

export const getAppleWalletService = (obj, countryCode) => {
  return axios.post(url_api + 'service/applewallet', { ...obj, countryCode }).then(data => data.data);
};

export const getCities = (countryCode) => {
  return axios.get(url_api + "location/cities", { params: { countryCode } });
};

export const getMastercardBines = (countryCode) => {
  return axios.get(url_api + 'settings/bines', { params: { countryCode } });
};

export const spreedlyPurchaseStatus = (data, sessionId, fingerprintId, paymentMethod, tokenR, first_six_digits, appDynamics, countryCode, paymentProvider, cardType, last_four_digits) => {
  return axios.post(url_api + 'purchase/status',
    {
      bill_to: data,
      app_dynamics: appDynamics,
      payment_method: paymentMethod,
      fingerprint_id: fingerprintId,
      token_google: tokenR,
      session_id: sessionId,
      first_six_digits: first_six_digits,
      country_code: countryCode,
      payment_provider: paymentProvider,
      card_type: cardType,
      last_four_digits,
      angel_anon_user_id: localStorage.getItem('angelAnonymousUserId'),
      angel_project_slug: localStorage.getItem('angelProjectSlug')
    },
    { headers: { 'Content-Type': 'application/json' } });
};
/*export const deunaPurchaseStatus = (data, sessionId, fingerprintId, paymentMethod, tokenR, first_six_digits, appDynamics, countryCode, paymentProvider, cardType, last_four_digits, amount) => {
  return axios.post(url_api + 'purchase/status',
    {
      bill_to: data,
      app_dynamics: appDynamics,
      payment_token: paymentMethod,
      fingerprint_id: fingerprintId,
      token_google: tokenR,
      session_id: sessionId,
      first_six_digits: first_six_digits,
      country_code: countryCode,
      payment_provider: paymentProvider,
      card_type: cardType,
      angel_anon_user_id: localStorage.getItem('angelAnonymousUserId'),
      angel_project_slug: localStorage.getItem('angelProjectSlug'),
      amount: parseInt(amount),
    },
    { headers: { 'Content-Type': 'application/json' } });
};  */

export const reservationServicePurchaseStatus = (data, sessionId, tokenR, appDynamics, countryCode) => {
  return axios.post(url_api + 'purchase/status', {
    session_id: sessionId,
    bill_to: data,
    token_google: tokenR,
    app_dynamics: appDynamics,
    country_code: countryCode,
    angel_anon_user_id: localStorage.getItem('angelAnonymousUserId'),
    angel_project_slug: localStorage.getItem('angelProjectSlug')
  });
};

export const bankCardServicePurchaseStatus = (data, sessionId, fingerprintId, cardType, tokenR, appDynamics, countryCode) => {
  let month = data.card_date.split('/')[0];
  let year = data.card_date.split('/')[1];
  return axios.post(url_api + 'purchase/status', {
    session_id: sessionId,
    bill_to: data,
    card: {
      cvc: data.card_cvv,
      expiry_month: month,
      expiry_year: year,
      number: data.card_number,
      type: cardType
    },
    loyalty_card: data.loyalty,
    fingerprint_id: fingerprintId,
    token_google: tokenR,
    app_dynamics: appDynamics,
    country_code: countryCode,
    angel_anon_user_id: localStorage.getItem('angelAnonymousUserId'),
    angel_project_slug: localStorage.getItem('angelProjectSlug')
  });
};

export const cineCashPurchaseStatus = (data, sessionId, accessToken, cinemaName, movieName, loyaltyNumber, tokenR, appDynamics, countryCode) => {
  return axios.post(url_api + 'purchase/status',
    {
      session_id: sessionId,
      access_token: accessToken,
      cinema_name: cinemaName,
      movie_name: movieName,
      bill_to: data,
      loyalty_card: loyaltyNumber,
      token_google: tokenR,
      app_dynamics: appDynamics,
      country_code: countryCode,
      angel_anon_user_id: localStorage.getItem('angelAnonymousUserId'),
      angel_project_slug: localStorage.getItem('angelProjectSlug')
    });
};

export const clubCinepolisPurchaseStatus = (data, sessionId, pin, loyaltyNumber, tokenR, appDynamics, countryCode) => {
  return axios.post(url_api + 'purchase/status',
    {
      session_id: sessionId,
      bill_to: data,
      pin,
      card_number: loyaltyNumber,
      token_google: tokenR,
      app_dynamics: appDynamics,
      country_code: countryCode,
      angel_anon_user_id: localStorage.getItem('angelAnonymousUserId'),
      angel_project_slug: localStorage.getItem('angelProjectSlug')
    });
};

export const payPalPurchaseStatus = (sessionId, token, payerId, billTo, loyaltyCard, appDynamics, countryCode) => {
  return axios.post(url_api + 'purchase/status',
    {
      session_id: sessionId,
      token,
      payer_id: payerId,
      bill_to: billTo,
      loyalty_card: loyaltyCard,
      app_dynamics: appDynamics,
      country_code: countryCode,
      angel_anon_user_id: localStorage.getItem('angelAnonymousUserId'),
      angel_project_slug: localStorage.getItem('angelProjectSlug')
    });
};

export const visaPurchaseStatus = (data, wrappedKey, billTo, callId, card, cinema, fingerPrintId, loyaltyCard, movie, sessionId, appDynamics, countryCode) => {
  return axios.post(url_api + 'purchase/status',
    {
      data,
      wrapped_key: wrappedKey,
      session_id: sessionId,
      cinema,
      movie,
      card,
      call_id: callId,
      finger_print_id: fingerPrintId,
      bill_to: billTo,
      loyalty_card: loyaltyCard,
      app_dynamics: appDynamics,
      country_code: countryCode,
      angel_anon_user_id: localStorage.getItem('angelAnonymousUserId'),
      angel_project_slug: localStorage.getItem('angelProjectSlug')
    });
};

export const annualPassPurchaseStatus = (data, sessionId, tokenR, appDynamics, countryCode) => {
  return axios.post(url_api + 'purchase/status', {
    session_id: sessionId,
    bill_to: data,
    token_google: tokenR,
    app_dynamics: appDynamics,
    country_code: countryCode,
    angel_anon_user_id: localStorage.getItem('angelAnonymousUserId'),
    angel_project_slug: localStorage.getItem('angelProjectSlug')
  });
};

export const priceZeroPurchaseStatus = (data, sessionId, tokenR, appDynamics, countryCode) => {
  return axios.post(url_api + 'purchase/status', {
    session_id: sessionId,
    bill_to: data,
    token_google: tokenR,
    app_dynamics: appDynamics,
    country_code: countryCode,
    angel_anon_user_id: localStorage.getItem('angelAnonymousUserId'),
    angel_project_slug: localStorage.getItem('angelProjectSlug')
  });
};

export const createInvoice = ({idNumber, name, email, documentType, foreigner, customerAddress, customerType, customerRUC}, countryCode, cinemaVistaId, transactionNumber) => {
  return axios.post(url_api + 'service/invoice', {
    country_code: countryCode,
    tax_number: idNumber,
    tax_name: name,
    email,
    transaction_number: transactionNumber,
    cinema_vista_id: cinemaVistaId,
    document_type: documentType,
    is_foreigner: foreigner,
    customer_address: customerAddress,
    customer_type: customerType,
    customer_RUC: customerRUC
  })
}
/*
export const getSettingsInvoicing = (countryCode) => {
  return axios.get(url_api + 'settings/invoicing', { params: { countryCode } });
  // return new Promise((resolve, reject) => { resolve(data) });
};
  */