import React from "react";
import { connect } from "react-redux";
import { addTicket, subTicket } from "../store/tickets";

import { formatPrice, formatPriceTwoDecimals, formatPriceCL } from "../helpers";

import localSessionStorage from "../services/localSessionStorage";

const TicketType = ({
  id,
  name,
  price,
  numTickets,
  prev,
  next,
  addTicket,
  subTicket,
  code,
  area,
  maxQuantity,
  currencyCharacter,
  color,
  message,
  folio,
  hopk,
  countryCode
}) => {
  const handleAddTicket = () => {
    addTicket({ type: id, price: +price, code, area, folio, hopk });
  };
  const handleSubTicket = () => {
    subTicket({ type: id, price: +price, code, area, folio, hopk });
  };

  return (
    <li className="ticket__row">
      <div className="ticket__col">
        <p className="text" style={{ color }}>
          {name} {message !== "" ? `- ${message}` : ""}
          {folio !== null
            ? `****${folio.substring(folio.length - 4, folio.length)}`
            : null}
        </p>
      </div>
      <div className="ticket__col ticket__col--aright">
        <p className="price">{currencyCharacter}{countryCode === 'PA' ? formatPriceTwoDecimals(price) : (countryCode === 'CL' ? formatPriceCL(price) : formatPrice(price))}</p>
      </div>
      <div className="ticket__col ticket__qty">
        <span className="ticket__input">{numTickets || 0}</span>
        <div className="ticket__actions">
          <button
            type="button"
            className="ticket__action"
            onClick={handleSubTicket}
            disabled={numTickets <= 0 || !prev}
            aria-label={`Quitar un boleto ${name}`}
          >
            <svg width="11" height="1" viewBox="0 0 11 1">
              <path fill="#0096D5" fillRule="nonzero" d="M0 1V0h11v1z" />
            </svg>
          </button>
          <button
            type="button"
            className="ticket__action"
            onClick={handleAddTicket}
            disabled={numTickets >= maxQuantity || !next}
            aria-label={`Añadir un boleto ${name}`}
          >
            <svg width="11" height="11" viewBox="0 0 11 11">
              <g fill="#0096D5" fillRule="nonzero">
                <path d="M0 6V5h11v1z" />
                <path d="M6 11H5V0h1z" />
              </g>
            </svg>
          </button>
        </div>
      </div>
    </li>
  );
};

const mapStateToProps = ({ tickets, globals }, ownProps) => ({
  numTickets: tickets.types[ownProps.id] ? tickets.types[ownProps.id] : "",
  countryCode: globals.countryCode,
});

export default connect(mapStateToProps, { addTicket, subTicket })(TicketType);
