import React, { memo } from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs';

import { selectedSeatsHumanSelector, ticketsSelector, concessionsSelector } from '../selectors/index';
import hideFromRoutes from '../hoc/hideFromRoutes';


const Movie = (props) => {
  return (
    <ul className="nostyle poster-list">
        <li className="poster-item">
          <figure className="poster-item__img">
            { /[.jpg|.jpeg|.png]$/.test(props.movieUrlPoster) && <img src={ props.movieUrlPoster } alt="" /> }
          </figure>

        <div className="poster-item__info">
          <p className="poster-item__text"><span className="blue bold">Película: </span>{ props.movieName }</p>
          <p className="poster-item__text"><span className="blue bold">Complejo: </span>{ props.cinemaName }</p>
          <p className="poster-item__text"><span className="blue bold">Fecha: </span>{props.date }</p>
          <p className="poster-item__text"><span className="blue bold">Función: </span>{dayjs(props.dateTime).format('h:mm a').toUpperCase() }</p>
          {props.tickets !== ''
            ? <p className="poster-item__text">
                <span className="black bold">Boletos: </span>
                {props.hasDuoSeatModifier ? props.tickets.replace("ADULTO DUO", "ADULTO DUO 2 personas") : props.tickets}
              </p>
            : null 
          }
{
  props.concessions !== '' && props.sessionId !== null
    ? <p className="poster-item__text">
    <span className="black bold"></span>
    {props.concessions}
    </p>
  : null
}
          {props.selectedSeats !== ''
            ? <p className="poster-item__text">
						  <span className="black bold">Asientos: </span>
                { props.selectedSeats }
              </p>
            : null
          }
</div>
      </li>
    </ul>
  );
}


const areEqual = (prevProps, nextProps) => {
  if (prevProps.movieName === nextProps.movieName &&
    prevProps.cinemaName === nextProps.cinemaName &&
    prevProps.tickets === nextProps.tickets &&
    prevProps.selectedSeats === nextProps.selectedSeats) {
  return true;
}

return false;
};

const mapStateToProps = state => {
  const { showtime, globals } = state;
    
  const duoSeatModifierCinemas = [
    "plaza morelia",
    "oasis coyoacán"
  ]

  const hasDuoSeatModifier = duoSeatModifierCinemas.filter(cinema => showtime.cinema.name.toLowerCase().includes(cinema)).length > -1

  return {
    movieUrlPoster: showtime.movie.url_poster,
    movieName: showtime.movie.name,
    cinemaName: showtime.cinema.name,
    date: showtime.showtimes.date,
    dateTime: showtime.showtimes.datetime,
    tickets: ticketsSelector(state, showtime.settings?.currency.symbol ? showtime.settings.currency.symbol : '$', globals.countryCode),
    hasDuoSeatModifier,
    selectedSeats: selectedSeatsHumanSelector(state),
    concessions: concessionsSelector(state, showtime.settings?.currency.symbol ? showtime.settings.currency.symbol : '$'),
    sessionId: state.payments.sessionId
};
};

export default hideFromRoutes(
  memo(
    connect(mapStateToProps)(Movie), 
    areEqual
  ), 
  ['/error']
);
