import React from 'react';
import { connect } from 'react-redux';

import { showModal } from '../store/ui';
import Input from './Input';

const CardPin = ({ text, name, label, section, onChangePin, showPinModal, cardNumber, code }) => {

  const handlePinModal = () => {
    if (section === 'payments') {
      showPinModal({ type: 'pin-main', position: 'main', data: { cardNumber: cardNumber.replace(/\s+/g, '') } });
    } else if (section === 'aside') {
      showPinModal({ type: 'pin', position: 'aside', data: { cardNumber: cardNumber.replace(/\s+/g, '') } });
    }
  };

  return (
    <div className="card__pin">
      <p className="text">{ text }</p>
      {code === 'club-cinepolis' && <div className="icon-question" onClick={handlePinModal}>?</div>}

      <Input 
        type="password"
        name={name}
        id={name}
        className="form-control--light"
        label={label}
        onChange={onChangePin}
        validations={['notEmpty', 'numeric', 'minLength:4', `maxLength:${(code === 'otros') ? 6 : 4}`]}
      />
    </div>);
};

CardPin.defaultProps = {
  label: ''
};

const mapDispatchToProps = (dispatch) => {
  return {
    showPinModal: (obj) => dispatch(showModal(obj))
  };
};

export default connect(null, mapDispatchToProps)(CardPin);
