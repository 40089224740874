import localSessionStorage from '../services/localSessionStorage';

export default store => next => action => {
  next(action);

  if (action.metadata && action.metadata.local === 'save') {
    localSessionStorage.set('cccw-state', store.getState());
  } else if (action.metadata && action.metadata.local === 'clear') {
    localSessionStorage.clear('cccw-state');
  } else if (action.metadata && action.metadata.local === 'clearAll') {
    localSessionStorage.clear();
  }
};
