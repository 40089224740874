import { loginService } from '../services';

const LOGIN_REQUEST = 'LOGIN_REQUEST';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const CLEAR_LOGIN_RESPONSE = 'CLEAR_LOGIN_RESPONSE';


export const requestLogin = (userName, password) => dispatch => {
  dispatch({ type: LOGIN_REQUEST });
  loginService(userName, password).then((response) => {
    dispatch({ type: LOGIN_SUCCESS, payload: response.data, metadata: { local: 'save' } });
  });
};
export const clearLoginResponse = () => ({ type: CLEAR_LOGIN_RESPONSE, metadata: { local: 'save' } });

const initialState = {
  isLoading: false,
  loginResponse: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return { ...state, isLoading: true };
    case LOGIN_SUCCESS:
      return { ...state, isLoading: false, loginResponse: action.payload };
    case CLEAR_LOGIN_RESPONSE:
      return initialState;
    default:
      return state;
  }
};
