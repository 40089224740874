import React, { Component } from 'react';
import { connect } from 'react-redux';

import { hideModal } from '../store/ui';
import { recoverPin, clearRecoverPinResponse } from '../store/cards';
import Modal from './Modal';

class CardPinModal extends Component {

  constructor (props) {
    super(props);

    this.state = {
      showMessage: false,
      statusCode: null
    };
  }

  static getDerivedStateFromProps (props, state) {
    if (props.recoverPinStatus !== null && state.statusCode === null) {
      return {
        showMessage: true,
        statusCode: props.recoverPinStatus
      };
    }

    return null;
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.modal !== null && prevProps.modal.type === 'pin' && prevProps.route !== this.props.route &&
        ((prevProps.modal.position === 'aside' && this.props.route !== '/boletos') ||
         (prevProps.modal.position === 'main' && this.props.route !== '/pago'))) {
      this.props.handleCancelPinModal();
    }
  }

  handleRecoverPin = () => {
    if (this.props.cardNumber !== null) {
      this.setState({ showMessage: false, statusCode: null });
      this.props.handleRecoverPin(this.props.cardNumber);
    }
  };

  removeErrorMessage = () => {
    this.props.clearRecoverPinResponse();
    this.setState({ showMessage: false });
  };
  
  handleCancelPinModal = () => {
    this.props.handleCancelPinModal();
    if (this.state.statusCode) {
      this.props.clearRecoverPinResponse();
    }
  };

  render () {
    return (
      <Modal
        className="modal--pin"
        cancel={this.handleCancelPinModal}
        title="Recuperar PIN"
      >
        <p className="cardpin-text">
          Ahora tu tarjeta cuenta con un PIN de seguridad que necesitarás para gozar de los siguientes beneficios:
        </p>

        <ul className="nostyle cardpin-list">
          <li className="cardpin-item">
            <span className="cardpin-num">1</span>
            <p className="cardpin-text cardpin-itemtext">Consulta tus puntos, visitas y transacciones de forma segura en cualquier momento.</p>
          </li>
          <li className="cardpin-item">
            <span className="cardpin-num">2</span>
            <p className="cardpin-text cardpin-itemtext">Paga tus boletos con los puntos <br/> <strong>¡son tu recompensa!</strong></p>
          </li>
        </ul>

        { this.state.showMessage && this.state.statusCode > 0 &&
          <div className="alert alert--warning" role="alert">
            <button type="button" className="alert__cross" onClick={this.removeErrorMessage} aria-label="close">
              <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11">
                <g fill="#69560e" fillRule="nonzero">
                  <path d="M.793 2.207A1 1 0 1 1 2.207.793l8.062 8.062a1 1 0 0 1-1.414 1.414L.793 2.207z"/>
                  <path d="M2.207 10.207A1 1 0 0 1 .793 8.793L8.855.73a1 1 0 1 1 1.414 1.414l-8.062 8.062z"/>
                </g>
              </svg>
            </button>
            <span className="alert__msg">{ this.props.recoverPinMessage }</span>
          </div>
        }

        {  this.state.showMessage && this.state.statusCode === 0 &&
          <div className="alert alert--success" role="alert">
            <span className="alert__msg">{ this.props.recoverPinMessage }</span>
          </div>
        }

        { (this.state.statusCode === null || this.state.statusCode > 0) && 
          <button type="button" className="btn btn--secondary" onClick={this.handleRecoverPin} disabled={this.props.cardNumber === null || this.props.isLoading}>RECUPERAR PIN</button>
        }

        <p className="cardpin-text">
          <strong>Si tienes algún problema con tu PIN puedes contactar a Cineticket al correo:</strong> <br/><br/>
          <a href="mailto:clubcinepolis@cinepolis.com" className="link">clubcinepolis@cinepolis.com</a>
        </p>
      </Modal>
    );
  }
}

const mapStateToProps = ({ ui, cards, globals }) => {
  return {
    modal: ui.modal,
    show: ui.modal !== null && ui.modal.type === 'pin',
    cardNumber: ui.modal !== null && ui.modal.type.indexOf('pin') > -1 ? ui.modal.data.cardNumber : null,
    recoverPinMessage: cards.recoverPinResponse !== null ? cards.recoverPinResponse.message : null,
    recoverPinStatus: cards.recoverPinResponse !== null ? cards.recoverPinResponse.status_code : null,
    isLoading: cards.isLoading,
    route: globals.route,
    countryCode: globals.countryCode
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleCancelPinModal: () => dispatch(hideModal()),
    handleRecoverPin: (card, countryCode) => dispatch(recoverPin(card, countryCode)),
    clearRecoverPinResponse: () => dispatch(clearRecoverPinResponse())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardPinModal);
