import React, { Component, Fragment } from 'react';
import { PropTypes } from 'prop-types';
import TagManager from 'react-gtm-module';
import CircleClubCinepolis from "../static/images/circle-club-cinepolis.svg";
import CirclePaseAnual from "../static/images/circle-pase-anual.svg";
import CircleOtros from "../static/images/circle-otros.svg";
import TrashIcon from "../static/images/trash.svg";


class CardAddItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showActionBtns: false
    };
  }

  componentDidMount() {
    const tagManagerArgs = {
      dataLayer: {
        event: "add_promotion_card",
        pass_name: this.props.code,
        times: new Date().toLocaleTimeString('es-MX', { hour12: false })
      }
    }
    TagManager.dataLayer(tagManagerArgs);
  }

  toggleShowActionBtns = () => {
    this.setState(prevState => ({
      showActionBtns: !prevState.showActionBtns
    }));
  };

  delete = (e) => {
    e.preventDefault();
    this.toggleShowActionBtns();
    this.props.handleInvalidateCard(this.props.item);
    localStorage.setItem("foliosPromocionCL", "false")
  }

  getEndFolio = folio => folio.substring(folio.length - 4, folio.length);


  render() {
    return (
      <Fragment>
        <div className={`card__added-item ${this.state.showLoadingDelete ? 'shrinkItem' : ''}`}>
          <img src={
            (this.props.code === 'club-cinepolis' && CircleClubCinepolis || (this.props.code === 'pase-anual' && CirclePaseAnual || CircleOtros))} alt="" className="thumbs-added-item" />
          <p>
            <span className="bold">{this.props.nameFolio} </span>
            <span>····{this.getEndFolio(this.props.numberFolio.toString())}</span>
          </p>
          <div className="trash-icon-card-added" onClick={this.toggleShowActionBtns}><img src={TrashIcon} alt="" /></div>

          {
            <div className={`card__added-item-remove ${!this.state.showActionBtns ? ' hidden' : ''}`}>
              <div className="card__added-item-remove-buttons">
              <span className="mask-animable"></span>
              <button className="btn-remove" type="button" onClick={this.delete}><span className="text-button-fade">Eliminar {this.props.numberFolio}</span></button>
                <button className="btn-back" type="button" onClick={this.toggleShowActionBtns}><span className="text-button-fade">Regresar <span>→</span></span></button>
              </div>
            </div>
          }
        </div>
      </Fragment>
    );
  }
}

CardAddItem.propTypes = {
  delete: PropTypes.func
}
export default CardAddItem;
