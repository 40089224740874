import { orderService } from '../services';
import { resetSessionData, setStartTime, requestError } from './globals';
import { resetTicketData } from './tickets';
import { resetSeatsData } from './seats';
import {clearCard} from './cards';
import { hideModal } from './ui';

/*
  Action creators
*/
export const cancelOrder = (sessionId, resetSession, countryCode, persistData = true) => (dispatch) => {
  dispatch(clearCard());
  if (persistData) {
    dispatch(resetTicketData());
    dispatch(resetSeatsData());
  }
  return orderService('cancel', sessionId).then((response) => {
    if (resetSession) {
      dispatch(resetSessionData());
      dispatch(requestError(10009, "", countryCode));
    }
    dispatch(hideModal());
  }).catch((error) => {
    if(error.response){
      if(error.response.status === 504){
        console.log("error 504  orderService")
      }
    }
  });
};

export const resetOrder = (sessionId, isErrorCatch, countryCode, hideModalFromPayment = false) => (dispatch) => {
  return orderService('reset', sessionId, countryCode).then((data) => {
    if (data.data.status_code > 0 && isErrorCatch) {
      dispatch(requestError(data.data.status_code, data.data.message, countryCode));
    } else {
      dispatch(setStartTime(data.data.limit_date));
      if(!hideModalFromPayment)
      dispatch(hideModal());
    }
  }).catch(console.log);
};
