import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { routeChanged } from '../store/globals';
import LocalRedirect from './LocalRedirect';


class RouteOrError extends Component {

  constructor (props) {
    super(props);

    this.errorURL = '/error';
  }

  componentDidMount () {
    if (this.props.currentRoute === this.props.path.replace(/\/$/, '')) { return true; }
    this.handleRouteChanged();
  }

  shouldComponentUpdate(prevProps) {
    return this.isEqual(prevProps);
  }

  componentDidUpdate (prevProps) {
    if (this.isEqual(prevProps)) {
      this.handleRouteChanged();
    }
  }

  isEqual (prevProps) {
    if (prevProps.location.pathname === this.props.location.pathname &&
      prevProps.message === this.props.message) {
      return false;
    }

    return true;
  }

  handleRouteChanged () {
    if (this.props.message !== null || this.props.guard) {
      this.props.routeChanged(this.errorURL);
    } else {
      this.props.routeChanged(this.props.path);
    }
  }

  guard () {
    if (this.props.guard) {
      this.handleRouteChanged();
      return true;
    }

    return false;
  }

  render () {
    const { message, statusCode } = this.props;

    if (message !== null || this.guard()) {
      return <LocalRedirect to={{ pathname: this.errorURL, state: { message, statusCode } }} />;
    }
  
    return <Route {...this.props} />;
  }
};

const mapStateToProps = ({ globals }) => ({
  currentRoute: globals.route,
  message: globals.error !== null && globals.error.status_code > 0 && globals.error.message ? globals.error.message : null,
  statusCode: globals.error !== null ? globals.error.status_code : null
});
export default connect(mapStateToProps, { routeChanged })(RouteOrError);
