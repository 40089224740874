import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';

import { requestLogin, clearLoginResponse } from '../store/login';
import { requestCineCashBalance } from '../store/payments';

import Input from './Input';
import PaymentMethod from './PaymentMethod';
import PaymentCardPoints from './PaymentCardPoints';
import Loader from './Loader';

import { CineCashLogoIcon } from './Icons';


class PaymentCinecash extends Component {

  constructor(props) {
    super(props);

    this.state = {
      user: {},
      error: props.login !== null && props.login.access_token === null ? props.login.error_description : null,
      valid: false,
      loggedIn: props.login !== null && props.login.access_token !== null ? props.login.access_token : false,
      fullName: '',
      balance: props.payments.cineCashBalance === null ? 0.0 : props.payments.cineCashBalance.credits_available
    };
  }

  componentDidMount () {
    if (this.props.login !== null && this.props.login.access_token !== null && this.props.payments.cineCashBalance === null) {
      this.props.requestCineCashBalance(this.props.login.access_token, this.props.countryCode);
    }

    if (this.state.error !== null) { this.removeErrorMessage(); }
  }

  shouldComponentUpdate (prevProps, prevState) {
    if (JSON.stringify(prevState.user) === JSON.stringify(this.state.user)
        && prevState.loggedIn === this.state.loggedIn && prevState.balance === this.state.balance
        && prevProps.isLoginLoading === this.props.isLoginLoading && prevState.error === this.state.error
        && prevState.valid === this.state.valid && prevProps.active === this.props.active
        && prevProps.login === this.props.login && prevProps.payments.cineCashBalance === this.props.payments.cineCashBalance) {
      return false;
    }

    return true;
  }

  componentDidUpdate (prevProps, prevState) {
    if (!prevState.valid) {
      this.isLoginValid();
    }

    if (this.props.login !== null && this.props.login.access_token !== null && this.props.payments.cineCashBalance === null) {
      this.props.requestCineCashBalance(this.props.login.access_token, this.props.countryCode);
    }

    if (!this.state.loggedIn && this.props.login !== null && this.props.login.access_token !== null && this.props.payments.cineCashBalance !== null) {      
      this.setState({ loggedIn: true, fullName: this.props.login.first_name, balance: this.props.payments.cineCashBalance.credits_available });
    }

    if (prevState.error === null && this.props.login !== null && prevProps.login !== this.props.login && this.props.login.access_token === null) {
      this.setState({ error: this.props.login.error_description });
    }
  }

  isLoginValid () {
    const { user } = this.state;

    if (Object.keys(user).length !== 0 && !this.state.valid &&
      user.hasOwnProperty('cc_email') && user.hasOwnProperty('cc_password')) {
      this.setState({ valid: true });
    }
  }

  handleChange = (name, value, isValid) => {
    const user = { ...this.state.user };

    if (isValid) {
      user[name] = value;
      this.setState({ user });
    } else {
      delete user[name];
      this.setState({ user, valid: false });
    }
  };

  removeErrorMessage = () => {
    this.props.clearLoginResponse();
    this.setState({ error: null });
  };
  
  handleSubmit = (e) => {
    e.preventDefault();

    if (this.state.error !== null) { this.removeErrorMessage(); }
    this.props.requestLogin(this.state.user.cc_email, this.state.user.cc_password);
  };

  render() {
    const isLoading = (this.props.isLoginLoading || (this.props.login !== null && this.props.login.access_token !== null && this.props.payments.cineCashBalance === null))

    return (
      <PaymentMethod active={this.props.active} className="welcome">
        <hgroup>
          <h3 className="title">Bienvenido a <figure class="cinecash-image" aria-label="Cinecash"><CineCashLogoIcon /></figure></h3>
          <h3 className="title dark-blue bold">
            {!this.state.loggedIn ? 'Inicia sesión' : this.state.fullName}
          </h3>
        </hgroup>

        { isLoading && <Loader /> }

        <div className={isLoading ? 'blur-overlay' : ''}>
        {!this.state.loggedIn
          ? <Fragment>
              <Input
                type="email"
                name="cc_email"
                id="cc_email"
                label="Correo electrónico"
                onChange={this.handleChange}
                validations={['email']}
              />
              <Input
                type="password"
                name="cc_password"
                id="cc_password"
                label="Contraseña"
                onChange={this.handleChange}
                validations={['notEmpty']}
              />

              <div className="row">
                <a href="http://www.cinepolis.com/id/inicio-recuperar-contrasena" className="text text--small link" target="_blank" rel="noopener noreferrer">¿Olvidaste tu contraseña?</a>
                <p className="text text--small"><a href="http://www.cinepolis.com/id/registro" className="link" target="_blank" rel="noopener noreferrer">Activa tu cuenta Cinépolis®</a></p>
              </div>

              { this.state.error !== null && 
                <div className="alert alert--warning" role="alert">
                  <button type="button" className="alert__cross" onClick={this.removeErrorMessage} aria-label="close">
                    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11">
                      <g fill="#69560e" fillRule="nonzero">
                        <path d="M.793 2.207A1 1 0 1 1 2.207.793l8.062 8.062a1 1 0 0 1-1.414 1.414L.793 2.207z"/>
                        <path d="M2.207 10.207A1 1 0 0 1 .793 8.793L8.855.73a1 1 0 1 1 1.414 1.414l-8.062 8.062z"/>
                      </g>
                    </svg>
                  </button>
                  <span className="alert__msg">{ this.state.error }</span>
                </div>
              }
              
              <button type="button" className="btn btn--primary" disabled={this.state.valid ? false : true} onClick={this.handleSubmit}>ENTRAR</button>
            </Fragment>
            : <PaymentCardPoints text="Créditos CineCash®" points={this.state.balance} />
        }
        </div>
      </PaymentMethod>
    );
  }
}

PaymentCinecash.propTypes = {
  active: PropTypes.bool,
  onInputChange: PropTypes.func
};

const mapStateToProps = ({ login, payments, globals }) => {
  return {
    isLoginLoading: login.isLoading,
    login: login.loginResponse,
    payments,
    countryCode: globals.countryCode
  };
};

export default connect(mapStateToProps, { requestCineCashBalance, requestLogin, clearLoginResponse })(PaymentCinecash);
