import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { useSwipeable} from 'react-swipeable';


const WizardModal = (props) => {
  const { className, icon, iconWidth, title, accept, acceptText, steps : stepsOrigin, cancel } = props
  const [state, setState] = useState({
    leave: false,
    hide: false,
    sizePage: 3,
    // currentPage: 1,
    pages: 0,
    blurred: false,
   
  })

  const [steps, setSteps] = useState([...stepsOrigin]);
  const [activeStep, setActiveStep] = useState(steps[0]);



  useEffect(() => {

    setState((prev) => {
      return {
        ...prev, blurred: true
      }
    })

    const timer = setTimeout(() => setState((prev) => {
      return {
        ...prev, blurred: false
      }
    }), 200);

    return () => clearTimeout(timer);
  }, [activeStep.id])



  const handleNext = () => {
    if (steps[steps.length - 1].id === activeStep.id) {
      
      return;
    }

    const index = steps.findIndex((x) => x.id === activeStep.id);
    setSteps((prevStep) =>
      prevStep.map((x) => {
        if (x.id === activeStep.id) x.isDone = true;
        return x;
      })
    );
    setActiveStep(steps[index + 1]);
  };

  const handleBack = () => {
    const index = steps.findIndex((x) => x.id === activeStep.id);
    if (index === 0) return;

    setSteps((prevStep) =>
      prevStep.map((x) => {
        if (x.id === activeStep.id) x.isDone = false;
        return x;
      })
    );
    setActiveStep(steps[index - 1]);
  };
  const selectStep = (payload) => {
    const index = steps.findIndex((x) => x.id === payload);

    setActiveStep(steps[index]);
  };

  const handlerSwipe = useSwipeable({
    onSwipedLeft: () => {
      handleNext();
    },
    onSwipedRight: () => {
      handleBack();
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const paginator=(array, page_size, page_number)=> {
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  }

    return (
      <div className={`modalWz ${className} active${state.leave ? ' leave' : ''}${state.hide ? ' hidden' : ''}`}>
        <div className="modalWz__box">
          
            <header className="modalWz__head">
              {(cancel) &&
                <button type="button" className="modalWz__close" onClick={cancel}>
                  <svg width="24" height="24" viewBox="0 0 15 15">  
                    <g fill="#D61919" fill-rule="nonzero">
                      <path d="M.793 2.207A1 1 0 1 1 2.207.793l8.062 8.062a1 1 0 0 1-1.414 1.414L.793 2.207z"></path>
                      <path d="M2.207 10.207A1 1 0 0 1 .793 8.793L8.855.73a1 1 0 1 1 1.414 1.414l-8.062 8.062z"></path>
                    </g>
                  </svg>
                </button>
              }
            {title && <p className="modalWz__step-title-custom">{activeStep?.description || title}</p>}
            </header>

            <div className="modalWz__body"
            {...handlerSwipe}
            >
              <div className={`modalWz__step-src-container`}>
              <img className={`modalWz__step-src${state.blurred ? " modalWz__animate" : ''}`} src={activeStep?.img}/>
              </div>
            <h4 className="modalWz__title">{activeStep?.title}</h4>
            <p className="modalWz__step-description">{activeStep?.subtitle}</p>
            </div>

            <div className="modalWz__step-points">
            {/* {(steps.length > 3 && state.currentPage > 1) && <div className="modalWz__step-arrow-left" onClick={handleBack}></div>} */}
              {
              steps.map((s, i) => {
                  return <><div
                    key={i}
                    aria-label={i}
                    className={`modalWz__step-point${s?.id == activeStep.id ? " active" : ''}`}
                    onClick={() => selectStep( s?.id)}
                  ></div></>
                })
              }
            {/* {(steps.length > 3 && state.currentPage <= state.pages - 1) && <div className="modalWz__step-arrow-right" onClick={handleNext} ></div>} */}
           
            </div>
          {
            <button type="button" className="btn btn--primary" onClick={steps[steps.length - 1].id !== activeStep.id ? handleNext:cancel   }>
              {steps[steps.length - 1].id !== activeStep.id ?"Siguiente":"De acuerdo"}
            </button>
          }
        </div>
      </div>
    );

}

WizardModal.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  cancel: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  iconWidth: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  accept: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  acceptText: PropTypes.string,
  steps: PropTypes.arrayOf(PropTypes.object)
}; 

WizardModal.defaultProps = {
  steps: []
}

export default connect(null, {  })(WizardModal);
