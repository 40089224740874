import React from 'react';

import hideFromRoutes from '../hoc/hideFromRoutes';
import Modal from './Modal';

const AsideModal = (props) => 
  <Modal 
    className='modal--aside'
    show={props.show} 
    cancel={props.cancel} 
    iconWidth={props.iconWidth}
    icon={props.icon}
    accept={props.accept}
    acceptText={props.acceptText}
    children={props.children}
  />;

export default hideFromRoutes(AsideModal, ['/error', '/confirmacion']);
