import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { routeChanged } from '../store/globals';

class LocalRedirect extends Component {
  
  componentDidMount () {
    const to = typeof this.props.to === 'string' ? this.props.to : this.props.to.pathname;
    this.props.routeChanged(to);
    this.props.history.push(this.props.to);
  }

  render () {
    return null;
  }
};

export default withRouter(connect(null, { routeChanged })(LocalRedirect));
