import React, { Fragment, memo } from 'react';


const PaymentSubmitButton = (props) => {
  const { isMobile, wSize, isPaymentLoading, active, valid } = props;
  let button;
  let headerButton;
  let cssClass = '';
  let headerCssClass = '';

  if (active === 'paypal_tickets') {
    button = <figure>
      <img src={require('../static/images/icons/logotipo-paypal-pagos.jpg')} alt="Cinépolis - Pague con PayPal" />
    </figure>;
    headerButton = <figure>
      <img src={require('../static/images/icons/logotipo-paypal-pagos.jpg')} alt="Cinépolis - Pague con PayPal" />
    </figure>;
    cssClass = 'btn--img';
    headerCssClass = 'btn--img btn--img-header';
  } 
  else if(active === 'kueski_tickets'){
    button = <figure>
      <img src={require('../static/images/icons/icon_kueski.png')} alt="Cinépolis - Pague con Kueski" />
    </figure>;
    headerButton = <figure>
      <img src={require('../static/images/icons/icon_kueski.png')} alt="Cinépolis - Pague con Kueski" />
    </figure>;
    cssClass = 'btn--img kueski-btn';
    headerCssClass = 'btn--img btn--img-header kueski-header';
  }
  else {
    button = <Fragment>
      {(active === 'spreedly_tickets' || active === 'c2p_tickets' ? 'PROCEDER AL ' : 'REALIZAR')}
      {(active === 'spreedly_tickets' || active === 'c2p_tickets' || active === 'cinecash' || active === 'loyalty_tickets' || active === 'price-zero') && ' PAGO'}
      {active === 'unpaid_booking' && ' RESERVACIÓN'}
    </Fragment>;
    cssClass = 'btn--primary';
    if (isMobile) {
      headerButton = <Fragment>
        {(active === 'spreedly_tickets' || active === 'c2p_tickets' || active === 'cinecash' || active === 'loyalty_tickets' || active === null || active === 'price-zero') && ' PAGAR'}
        {active === 'unpaid_booking' && ' RESERVAR'}
      </Fragment>;
      headerCssClass = 'btn--border btn--next';
    }
  }

  return <Fragment>
      <button type="submit" className={`btn ${cssClass}`} disabled={valid && !isPaymentLoading ? false : 'disabled'}>
        {button}
      </button>
      {isMobile && <button type="submit" className={`btn ${headerCssClass}`} disabled={valid && !isPaymentLoading ? false : 'disabled'}>
        {headerButton}
      </button>}
    </Fragment>;
};

export default memo(PaymentSubmitButton);
