import React from 'react';
import { PropTypes } from 'prop-types';

import hideFromRoutes from '../hoc/hideFromRoutes';

const PromocardButton = ({ toggleCards, hasFolio, countryCode }) => (
  <button type="button" className="btn btn--img ticket-promo" onClick={toggleCards} aria-label="Tengo tarjeta o promoción">
    <figure>
      {
        countryCode === 'CL' && !hasFolio ?
        <div className="btn ticket-promo-cl">
          <div className="col-rut-1">
            <img src={require('../static/images/star_rut_cl.png')} alt="" />
          </div>
          <div className="col-rut-2">
            TENGO RUT O<br/>PROMOCIÓN
          </div>
        </div> :
        <img src={require(!hasFolio ? '../static/images/ticket-promo.png' : '../static/images/promo-button-has-folio.png')} alt="" />
      }
    </figure>
  </button>
);

PromocardButton.propTypes = {
  toggleCards: PropTypes.func
};

export default hideFromRoutes(
  PromocardButton, [
    '/error', 
    '/confirmacion', 
    '/', 
    '/asientos', 
    '/pago',
    '/pago/paypal/callback'
  ]
);
