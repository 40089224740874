import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { routeChanged } from '../store/globals';


const Return = (props) => {

  const routeChanged = () => {
    const to = typeof props.to === 'string' ? props.to : props.to.pathname;
    props.routeChanged(to);
    props.history.replace(to);
  };

  return (
    <div className="return">
      <div className="container container--mid align-return">
        <button type="button" className="text return__link" onClick={routeChanged}>Regresar</button>
        <button type="button" className="btn btn--border btn--next btn--return-header" onClick={routeChanged}>REGRESAR</button>
      </div>
    </div>
  );
}

export default withRouter(connect(
  null, 
  { routeChanged }
)(Return));
