import React, { Component, Fragment } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';

import Input from './Input';
import PaymentMethod from './PaymentMethod';
import CardPin from './CardPin';
import CardPinModal from './CardPinModal';

import { CinepolisLogoIcon } from './Icons';


class PaymentClubCinepolis extends Component {

  constructor(props) {    
    super(props);
    
    let initialState = {
      loyaltyNumber:''
    };

    if (this.props.login.loginResponse !== null) {
      const { card_number } = this.props.login.loginResponse;
      initialState = {
        loyaltyNumber: card_number
      };
    }

    this.state = initialState;
  }

  onInputChange = (name, value, valid) => {
    this.setState({ 
      loyaltyNumber: value
    });
    this.props.onInputChange(name, value, valid);
  }

  render () {
    return (
      <Fragment>
        <PaymentMethod active={this.props.active} className="welcome">
          <hgroup>
            <h3 className="title">Bienvenido a <figure className="cc-image margin-top-logo" aria-label="Club Cinépolis"><CinepolisLogoIcon /></figure></h3>
            <h3 className="title dark-blue bold">Ingresa tu número</h3>
          </hgroup>

          <Input
            name="card_cc_number"
            id="card_cc_number"
            label="Tarjeta Club Cinépolis®"
            value={this.state.loyaltyNumber}
            onChange={this.onInputChange}
            validations={['numeric', 'minLength:16', 'maxLength:16']}
            pattern="**** **** **** ****"
          />

          <div className="payment__pin">
            <CardPin onChangePin={this.props.onInputChange} text="Introduce tu PIN para autorizar el pago" name="cc_pin" section="payments" cardNumber={this.state.loyaltyNumber} />
          </div>
        </PaymentMethod>

        { this.props.modal !== null && this.props.modal.type === 'pin-main' && <CardPinModal /> }
      </Fragment>
    );
  }
}

PaymentClubCinepolis.propTypes = {
  active: PropTypes.bool,
  onInputChange: PropTypes.func
};

export default connect(({ login }) => ({ login }))(PaymentClubCinepolis);
