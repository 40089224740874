import React from 'react';
import { PropTypes } from 'prop-types';

const SeatList = (props) => (
  <ul className={`nostyle seats__row ${props.className} ${props.isMix ? 'seats__isMix' : ''}`}>
    {props.children}
  </ul>
);

SeatList.defaultProps = {
  className: ''
};

SeatList.propTypes = {
  className: PropTypes.string,
  isMix: PropTypes.any
};

export default SeatList;
