import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { requestSeats, selectSeat, showSeatMessage, removeSeatMessage, requestSetSelectedSeats, deSelectSeat, hideModal, hideWizardModal, showWizardModal, clearSelectedSeatsResponse} from '../store/seats';

import { showModal } from "../store/ui";

import { setSelectedSeats, SET_SELECTED_SEATS } from '../store/globals';

import { removeSessionId } from '../store/payments';

import Return from '../components/Return';
import SeatList from '../components/SeatList';
import Seat from '../components/Seat';
import SeatMessage from '../components/SeatMessage';
import Loader from '../components/Loader';
import Movie from '../components/Movie';
import LocalRedirect from '../components/LocalRedirect';
import TagManager from 'react-gtm-module';
import Modal from '../components/Modal'
import WizardModal from '../components/WizardModal'
import { CautionIcon } from '../components/Icons'
import '../static/css/modal-utils.css';
import htmlParse from 'html-react-parser'; 
import { formatPriceTwoDecimals } from '../helpers';

const COLORS = {
  blue: '#4781FF',
  blueLight: '#5495D2',
  gray: '#7c7c7c',
  black: '#1c1c1b',
  yellow: '#ffc526',
  red: '#f64e4e',
  purple: '#8234cf'
};

const COLORS_EXTRA = [
  '#35CBCC',
  '#D3168F',
  '#08285b',
  '#4FC974',
  '#3471C4',
  '#795548',
  '#00B398',
  '#009688',
  '#8C4799',
  '#F65275',
  '#F0B323',
  '#77C5D5',
  '#8234CF',
];

const SEATS_AREA_COLORS = [
  {
    description : "general",
    colorHEX: '#4781FF'
  },
  {
    description : "preferente",
    colorHEX: '#8234cf'
  },
  {
    description : "puff doble",
    colorHEX: '#8234CF'
  },
  {
    description : "camastro",
    colorHEX: '#35CBCC'
  },
  {
    description : "puff zen",
    colorHEX: '#D3168F'
  },
  {
    description : "puff mediano",
    colorHEX: '#D3168F'
  },
  {
    description : "palco",
    colorHEX: '#08285b'
  },
  {
    description : "puff familiar",
    colorHEX: '#4FC974'
  },
  {
    description : "pluss",
    colorHEX: '#3471C4'
  },
  {
    description : "lounge",
    colorHEX: '#8234cf'
  },
  {
    description : "codigo 1",
    colorHEX: '#8234CF'
  },
  {
    description : "codigo 2",
    colorHEX: '#8234CF'
  },
  {
    description : "codigo 3",
    colorHEX: '#8234CF'
  },
  {
    description : "codigo 4",
    colorHEX: '#8234CF'
  },
  {
    description : "código 1",
    colorHEX: '#8234CF'
  },
  {
    description : "código 2",
    colorHEX: '#8234CF'
  },
  {
    description : "código 3",
    colorHEX: '#8234CF'
  },
  {
    description : "código 4",
    colorHEX: '#8234CF'
  },
  {
    description : "butaca dúo",
    colorHEX: '#D3168F'
  },
  {
    description : "butaca duo",
    colorHEX: '#D3168F'
  },
];

class Seats extends Component {
  state = {
    goToPayment: false,
    showWizardModal: true,
    aAreaCategoryCodesColors: []
  }

  componentDidMount() {
    const { showtimeVistaId, cinemaVistaId, sessionId, selectedSeats, countryCode } = this.props;
    this.props.clearSelectedSeatsResponse()
    if (this.props.seatsAreas.length === 0) {
      this.props.requestSeats(cinemaVistaId, showtimeVistaId, sessionId, this.props.selectedTickets, selectedSeats.length === 0, countryCode);
    } else {
      this.reloadSeatsMap()
    }
    this.selectedCategoryCodes = new Set(this.props.selectedTickets.map(ticket => ticket.area_category_code));

    let seatsTagManagerPageView;

    if(this.props.isMX) {
      seatsTagManagerPageView = {
        dataLayer: {
          event: 'page_view',
          channel: {
            type: 'web',
            subtype: 'legacy',
            language: 'ES',
            country: 'MX'
          }
        }
      }
    } else {
      seatsTagManagerPageView = {
        dataLayer: {
          item_name: this.props.showtime.movie.name,
          event: 'page_view',
          path: '/visSelectSeats',
          times: new Date().toLocaleTimeString('es-MX', { hour12: false })
        }
      }

      // This event was replaced in MX for 'select_seat' one
      const seatsTagManagerArgs = {
        dataLayer: {
          item_name: this.props.showtime.movie.name,
          event: 'asientos',
          times: new Date().toLocaleTimeString('es-MX', { hour12: false }),
          items: [{
            step: 2,
            action: "checkout"
          }]
        }
      }

      if(countryCode === 'CL') {
        seatsTagManagerArgs.dataLayer.items[0].item_name = this.props.showtime.movie.name;
        delete seatsTagManagerArgs.dataLayer.item_name;
      }

      TagManager.dataLayer(seatsTagManagerArgs);
    }

    TagManager.dataLayer(seatsTagManagerPageView);
    this.props.showWizardModal()
  }

  componentDidUpdate() {
    if(this.state.reloadSeats) {
      this.reloadSeatsMap()
    }
  }

  reloadSeatsMap() {
    const { showtimeVistaId, cinemaVistaId, selectedSeats, sessionId, countryCode, cinema_type, screen } = this.props;
    this.props.requestSeats(cinemaVistaId, showtimeVistaId, sessionId, this.props.selectedTickets, false, countryCode);
    this.setState({ reloadSeats: false });
  }
  
  componentDidUpdate() {
    if(this.state.reloadSeats) {
      this.reloadSeatsMap()
    }
  }
  reloadSeatsMap() {
    const { showtimeVistaId, cinemaVistaId, selectedSeats, sessionId, countryCode, cinema_type, screen } = this.props;
    this.props.requestSeats(cinemaVistaId, showtimeVistaId, sessionId, this.props.selectedTickets, false, countryCode);
    this.setState({ reloadSeats: false });
  }

  isJunior() {
    const { showtime, tickets } = this.props;

    if(tickets.areas && tickets.areas.length > 1){
      return tickets.areas.some(cat => cat.name.toLowerCase().indexOf('junior') >= 0);
    }else if(showtime.movie.format === "SJ ESP" ){
      return true;
    }else{
      return false;
    }
  }

  getSeatKey(rowIndex, columnIndex, area_number) {
    // Key is the letter + number of the seat, ex. G11
    try {
      const { seatsLayout } = this.props
      let result = '';

      for (let letter in seatsLayout) {
        const foundSeat = seatsLayout[letter].find(elem => {
          return (
          rowIndex === elem.position.row_index && 
          columnIndex === elem.position.column_index && 
          area_number === elem.position.area_number
        )});
  
        if (foundSeat) {
          result = `${letter}${foundSeat.id}`;
          break;
        }
      }

      return result;
    } catch {
      return null
    }
  }

  handleSelectedSeat = (selectedSeat, status, color) => {
    if (COLORS.yellow === color) {
      this.props.deSelectSeat(selectedSeat, status);
      return;
    }
    if (status === 'Special') {
      this.props.showSeatMessage();
    }
    if ((status === 'Sold' || status === 'Reserved' || status === 'House' || status === 'Broken') ||
      !this.selectedCategoryCodes.has(selectedSeat.area_category_code)) {
        this.props.showModal({ type: 'error seats', position: 'main' });
      return;
    }

    if(this.props.isMX) {
      const { row_index, column_index } = selectedSeat
      const seatKey = this.getSeatKey(row_index, column_index, selectedSeat.area_number)

      const selectSeatTagManagerArgs = {
        dataLayer: {
          event: 'select_seat',
          channel: {
            module: "seats",
            component: 'seat_selector',
            element: seatKey,
            action: "select_seat"
          }
        }
      }  

      TagManager.dataLayer(selectSeatTagManagerArgs);
    }


    this.props.selectSeat(selectedSeat);
  };

  handleSelectedSeats = () => {
    const { showtimeVistaId, cinemaVistaId, sessionId, selectedSeats, countryCode, selectedTickets, seatsAreas } = this.props;
    if(this.props.isMX) {
      const items = selectedSeats.map((selectedArea) => {
        const areaIndex = seatsAreas.findIndex(area => area.area_category_code === selectedArea.area_category_code)
        const selectedSeatsKeys = selectedArea.selected_seats.map(seat => this.getSeatKey(seat.row_index, seat.column_index, seat.area_number))
        const areaName = seatsAreas[areaIndex].description
        const areaTickets = selectedTickets.filter(ticket => ticket.area_category_code === selectedArea.area_category_code)
        const areaPrice = areaTickets.reduce((acc, ticket) => {
          const ticketPrice = ticket.price * ticket.quantity
          return acc + ticketPrice
        }, 0)
        const isTradicional = areaName.toUpperCase().includes("SALA") || areaName.toUpperCase().includes("GENERAL") 

        return {
          item_id: "seat",
          item_category: "seats",
          item_category2: isTradicional ? "TRADICIONAL" : areaName.toUpperCase(),
          item_category3: selectedSeatsKeys,
          price: areaPrice,
          quantity: selectedSeatsKeys?.length
        }
      })

      const addToCartTagManagerArgs = {
        dataLayer: {
          event: "add_to_cart",
          channel: {
            module: "seats",
            component: "confirmation_seat",
            element: "continue_button",
            action: "add_seats",
          },
          transaction: {
            total_amount: this.props.totalPrice
          },
          items
        }
      }

      TagManager.dataLayer(addToCartTagManagerArgs)
    }
    this.setState({ goToPayment: true });
    this.props.removeSessionId();
    this.props.requestSetSelectedSeats(cinemaVistaId, showtimeVistaId, sessionId, selectedSeats, countryCode);
  }

  handleCloseSeatMessage = () => { this.props.removeSeatMessage(); }

  renderSeats() {
    const { selectedSeats, seatsLayout, maxColumnCount, globalError } = this.props;

    if (globalError !== null && globalError.status_code > 0) return <LocalRedirect to={{ pathname: '/error', state: { message: globalError.message } }} />;

    const seatWidth = (100 / (maxColumnCount + 1) - (window.innerWidth < 768 ? 0.38 : 0.18));

    //console.time('render layout');
    const renderLayout = Object.keys(seatsLayout).map(letter => {
      const rows = seatsLayout[letter];
      const seatLounge = "LOUNGE";
      const seatDuo = "Butaca Duo";
      const seatsType = new Set(rows.filter(s => {
        return s?.description
      }).map(s => {
        return s?.description
      }))

      const seatsIndexToSpread = (seatsType.has(seatLounge) || seatsType.has(seatDuo)) 
      ? rows.map((s,i) => s?.description ? i-1 : NaN)
      : []

      const indexDoubleSeat = [...seatsIndexToSpread]

      const initialSevenAreNoSeat = rows.slice(0, 7).every(item => item.status === "noSeat");
      
      return (
        <SeatList key={letter} isMix={initialSevenAreNoSeat}>
          <Seat letter={letter} width={`${seatWidth}%`} />
          {
            
            rows.map((seat, index) => {
              let color = COLORS.blueLight;
              let widthSeat = seatWidth;
              if (seat.status !== 'noSeat') {

                if (seatsType.has(seatLounge)) widthSeat = 10;
                if (seatsType.has(seatDuo)) widthSeat *= 2;

                switch (seat.status) {
                  case "Sold":
                  case "Reserved":
                      color = COLORS.black;
                  break;

                  case "Broken":
                  case "House":
                    color = COLORS.red;
                  break;

                  case "Special":
                    color = COLORS.gray;
                  break;
                
                  default:
                  try {
                      let areaDescription = seat.description.toLowerCase();
                      let colorDinamic = '';
                      if ( this.props.countryCode !== 'MX' || this.props.countryCode !== null ) {

                        let areaCategoryCode = seat.position.area_category_code;
                        if(areaCategoryCode !== '0000000001' || this.props.countryCode === 'CL') {

                          let descriptionDinamic = areaDescription;
                          for (const iterator of this.props.tickets.areas) {
                            if(iterator.code === areaCategoryCode) {
                              descriptionDinamic =  iterator.name;
                            }
                          }
                          const regexJr = /\bj\w*r\b/;
                          descriptionDinamic = descriptionDinamic.toLowerCase().replace(regexJr, '').trim();
                          
                          if(descriptionDinamic === 'tradicional') {
                            
                            colorDinamic = COLORS.blueLight;

                          } else if (descriptionDinamic === 'preferente' || descriptionDinamic === 'vip') {
                            
                            colorDinamic = COLORS.purple;

                          } else {
                            let indexColorExtra = this.state.aAreaCategoryCodesColors.findIndex(element => element === areaCategoryCode);
                            if(indexColorExtra === -1) {
                              indexColorExtra = this.state.aAreaCategoryCodesColors.push(areaCategoryCode) - 1;
                            }
                            
                            colorDinamic = COLORS_EXTRA[indexColorExtra];
                          }

                        }

                      }

                      if(colorDinamic === '') {
                        if (areaDescription) {
                          let area = SEATS_AREA_COLORS.find(x => x.description.toLowerCase() === areaDescription.toLowerCase()); 
                          if (area) {
                            if (area.description === "general"){
                              color = this.isJunior() ? area.colorHEX : COLORS.blueLight;
                            }else{
                              color = area.colorHEX;
                            }
                          }
                        }
                      } else {
                        color = colorDinamic;
                      }
                      if (seatsType.has(seatLounge)) {
                        color = COLORS.purple;
                      }   
                      if (this.props.countryCode === "PE" && seat.position.area_category_code === "0000000002") {
                        color = COLORS.purple;
                      }
                  } catch (error) {
                    console.log(error);
                  }
                    break;
                }

                for (let i = 0; i < selectedSeats.length; i += 1) {
                  const selectedElem = selectedSeats[i];
                  for (let j = 0; j < selectedElem.selected_seats.length; j += 1) {
                    if (selectedElem.selected_seats[j].row_index === seat.position.row_index
                      && selectedElem.selected_seats[j].column_index === seat.position.column_index
                      && selectedElem.selected_seats[j].area_category_code == seat.position.area_category_code ) {
                      color = COLORS.yellow;
                    }
                  }
                }

                const typeSeat = 
                  seatsType.has(seatLounge) ? "lounge" 
                    : (seatsType.has(seatDuo) ? "duo" 
                      : "trad") 

                return (
                  <Seat
                    key={`${letter}-${seat.position.column_index}-${seat.position.row_index}`}
                    id={seat.id}
                    color={color}
                    width={`${widthSeat}%`}
                    physical_name={letter}
                    position={seat.position}
                    status={seat.status}
                    colLetter={letter}
                    onSelected={this.handleSelectedSeat}
                    typeSeat={typeSeat}
                  />
                );
              }
         
              const typeSeat = 
              seatsType.has(seatLounge) ? "lounge" 
                : (seatsType.has(seatDuo) ? "duo" 
                  : "trad") 
               
              return <Seat empty={true} width={`${indexDoubleSeat.includes(index)?0:seatWidth}%`} key={index} typeSeat={typeSeat} isMix={seat.isMix} />;
            })
          }
        </SeatList>
      );
    });
    //console.timeEnd('render layout');

    return renderLayout;
  }

  renderButton() {
    const isMobileOrDesktop = this.props.isMobileOrDesktop;
    const ticketsLength = this.props.selectedSeats.reduce((acc, seatCat) => acc + seatCat.seats_to_allocate, 0);
    const seatsLength = this.props.selectedSeats.reduce((acc, seatCat) => acc + seatCat.selected_seats.length, 0);
    const seatsEqualTickets = seatsLength === ticketsLength;

    return <Fragment>
      <button type="button" disabled={this.props.isSeatsLoading || !seatsEqualTickets ? true : false} onClick={this.handleSelectedSeats} className="btn btn--primary" style={{borderRadius: '30px'}}>
        CONTINUAR
      </button>
      {!isMobileOrDesktop &&
        <button type="button" disabled={this.props.isSeatsLoading || !seatsEqualTickets ? true : false} onClick={this.handleSelectedSeats} className="btn btn--border btn--next">
          CONTINUAR
      </button>}
    </ Fragment>;
  }

  renderModal() {
    return this.props.modalIsVisible ? (
      <Modal
        className="modal--main modal-errors"
        accept={() => {
            this.setState({ reloadSeats: true });
            this.props.hideModal()
          }}
        acceptText="OK, ENTENDIDO"
        title="Asiento no disponible"
        icon={<CautionIcon />}
        iconWidth="21px"
      >
        <p className="text">{this.props.modalMessage}</p>
      </Modal>
    ) : null;
  }

  renderModalWizard() {
    const { showtime } = this.props;

    const alerts = (showtime?.showtimeInfo?.alerts || []).filter(x => x.section == "seats-image").sort(function (a, b) {
      if (a?.priority > b?.priority) {
        return 1;
      }
      if (a?.priority < b?.priority) {
        return -1;
      }
      return 0;
    }).map((x,i)=>{
      return {
        id:i++,
        img: x?.image,
        title: x?.title,
        subtitle: x?.description,
        description: x?.message
      }
    })


    return this.props.modalWizardIsVisible && alerts.length>0 ? (
      <WizardModal
        className="modalWz--main modalWz-errors"
        cancel={this.props.hideWizardModal}
        acceptText="OK, ENTENDIDO"
        title="ATENCIÓN"
        icon={<CautionIcon />}
        iconWidth="21px"
        steps={alerts} 
      >
       
      </WizardModal>
    ) : null;
  }
  render() {
    const { seatsAreas, seatsSelectedResponse, seatsMessage, isMobileOrDesktop, isSeatsLoading, seatsLayout, hasSeats, countryCode } = this.props;
    if (
      (typeof seatsSelectedResponse.status_code !== 'undefined' &&
        seatsSelectedResponse.status_code === 0 &&
        this.props.paymentSessionId === null) || !hasSeats
    ) {
      return <LocalRedirect to="/pago" />;
    }

      let INVALID_DESCRIPTION_SEAT_HEADER = 
      [
        { copy : "GARANTÍA" }, { copy : "GARANTIA" },
        { copy : "DEFAULT" }, { copy : "SALA " }, { copy : "SALA" },
        { copy : "VIP" }, { copy : "HFR" },
        { copy : "4DX" }, { copy : "2D" }, { copy : "3D" },
        { copy : "ORI" }, 
        // { copy : "DIG" },
        { copy : "SUB" }, { copy : "ESP" }, { copy : "ATMOS" },
        { copy : "LED" }, { copy : "SCREENX" },
        { copy : "PLUUS" }, { copy : "XE" }, { copy : "MACRO" },
        { copy : "IMAX" }, {copy : "TRADICIONAL"}
      ];

      function validateAreaDescription(descriptionSeatService){
        try {
          if (descriptionSeatService && typeof(descriptionSeatService) === "string") {

              descriptionSeatService = descriptionSeatService.toUpperCase();

              let invalidaDescriptionTemp = [];
              INVALID_DESCRIPTION_SEAT_HEADER.forEach(item => {
                invalidaDescriptionTemp.push({ copy : item.copy });
                invalidaDescriptionTemp.push({ copy : " " + item.copy });
                invalidaDescriptionTemp.push({ copy : item.copy + " " });
              });
     
              for (let index = 0; index < 50; index++) {
                  let lobby =  { copy : "SALA " + index };
                  invalidaDescriptionTemp.push(lobby);
              }

              let result = invalidaDescriptionTemp.find(desc => descriptionSeatService.indexOf(desc.copy.toUpperCase()) !== -1);

              if (countryCode !== 'MX' || countryCode !== null)
                if(result)
                  if(result.copy == 'SALA ' || result.copy == 'VIP')
                    return true;

              return result === null || result === undefined;
          }
          return false;
        } catch (error) {
          console.log(error);
          return false;
        }
      }

     let textLoader = this.state.goToPayment ? "pago" : "asientos";
     if (isSeatsLoading || seatsLayout.length === 0) return <Loader text={textLoader} />;
     const seatLounge = "lounge";
     const seatDuo = "butaca duo";
     const { tickets } = this.props;

     this.state.aAreaCategoryCodesColors = [];

    return (
      <Fragment>
     
        {isMobileOrDesktop && <Return to="/boletos" />}

        <div className="container container--mid seats">
          {this.props.isMobile && <Movie />}

          <h1 className="title">Selecciona tus asientos</h1>
          <p className="text">Para cambiar tu lugar asignado da clic en el asiento deseado.</p>

          <ul className="nostyle seats__types">
            <Seat HTMLElement='div' color="#ffc526"><span className="text">Selección</span></Seat>
            <Seat HTMLElement='div' color="#1c1c1b"><span className="text">Ocupado</span></Seat>
            <Seat HTMLElement='div' color="#7c7c7c"><span className="text">Silla de ruedas</span></Seat>
            <Seat HTMLElement='div' color="#f64e4e"><span className="text">Deshabilitado</span></Seat>
            {this.isJunior() ? <Seat HTMLElement='div' color="#4781FF"><span className="text">General</span></Seat> 
            : <Seat HTMLElement='div' color="#5495D2"><span className="text">Tradicional</span></Seat> }
            {
              seatsAreas.filter(
                x => !"general".includes(x.description.toLocaleLowerCase())
              ).map(area => {
                try {
                  let areaDescription = area.description.toLowerCase()
                  let areaDescriptionIsValid = validateAreaDescription(area.description);
                  
                  if (areaDescription && areaDescriptionIsValid) {

                    if ( this.props.countryCode !== 'MX' || this.props.countryCode !== null ) {
                      
                      let areaCategoryCode = area.area_category_code;
                      if(areaCategoryCode !== '0000000001') {

                        let indexColorExtra = 0;
                        let descriptionDinamic = areaDescription;

                        for (const iterator of this.props.tickets.areas) {
                          if(iterator.code === areaCategoryCode) {
                            descriptionDinamic =  iterator.name;
                          }
                        }

                        const regexJr = /\bj\w*r\b/;
                        descriptionDinamic = descriptionDinamic.toLowerCase().replace(regexJr, '').trim();

                        if(descriptionDinamic === 'tradicional') {
                          
                          return null;
                        
                        } else if(descriptionDinamic === 'preferente' || descriptionDinamic === 'vip') {

                          indexColorExtra = COLORS_EXTRA.findIndex((element) => (element.toLowerCase() === COLORS.purple.toLowerCase()));

                        } else {
                        
                          indexColorExtra = this.state.aAreaCategoryCodesColors.findIndex(element => element === areaCategoryCode);
                          if(indexColorExtra === -1) {
                            indexColorExtra = this.state.aAreaCategoryCodesColors.push(areaCategoryCode) - 1;
                          } else {
                            return null;
                          }

                        }
                        
                        return <Seat HTMLElement='div'
                                color={COLORS_EXTRA[indexColorExtra]}
                                key={areaDescription}
                                typeSeat={(areaDescription === seatLounge) ? "lounge" : (areaDescription === seatDuo ? "duo" : "")}
                                typeSection="pointer" >
                                <span className="text">{descriptionDinamic[0].toUpperCase() + descriptionDinamic.slice(1).toLowerCase()}</span>
                              </Seat>;

                      }

                    }

                    let areaColor = SEATS_AREA_COLORS.find(x => x.description.toLowerCase() === areaDescription.toLowerCase());
                    if (areaColor) {
                      const typeSeat = (areaDescription === seatLounge) ? "lounge" : (areaDescription === seatDuo ? "duo" : "")
                      return <Seat HTMLElement='div' 
                      color={(areaDescription == seatLounge)? COLORS.purple: areaColor.colorHEX} 
                      key={areaDescription} 
                      typeSeat={typeSeat}
                      typeSection="pointer" >
                      <span className="text">{areaDescription[0].toUpperCase() + areaDescription.slice(1)}</span>
                    </Seat>;
                    }
                  }
                } catch (error) {
                  console.log(error);
                }
                return null;
              })
            }
          </ul>

          <div className="seats__select">
            {seatsMessage ?
              <SeatMessage onCloseMessage={this.handleCloseSeatMessage}>
                <svg width="34" height="40" viewBox="0 0 34 40">
                  <g fill="#0C5BA1" fillRule="nonzero">
                    <path d="M13.012 36.957c-5.554 0-10.074-4.496-10.074-10.022 0-3 1.334-5.694 3.44-7.533l-.633-3.2C2.282 18.53 0 22.471 0 26.934 0 34.073 5.837 39.88 13.012 39.88c4.937 0 9.24-2.749 11.445-6.789l-1.82-3.196a10.086 10.086 0 0 1-9.625 7.062z" />
                    <path d="M31.57 31.914l-1.932.96-5.51-9.685a1.68 1.68 0 0 0-1.462-.848H11.665l-.413-2.088h8.056c.928 0 1.68-.747 1.68-1.67 0-.922-.752-1.67-1.68-1.67h-8.717l-1.178-5.96A1.678 1.678 0 0 0 7.44 9.636a1.67 1.67 0 0 0-1.323 1.962l2.519 12.736a1.677 1.677 0 0 0 1.647 1.348h11.404l5.814 10.219a1.68 1.68 0 0 0 2.212.67l3.357-1.67a1.666 1.666 0 0 0 .751-2.24 1.683 1.683 0 0 0-2.252-.747z" />
                    <ellipse cx="9.13" cy="4.072" rx="4.093" ry="4.072" />
                  </g>
                </svg>
              {countryCode == "CO" ? <p className="text">Este es un espacio para personas con discapacidad motriz. En caso de haber seleccionado por error estos asientos, será reubicado en otro asiento o función disponible. Los asientos adyacentes están designados para un acompañante. Consulta nuestros términos y condiciones.</p>
                : <p className="text">Este es un asiento para personas con discapacidad motriz. En caso de haber seleccionado por error estos asientos, será reubicado en otro asiento o función disponible. Los asientos adyacentes están designados para un acompañante. Consulta nuestros términos y condiciones.</p>}
              </SeatMessage>
              : null
            }
            <div className={`${seatsMessage ? 'blur-overlay' : ''}`}>

              <div className="screen"></div>

              <div className="seats__grid" style={{ padding: "0 0.75rem" }}>
                {this.renderSeats()}
              </div>
            </div>
          </div>

          {!(isSeatsLoading || seatsLayout.length === 0) && this.renderButton()}

          {!isMobileOrDesktop && <Return to="/boletos" />}
        </div>
        {this.renderModal()}
        {this.renderModalWizard()}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ seats, globals, tickets, payments, ui, showtime }) => {
  const { selected: selectedSeats, isLoading: isSeatsLoading,
    response, maxColumnCount, message: seatsMessage, seatsSelectedResponse, layout: seatsLayout } = seats;

    let message = "";
    if (showtime.showtimeInfo && showtime.showtimeInfo.alerts) {
      const alertMessage = showtime.showtimeInfo.alerts.find(x => x.section == "seats-message");
      if (alertMessage) {
        message = alertMessage.message;  
      }
    }

  return {
    selectedSeats, isSeatsLoading, seatsSelectedResponse,
    seatsMessage, maxColumnCount, seatsLayout,
    seatsAreas: response.seat_layout_data ? response.seat_layout_data.areas : [],
    seatsAreasCategories: response.seat_layout_data ? response.seat_layout_data.area_categories : [],
    showtimeVistaId: globals.showtimeVistaId,
    cinemaVistaId: globals.cinemaVistaId,
    countryCode: globals.countryCode,
    sessionId: globals.sessionId,
    globalError: globals.error,
    selectedTickets: tickets.selectedTickets,
    tickets: tickets.tickets,
    isMobile: ui.isMobile,
    windowSize: ui.size,
    paymentSessionId: payments.sessionId,
    showtime,
    isMobileOrDesktop: (ui.isMobile && ui.size >= 768 && ui.size < 1024) || !ui.isMobile,
    hasSeats: seats.hasSeats,
    modalIsVisible: seats.modalIsVisible,
    modalTitle: seats.modalTitle,
    modalMessage: seats.modalMessage,
    modalWizardIsVisible: seats.modalWizardIsVisible,
    modalAction: seats.modalAction,
    isMX: showtime.settings?.currency.code === 'MXN',
    totalPrice: seats.response?.total_value && parseFloat(formatPriceTwoDecimals(seats.response.total_value)),
  }
};

export default connect(
  mapStateToProps, {
  requestSeats, selectSeat, showSeatMessage, removeSeatMessage,
  setSelectedSeats, requestSetSelectedSeats, removeSessionId, deSelectSeat,
  hideModal, hideWizardModal, showWizardModal, showModal,
  clearSelectedSeatsResponse
}
)(Seats);
