import React from 'react';

import hideFromRoutes from '../hoc/hideFromRoutes';
import Step from './Step';

const StepsList = ({ route }) => {
  const stepsNames = ['Boletos', 'Asientos', 'Pago'];
  let step = 'one', step_index = 0;

  if (route === '/asientos') {
    step = 'two';
    step_index = 1;
  } else if (route === '/pago' || route === '/pago/paypal/callback') {
    step = 'three';
    step_index = 2;
  }

  return (
    <header className="tabs">
      <ul className={`nostyle steps steps--${step}`}>
        {stepsNames.map((name, i) => {
          return <Step 
            key={name}
            step={i + 1} 
            name={name} 
            completed={step_index >= i} active={step_index === i}
          />
        })}
      </ul>
    </header>
  );
};

export default hideFromRoutes(StepsList, ['/', '/error', '/confirmacion']);
