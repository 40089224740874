import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import sessionStorage from '../middlewares/sessionStorage';

import showtime from './showtimeInfo';
import payments from './payments';
import ui from './ui';
import tickets from './tickets';
import cards from './cards';
import seats from './seats';
import globals from './globals';
import login from './login';
import locations from './locations'

export default function configureStore(initialState) {
  const appReducer = combineReducers({
    showtime,
    ui,
    tickets,
    cards,
    seats,
    globals,
    payments,
    login,
    locations
  });

  const rootReducer = (state, action) => {
    if (action.type === 'CLEAR_STORE') {
      state = undefined;
    }

    return appReducer(state, action);
  };

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];
  //const isDevelopment = process.env.NODE_ENV === 'development';
  if (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
  }

  return createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(thunk, sessionStorage), ...enhancers)
  );
}
