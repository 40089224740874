import React from 'react';
import TicketType from './TicketType';


const TicketCategory = ({ name, types, quantity, area, isJunior, hasDuoSeatModifier, maxQuantity, currencyCharacter, selected, countryCode }) => {

  const getAreaName = (area, name, types) => {
    if (+area === 1 && !hasDuoSeatModifier) return 'tradicional.png';
    const ticketType = types[0];
    const code = ticketType.type.toLowerCase();

    if(code.indexOf('zen') >= 0) return 'puff-mediano.png';
    if(code.indexOf('mediano') >= 0) return 'puff-mediano.png';
    if(code.indexOf('palco') >= 0) return 'palco-familiar.png';
    if(code.indexOf('camastro') >= 0) return 'camastros.png';
    if(code.indexOf('familiar') >= 0) return 'puff-familiar.png';
    if(code.indexOf('puff') >= 0) return 'puff-doble.png';
    if(code.indexOf('adulto-duo') >= 0 && hasDuoSeatModifier) return 'butaca-duo.png';
    return null
  };

  return (
    <div className="tickets__category">
      {
        (isJunior || hasDuoSeatModifier) && (countryCode === "MX" || !countryCode) &&
        <div className="tickets__category-img">
          {getAreaName(area, name, types) && <img src={require(`../static/images/${getAreaName(area, name, types)}`)} alt={name} />}
        </div>
      }
      <div className="tickets__category-type">
        <h4 className="title2 ticket-title2">
          {
            isJunior && name.toLowerCase().indexOf('tradicional') < 0
              ? <span><img src={require('../static/images/icons/logo-salajr.png')} alt="JUNIOR" />{name.replace(/junior/i, '')}</span>
              : name
          }
        </h4>
        <ul className="nostyle ticket__list">
          { types.map(ticketType => {
            return <TicketType
              key={`${area}-${ticketType.description}-${ticketType.folio}`}
              id={`${area}-${ticketType.description}-${ticketType.folio}`}
              name={ticketType.description}
              price={ticketType.price}
              code={ticketType.code}
              area={area}
              next={quantity < maxQuantity && (selected[`${area}-${ticketType.description}-${ticketType.folio}`] || 0) < ticketType.max_quantity}
              prev={quantity > 0}
              maxQuantity={maxQuantity}
              currencyCharacter={currencyCharacter}
              color={ticketType.color}
              message={ticketType.message}
              folio={ticketType.folio}
              hopk={ticketType.hopk}
            />
          })}
        </ul>
      </div>
    </div>
  );
};

export default TicketCategory;
