/*
  Constants
*/
import { clearCardResponse } from './cards';

// Add fix on overlay values.

const SHOW_MODAL = 'SHOW_MODAL';
const HIDE_MODAL = 'HIDE_MODAL';
const SHOW_CARDS = 'SHOW_CARDS';
const RESIZE_WINDOW = 'RESIZE_WINDOW';
const OPEN_CARD = 'OPEN_CARD';
const CLOSE_CARD = 'CLOSE_CARD';
const TOGGLE_CARDS_LIST = 'TOGGLE_CARDS_LIST';
const TOGGLE_CARDS_LIST_CLOSE = 'TOGGLE_CARDS_LIST_CLOSE';
const CONTINUE_TOGGLE_CARDS_LIST = 'CONTINUE_TOGGLE_CARDS_LIST';

/*
  Action creators
*/
export const showModal = (obj) => ({ type: SHOW_MODAL, obj });
export const hideModal = () => (dispatch) => {
  if(localStorage.getItem('isFreeSeats'))
    localStorage.removeItem('isFreeSeats')
  dispatch({ type: HIDE_MODAL, metadata: { local: 'save' } });
  dispatch(clearCardResponse());
};
export const showCards = () => ({ type: SHOW_CARDS });
export const resizing = (isMobile, windowSize) => ({ type: RESIZE_WINDOW, isMobile, windowSize, metadata: { local: 'save' } });
export const openCard = (cardName) => ({ type: OPEN_CARD, cardName });
export const closeCard = () => ({ type: CLOSE_CARD });
export const toggleCardsList = () => ({ type: TOGGLE_CARDS_LIST });
export const toggleCardsListClose = () => ({ type: TOGGLE_CARDS_LIST_CLOSE });
export const continueToggle = () => ({ type: CONTINUE_TOGGLE_CARDS_LIST });

/*
  Reducer
*/
const initialState = {
  modal: null,
  cardsShown: false,
  showCardsList: false,
  isMobile: window.innerWidth < 1024,
  size: window.innerWidth,
  overlay: false,
  activeCard: null,
  continueToggleCardsLis: true
};
export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      return { ...state, modal: action.obj };
    case HIDE_MODAL:
      return { ...state, modal: null };
    case SHOW_CARDS:
      return { ...state, cardsShown: true };
    case RESIZE_WINDOW:
      return { ...state, isMobile: action.isMobile, size: action.windowSize };
    case OPEN_CARD:
      return { ...state, activeCard: action.cardName };
    case CLOSE_CARD:
      return { ...state, activeCard: null };
    case TOGGLE_CARDS_LIST:
      return { ...state, showCardsList: !state.showCardsList, overlay: !state.overlay };
    case TOGGLE_CARDS_LIST_CLOSE:
      return { ...state, showCardsList: false, overlay: false, continueToggleCardsLis: false };
    case CONTINUE_TOGGLE_CARDS_LIST:
      return { ...state, continueToggleCardsLis: true };
    default:
      return state;
  }
};
