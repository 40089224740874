import React, { Component } from 'react';
import { connect } from 'react-redux';
import preventBack from '../hoc/preventBack';
import { resetSessionData, resetTime } from '../store/globals';
import localSessionStorage from '../services/localSessionStorage';
import { check_os } from "../helpers";
import TagManager from 'react-gtm-module';
import QRCode from "qrcode.react";
import { selectedSeatsHumanSelector } from '../selectors/index';
import { getAppleWallet, clearPaymentResponse } from "../store/payments";
import SonicBrand from '../components/SonicBrand';
import { formatPriceTwoDecimals } from '../helpers';
import ReactPixel from 'react-facebook-pixel';

const advancedMatchingFBPix = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const optionsFBPix = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};
const disneyMetaPixelID = '434401772736666';

class PaymentSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countryCodeRoute: this.props.countryCode,
      handleDisplayForm: this.props.handleDisplayForm
    }    
    this.validateTgm = false;
  }
  componentDidMount() {
    ReactPixel.init(disneyMetaPixelID, advancedMatchingFBPix, optionsFBPix);

    if (this.props.paymentResponse && typeof this.props.paymentResponse.tickets_confirmation === 'undefined') {      
        this.props.history.push('/error');
    }
    if (this.validateTgm === false) {
      const { selectedTickets, tickets, showtime, ticket, paymentResponse, seatsResponse, cityName, payments, textselectedSeats  } = this.props;
      let products = [];
      let i = 1;
      let caterogories = '';
      let ticketFacility = '';
      for (let item of ticket.selectedTickets) {
        let category = '';
        let price = '';
        for (let areaItem of ticket.tickets.areas) {
          let area = areaItem.tickets.find(c => c.code === item.ticket_type_code);
          if (typeof area !== 'undefined') {
            caterogories += caterogories !== '' ? ', ' + (typeof area.description !== 'undefined' ? area.description : typeof area.name !== 'undefined' ? area.name : '') : '';
            category = area.description;
            price = parseFloat(area.price.toString().slice(0, -2) + "." + area.price.toString().substr(-2));
            ticketFacility= areaItem.name;
          }
        }

        let product = {
          item_id: ''+showtime.movie.id,//i.toString() + ticket.tickets.session_id + showtime.showtimes.datetime.split('T')[1].replace(/:/g, ""),
          item_name: showtime.movie.original_name,
          item_brand: showtime.movie.distributor, //distribuidora 
          item_category: "boleto",
          item_category2: category,
          item_category3: textselectedSeats,
          item_language: showtime.movie.format,
          facility: ticketFacility,
          price: price,
          quantity: item.quantity,
        };

        if(this.props.countryCode === 'CL') {
          const distributor = showtime.movie.distributor;
          const price = product.price;
  
          product.item_variant = showtime.cinema.name;
          product.price = !isNaN(price) ? parseFloat(price) : 0;
          product.item_brand = distributor === '' ? 'NA' : (distributor ?? 'NA');
        }
        
        products.push(product);
        i++;
      }
      
      let confirmationTagManagerArgs;
      let paymentSuccessTagManagerPageView;
      if(this.props.isMX) {
        try {
          const ticketDetails = {} 
  
          for(const { tickets: ticketsAreas } of tickets.areas) {
            ticketsAreas.forEach(ticket => {
              ticketDetails[ticket.description] = {
                price: +formatPriceTwoDecimals(ticket.price),
                quantity: selectedTickets.find(selectedTicket => selectedTicket.hopk === ticket.hopk)?.quantity || 0 
              }
            })
          }
  
          paymentSuccessTagManagerPageView = {
            dataLayer: {
              event: 'page_view',
              channel: {
                type: 'web',
                subtype: 'legacy',
                language: 'ES',
                country: 'MX'
              }
            }
          }
  
          confirmationTagManagerArgs = {
            dataLayer: {
              event: "purchase",
              showtime: {
                id: this.props.sessionId,
                date: this.props.movieDate,
                complex: this.props.cinemaVistaId,
                facility: this.props.movieFacility,
                language: this.props.movieLanguage,
                city: this.props.cityName,
                country: "MX"
              },
              movie: {
                id: this.props.movideId,
                name: this.props.movieName,
                rating: this.props.movieRating,
                genre: this.props.movieGenre.split(",").map(gen => gen.trim()),
                distributor: this.props.movieDistributor,
                length: +this.props.movieDuration.replaceAll(/\D/g, "")
              },
              channel: {
                type: "web",
                subtype: "legacy",
                language: "ES",
                country: "MX",
                module: "confirmation",
              },
              transaction: {
                total_tickets: selectedTickets.reduce((acc, ticket) => acc + ticket.quantity, 0),
                ticket_details: ticketDetails,
                total_amount: +formatPriceTwoDecimals(seatsResponse.total_value),
                currency: "MXN"
              },
              items: products.map(product => ({
                item_id: product.item_id,
                item_name: product.item_name,
                item_category: "ticket",
                item_category2: product.item_category2,
                quantity: product.quantity
              }))
            }
          }
        } catch {
          paymentSuccessTagManagerPageView = {}
          confirmationTagManagerArgs = {}
        }
      } else {
        paymentSuccessTagManagerPageView = {
          dataLayer: {
            event: 'page_view',
            path: '/visConfirmation',
            item_name: this.props.showtime.movie.name,
            times: new Date().toLocaleTimeString('es-MX', { hour12: false })
          }
        };
        let metodo = localStorage.getItem('metodoPago');
        let metodoPago = metodo.replace(/["/]/g, '');
        localStorage.setItem("foliosPromocionCL", "false")
        confirmationTagManagerArgs = {
          dataLayer: {
            event: 'purchase',
            ecommerce: {
              city_name: cityName,
              movie_date: showtime.showtimes.datetime.split('T')[0].replaceAll("-", " "),
              movie_time: showtime.showtimes.datetime.split('T')[1],
              movie_genre: showtime.movie.genre,
              currency: this.props.currencyCode,
              transaction_id: 'T' + Date.now().toString() + paymentResponse.tickets_confirmation.booking_id,
              value: (parseFloat(seatsResponse.total_value.toString().slice(0, -2) + "." + seatsResponse.total_value.toString().substr(-2))),
              coupon: this.props.folio?.name === '' ? 'NA' : (this.props.folio?.name ?? 'NA'),
              payment_type: payments.type === '' ? 'NA' : (payments.type ? 'NA' : metodoPago),
              language: showtime.movie.language,
              affiliation: 'Canal Web',
              item_variant: showtime.cinema.name,
              times: new Date().toLocaleTimeString('es-MX', { hour12: false }),
              items: [...products],
            }
          }
        }
      }
      console.log(this.props.countryCode, "entro linea 189");
      if(this.props.countryCode === 'CL') {
        paymentSuccessTagManagerPageView.dataLayer.items = [{item_name: this.props.showtime.movie.name}];
        delete paymentSuccessTagManagerPageView.dataLayer.item_name;
        
        const value = (seatsResponse.total_value.toString().slice(0, -2) + "." + seatsResponse.total_value.toString().substr(-2));
        delete confirmationTagManagerArgs.dataLayer.ecommerce.item_variant;
        confirmationTagManagerArgs.dataLayer.ecommerce.value = !isNaN(value) ? parseFloat(value) : 0;
        confirmationTagManagerArgs.dataLayer.ecommerce.payment_type = localStorage.getItem('payment-type');
        localStorage.removeItem('payment-type');
        
        const dataProductsStorage = JSON.parse( localStorage.getItem('tickets') ) ?? [];
        confirmationTagManagerArgs.dataLayer.ecommerce.items = [...dataProductsStorage];
        console.log(this.props.countryCode, "entro linea 201");
        if (this.props.movieName.toLowerCase().includes("deadpool y wolverine")) {
          console.log(this.props.countryCode, "entro linea 204");
          console.log(ReactPixel);
          ReactPixel.trackSingleCustom(disneyMetaPixelID, 'PageView', {});
          ReactPixel.trackSingleCustom(disneyMetaPixelID, 'Purchase', {
            value: (parseFloat(seatsResponse.total_value.toString().slice(0, -2) + "." + seatsResponse.total_value.toString().substr(-2))), //  Reemplzar con el valor dinamico de 'valor total'.
            currency: this.props.currencyCode, // Reemplzar con el valor dinamico de 'moneda'.
            contents: products.map(product => ({
              id: product.item_id,
              quantity: product.quantity
            })),
            content_type: 'product',
            content_name: this.props.movieName //  Reemplzar con el valor dinamico de 'nombre de la pelicula'.
          }); 
        }
      }
      TagManager.dataLayer(confirmationTagManagerArgs);
      TagManager.dataLayer(paymentSuccessTagManagerPageView);
      this.validateTgm = true;
    }
    this.props.resetTime();

    
  }
  printBookingId = () => {
    const { paymentResponse } = this.props;
    if ((paymentResponse && typeof paymentResponse.status_code !== 'undefined' && paymentResponse.status_code === 0) ||
      (this.statusCode && parseInt(this.statusCode, 10) === 0)) {
      return <div className="success__code">
        {<div className="codigo-qr">
          <QRCode 
            value={paymentResponse.tickets_confirmation
              ? paymentResponse.tickets_confirmation.booking_id
              : this.ticketsConfirmation
            }
            size={this.props.isMobile ? 80 : 250} level="Q" />
        </div>
        }
        {paymentResponse.tickets_confirmation
          ? paymentResponse.tickets_confirmation.booking_id
          : this.ticketsConfirmation
        }
      </div>;
    }
    return null;
  };
  goToHome = () => {
    localSessionStorage.clear();
    this.props.resetSessionData();
  };
  appleWallet = () => {
    var pass = {
      data: {
        booking_id: this.props.paymentResponse.tickets_confirmation.booking_id,
        booking_token: this.props.sessionId,
        cinema_vista_id: this.props.cinemaVistaId,
        passbok_type: 'EventTicket',
        booking_number: this.props.paymentResponse.tickets_confirmation.booking_number,
        credit_card: '',
        platform_version: "1",
        pay_method: "C"
      },
      event_ticket: {
        room: this.props.showtime.showtimes.screen,
        seating: {
          identify: this.props.textselectedSeats
        },
        movie: {
          name: this.props.showtime.movie.original_name,
          image_thumb: this.props.showtime.movie.url_poster,
          image_thumb_retina: this.props.showtime.movie.url_poster
        },
        movie_projection_date: this.props.showtime.showtimes.datetime,
        cinema: {
          name: this.props.showtime.cinema.name,
          latitude: "0",
          longitude: "0"
        }
      }
    }
    const data = this.props.getAppleWallet(pass, this.props.countryCode);
  }
  showSonicBrandMastercard = () => {
   
    const { sonicBrand, countryCode } = this.props;
    const isMastercardPayment = countryCode === 'CL' ?
                                (localStorage.getItem('isMastercard') ?? false) : 
                                (this.props.location?.state?.activeSonicBrand ?? false);
    const haveToShowSonicBrand = sonicBrand?.active_sonic_brand_mastercard ?? false;
    
    if(isMastercardPayment)
      localStorage.removeItem('isMastercard');
    return haveToShowSonicBrand && isMastercardPayment ;
  }
  render() {
    const { isMobile, countryCode } = this.props;
    
    return (
      <div className="success">
        
        {this.showSonicBrandMastercard() && <SonicBrand />}
        
        <div id="payment-success-container">
          <h1 className="title">¡Gracias por tu preferencia!</h1>
          <figure className="success__img">
            {isMobile
              ? <img src={require('../static/images/banner-inseparables.jpg')} alt="Cinépolis Chile - Consiente a todos desde el mismmo lugar" />
              : <img src={require('../static/images/banner-inseparables-horizontal.jpg')} alt="Cinépolis Chile - Consiente a todos desde el mismmo lugar" />
            }
          </figure>
          <p className="text">Tus boletos han sido enviados al correo electrónico que nos proporcionaste.</p>
          { countryCode && countryCode === "GT" ? <p className="text">Su factura será enviada al correo electrónico registrado.</p> : <></>}
          <p className="text">Si lo prefieres, puedes recogerlos con el siguiente código en taquilla.</p>
          {this.printBookingId()}
          {check_os() && <div className="btn-wallet">
            <button className="btn" onClick={this.appleWallet}><img src={require("../static/images/icon-wallet.png")} alt="" /> Agregar a Apple Wallet</button>
          </div>}
          {countryCode && countryCode === "CR" || countryCode ==="PA" ? <button className="btn btn--yellow" onClick={() => this.state.handleDisplayForm(true)}>¿REQUIERE FACTURA?</button> : <></>}
          <a href={
            (this.state.countryCodeRoute === 'CL') ? (
              (document.location.href).includes('sls-pre') ?
                'https://preprod.cinepolischile.cl/' :
                'https://cinepolischile.cl/'
            ) :
            `https://cinepolis.co${this.state.countryCodeRoute !== "CR" ? "m" : ""}${this.state.countryCodeRoute ? `.${this.state.countryCodeRoute}` : ''}`
            }
            onClick={this.goToHome} className="btn btn--primary">IR AL INICIO</a>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ payments, ui, showtime, tickets, seats, globals, locations, cards }) => {
  let cityName = ''
  if (locations && locations.cities) {
    const currentCity = locations.cities.find(city => {
      const hasCurrentCinema = city?.cinemas.find(cinema => cinema.vista_id === showtime.cinema.vista_id)
      return hasCurrentCinema
    })
    cityName = currentCity?.name
  }
  return {
    tickets: tickets.tickets,
    selectedTickets: tickets.selectedTickets,
    paymentResponse: payments.paymentResponse,
    payments,
    isMobile: ui.isMobile,
    showtime,
    sonicBrand: showtime.settings !== null && typeof showtime.settings.others !== 'undefined' ? showtime.settings.others : {},
    countryCode: globals.countryCode,
    ticket: tickets,
    folio: cards.folio,
    seatsResponse: seats.seatsSelectedResponse,
    sessionId: globals.sessionId,
    cinemaVistaId: globals.cinemaVistaId,
    textselectedSeats: selectedSeatsHumanSelector({
      ui,
      payments, globals, tickets, seats, showtime
    }),
    countryCode: globals.countryCode,
    currencyCode: showtime.settings?.currency.code ? showtime.settings.currency.code : 'MXN',
    cityName,
    isMX: showtime.settings?.currency.code === 'MXN',
    movieFacility: showtime.movie.format,
    cinemaApiId: showtime.cinema.id,
    movieLanguage: showtime.movie.language,
    movieName: showtime.movie.name,
    movieRating: showtime.movie.rating,
    movieGenre: showtime.movie.genre,
    movieDistributor: showtime.movie.distributor,
    movieDuration: showtime.movie.length,
    movieId: showtime.movie.id,
    movieDate: showtime.showtimes.datetime
  }
}

export default preventBack(connect(mapStateToProps, { resetSessionData, resetTime, getAppleWallet, clearPaymentResponse })(PaymentSuccess));
