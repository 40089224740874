import React from 'react';
import { PropTypes } from 'prop-types';

const Loader = (props) => (
  <div className="loader">
    <img src={require('../static/images/logoActual.png')} alt="loader" />
    <span>Cargando {props.text}</span>
  </div>
);

Loader.propTypes = {
  text: PropTypes.string
};

export default Loader;
