import React, { Component, Fragment, } from 'react';
import { connect } from 'react-redux';
import CardAddItem from "./CardAddedItem";
import hideFromRoutes from '../hoc/hideFromRoutes';
import { resetSelectedTickets } from '../store/tickets';

class CardAddedList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listFolios: [{ "bin_id": 801, "type": "voucher", "number": "508150301387", "pin": null, "parent_code": "otros", "name": "Otros" },
      { "bin_id": 234, "type": "voucher", "number": "234242342423423432", "pin": null, "parent_code": "otros", "name": "Otros" }
      ]
    };
  }

  render() {
    const { cardList, isDeleteLoading, countryCode } = this.props;

    let updatedCardList = cardList;

    if (countryCode === 'CL') {
      if (cardList.length >= 2) {
        this.props.handleInvalidateCard(cardList[0]);
        this.props.resetSelectedTickets();
        updatedCardList = cardList;
      } else {
        updatedCardList = cardList;
      }
    }

    return (
      <Fragment>
        <div className="container-list-added-items">
          {
            updatedCardList.map((itemAdded, index) => {
              return (
                <CardAddItem
                  key={index}
                  nameFolio={itemAdded.name}
                  typeFolio={itemAdded.type}
                  numberFolio={itemAdded.number}
                  item={itemAdded}
                  handleInvalidateCard={this.props.handleInvalidateCard}
                  code={itemAdded.code}
                />
              )
            })
          }

          {
            (updatedCardList.length != 0 &&
              <div className={`${isDeleteLoading ? 'layer-delete-loading' : 'layer-delete-hide'}`}>
                <div className="layer-circle-loading">
                  <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                </div>
              </div>

              || "")
          }

        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ cards, globals }) => {

  const { countryCode } = globals;

  return {
    cardList: cards.cardList,
    isDeleteLoading: cards.isDeleteLoading,
    countryCode: countryCode,
  };
};

export default hideFromRoutes(
  connect(mapStateToProps, { resetSelectedTickets, })(CardAddedList), [
		'/error',
		'/asientos',
     '/pago',
	  '/confirmacion',
	  '/'
]);
