/*
  Constants
*/
const GET_MOVIEINFO_SUCCESS = 'GET_MOVIEINFO_SUCCESS';
const GET_BENEFITS_SUCCESS = 'GET_BENEFITS_SUCCESS';
const GET_SHOWTIME_INFO_SUCCESS = 'GET_SHOWTIME_INFO_SUCCESS';
const GET_SETTINGS = 'GET_SETTINGS';
const GET_MASTERCARD_BINES = 'GET_MASTERCARD_BINES';
const GET_FOOTER = 'GET_FOOTER';
/*
  Action creators
*/
export const getMovieInfo = (movie, cinema, showtimes) => ({
  type: GET_MOVIEINFO_SUCCESS,
  movie,
  cinema, 
  showtimes
});

export const getBenefits = (benefits) => ({
  type: GET_BENEFITS_SUCCESS,
  benefits,
  metadata: { local:'save' }
});

export const getSettings = (settings) => ({
  type: GET_SETTINGS,
  settings,
  metadata: {local:'save'}
});

export const getPaymentSettings = (paymentSettings) => ({
  type: GET_MASTERCARD_BINES,
  paymentSettings,
  metadata: { local: 'save' }
});


export const getFooter = (footer) => ({
  type: GET_FOOTER,
  footer,
  metadata: {local:'save'}
});

export const getShowtimeInfoSuccess = (showtimeInfo) => ({
  type: GET_SHOWTIME_INFO_SUCCESS,
  showtimeInfo,
  metadata: {local:'save'}
});

/*
  Reducer
*/
const initialState = {
  movie: {
    name: '', 
    url_poster: ''
  },
  cinema: {
    name: ''
  },
  showtimes: {},
  benefits: [],
  showtimeInfo: null,
  settings: null,
  paymentSettings:null,
  footer:[]
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_MOVIEINFO_SUCCESS: 
      const { movie, cinema, showtimes } = action;
      return { ...state, movie, cinema, showtimes };
    case GET_BENEFITS_SUCCESS:
      return { ...state, benefits: action.benefits };
    case GET_SHOWTIME_INFO_SUCCESS:
      return { ...state, showtimeInfo: action.showtimeInfo };
    case GET_SETTINGS:
      return { ...state, settings: action.settings };
    case GET_MASTERCARD_BINES:
      return { ...state, paymentSettings: action.paymentSettings };
    case GET_FOOTER:
      return { ...state, footer: action.footer };
    default:
      return state;
  }
};
