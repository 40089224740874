import React, { memo } from 'react';
import Input from './Input';

const PaymentSharedFields = (props) => {
  console.log(props.folio)
  return (
  <div className="payments__shared-fields">
    <h1 className="title">Información personal</h1>

    <div className="row">
      <Input
        name="first_name"
        id="first_name"
        label="Nombre(s)"
        value={props.bill_to.first_name}
        onChange={props.onInputChange}
        validations={['notEmpty', 'alpha', 'maxLength:40']}
        updateValue={props.updateValue}
        required={true}
      />
      <Input
        name="last_name"
        id="last_name"
        label="Apellidos"
        value={props.bill_to.last_name}
        onChange={props.onInputChange}
        validations={['notEmpty', 'alpha', 'maxLength:40']}
        updateValue={props.updateValue}
        required={true}
      />
    </div>
    <div className="row">
      <Input
        type="email" 
        name="email"
        id="email"
        value={props.bill_to.email}
        label="Correo electrónico"
        onChange={props.onInputChange}
        validations={['email']}
        updateValue={props.updateValue}
        required={true}
      />
      { props.countryCode === 'CL' && localStorage.getItem("foliosPromocionCL") == "true" ? <></> : props.active !== 'loyalty_tickets' && props.active !== 'unpaid_booking' && !props.isLoyalty ?
      <Input
        name="tarjeta_cc"
        id="tarjeta_cc"
        value={props.bill_to.loyalty}
        label={props.countryCode === 'CL' ? 'RUT (Ej. 12345678-k)' : 'Tarjeta Club Cinépolis®'}
        onChange={props.onInputChange}
        validations={props.countryCode === 'CL' ? ['rut', 'maxLength:10'] : ['numeric', 'maxLength:16']}
        pattern={props.countryCode === 'CL' ? '' : '**** **** **** ****'}
        updateValue={props.updateValue}
      />  : <></>
      }
    </div>
    <div className="required-fields-copy"><span className="required-field">*</span>Campos obligatorios
    </div>
  </div>)}
;

const areEqual = (prevProps, nextProps) => {
  if (prevProps.bill_to.first_name === nextProps.bill_to.first_name &&
      prevProps.bill_to.last_name === nextProps.bill_to.last_name &&
      prevProps.bill_to.email === nextProps.bill_to.email &&
      prevProps.bill_to.phone === nextProps.bill_to.phone &&
      prevProps.bill_to.loyalty === nextProps.bill_to.loyalty &&
      prevProps.bill_to.updateValue === nextProps.bill_to.updateValue &&
      prevProps.isLoyalty === nextProps.isLoyalty &&
      prevProps.active === nextProps.active) {
    return true;
  }

  return false;
};
export default memo(PaymentSharedFields, areEqual);
