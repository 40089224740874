import { ticketsService, orderService } from '../services';
import { setSessionId, requestError, resetTime } from './globals';
import { resetSeatsData } from './seats';
import { withRouter } from 'react-router-dom'

/*
  Constants
*/
export const ADD_TICKET = 'ADD_TICKET';
export const SUB_TICKET = 'SUB_TICKET';
export const GET_TICKET_TYPES = 'GET_TICKET_TYPES';
export const GET_TICKET_TYPES_SUCCESS = 'GET_TICKET_TYPES_SUCCESS';
const RESET_TICKET_DATA = 'RESET_TICKET_DATA';
export const GET_TICKET_TYPES_FAIL = 'GET_TICKET_TYPES_FAIL';
const RESET_SELECTED_TICKETS = 'RESET_SELECTED_TICKETS';
const REQUEST_TICKETS_ERROR = 'REQUEST_TICKETS_ERROR';
const RELOAD_CURRENT_TICKETS = 'RELOAD_CURRENT_TICKETS';
/*
  Action creators
*/
export const addTicket = (data) => ({ type: ADD_TICKET, data });
export const subTicket = (data) => ({ type: SUB_TICKET, data });
export const resetTicketData = () => ({ type: RESET_TICKET_DATA, metadata: { local: 'clear' } });
export const reloadCurrentTickets = () => (dispatch, getState) => {
  var tickets = getState().tickets.tickets;
  if (tickets === null || typeof tickets === 'undefined') {
    const { showtimeVistaId, cinemaVistaId, countryCode } = getState().globals;
    dispatch(getTicketTypes(cinemaVistaId, showtimeVistaId, false, countryCode))
  }
  dispatch({ type: GET_TICKET_TYPES_SUCCESS, data: tickets, metadata: { local: 'save' } });
};
export const reloadTickets = (data) => (dispatch) => {
  dispatch({ type: GET_TICKET_TYPES_SUCCESS, data, metadata: { local: 'save' } });
  dispatch(setSessionId(data.session_id));
  dispatch(resetTime());
};
export const resetSelectedTickets = () => ({ type: RESET_SELECTED_TICKETS, metadata: {local:'save'}});

export const getTicketTypes = (cinemaVistaId, showtimeVistaId, reload, cinema_type, screen, sessionId, tokenG, countryCode) => (dispatch, getState) => {
  if (getState().tickets.isLoading)
    return;  
  const ticketsResponse = (data) => {
    if (data.data.status_code > 0) {
      dispatch(requestError(data.data.status_code, data.data.message, countryCode));
    } else {
      dispatch(resetTime());
      dispatch({ type: GET_TICKET_TYPES_SUCCESS, data: data.data, showtimeVistaId, countryCode });
      dispatch(setSessionId(data.data.session_id));
    }
  };

  dispatch({ type: GET_TICKET_TYPES });
  if (getState().globals.sessionId) {
    dispatch(resetSeatsData());
    ticketsService(cinemaVistaId, showtimeVistaId, reload, cinema_type, screen, sessionId, tokenG, countryCode)
      .then(response => {
        if (response.data.status_code === 0) {
          ticketsResponse(response);
        } else {
          dispatch(
            requestError(response.data.status_code, response.data.message, countryCode)
          );
        }
      });
  } else {
    ticketsService(cinemaVistaId, showtimeVistaId, reload,null,null, sessionId, tokenG, countryCode)
      .then(ticketsResponse);
  }
};

/*
  Reducer
*/
const initialState = {
  types: {},
  total: 0,
  isLoading: false,
  tickets: {},
  selectedTickets: [],
  showtimeVistaId: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_TICKET:
      {        
        if(localStorage.getItem('isFreeSeats')) {
          localStorage.removeItem('isFreeSeats')
        }
        
        const { type, price, code, area, folio, hopk } = action.data;

        let ticketsTypes = { ...state.types, [type]: state.types[type] ? state.types[type] + 1 : 1 };
        let ticketsSelected = state.selectedTickets;
        let ticket = ticketsSelected.find((obj) => obj.ticket_type_code === code && obj.folio === folio && obj.hopk === hopk);
        if (ticket !== undefined)
          ticket.quantity = state.types[type] ? state.types[type] + 1 : 1;
        else
          ticketsSelected.push({
            ticket_type_code: code,
            quantity: state.types[type] ? state.types[type] + 1 : 1,
            area_category_code: area,
            price,
            type,
            folio,
            hopk
          });

        return {
          ...state,
          types: ticketsTypes,
          total: state.total + price,
          selectedTickets: ticketsSelected
        };
      }
    case SUB_TICKET: {
      let ticketsTypes = { ...state.types };
      const { type, price, code, folio, hopk } = action.data;
      let selectedTickets = [...state.selectedTickets];

      if (ticketsTypes[type] > 1) {
        ticketsTypes = {
          ...ticketsTypes,
          [type]: ticketsTypes[type] - 1
        };
      } else {
        delete ticketsTypes[type];
      }

      const ticketFound = selectedTickets.find(ticket => ticket.ticket_type_code === code && ticket.folio === folio && ticket.hopk === hopk);
      if (typeof ticketFound !== 'undefined' && ticketFound.quantity > 1) {
        selectedTickets = selectedTickets.map(ticket => {
          if (ticketFound.ticket_type_code === ticket.ticket_type_code && ticketFound.folio === ticket.folio && ticket.hopk === hopk) {
            ticket.quantity -= 1;
          }
          return ticket;
        });
      } else {
        selectedTickets = selectedTickets.filter(ticket => ticket.ticket_type_code !== code);
      }

      return {
        ...state,
        types: ticketsTypes,
        total: state.total - price,
        selectedTickets
      };
    }
    case GET_TICKET_TYPES: {
      return {
        ...state,
        isLoading: true
      };
    }
	  case GET_TICKET_TYPES_SUCCESS: {
		  if (action.data !== undefined && action.data.areas !== undefined) {
			  const ticketsFolios = action.data.areas[0].tickets.filter(t => t.folio !== undefined).map(t => t.folio);
			  const newSelectedTickets = state.selectedTickets.filter(sT => ticketsFolios.includes(sT.folio));
			  const newTypes = {};
			  let price = 0;
			  newSelectedTickets.forEach(nT => { newTypes[nT.type] = nT.quantity; price += nT.quantity * nT.price });
			  return {
				  ...state,
				  isLoading: false,
				  tickets: action.data,
				  showtimeVistaId: action.showtimeVistaId,
          types: newTypes,
				  selectedTickets: newSelectedTickets,
          total:price
			  };
		  }
		  else {
			  return {
				  ...state,
				  isLoading: false,
				  tickets: action.data,
				  showtimeVistaId: action.showtimeVistaId
			  };
		  }
    }
    case GET_TICKET_TYPES_FAIL:
      return {
        ...state, isLoading: false, selectedTickets: [], types: {} };
    case RESET_SELECTED_TICKETS:
      return {
        ...state, selectedTickets: [], types: {} };
    case RESET_TICKET_DATA:
      return initialState;
    default:
      return state;
  }
};
